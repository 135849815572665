import Auth from '@aws-amplify/auth'
import { Button, Heading, Input, Page, Text } from '@psyomics/components'
import Content from '../../components/Content'
import Field from '../../components/Field'
import Header from '../../components/Header'
import Label from '../../components/Label'
import { useAuth } from '../../context/auth'
import { Formik } from 'formik'
import { useAsync } from '../../hooks/use-async'
import React, { useContext } from 'react'
import { handleConfirm } from './handleConfirm'
import Style from './VerificationCode.module.scss'
import { useNavigate } from 'react-router-dom'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'

interface Props {
    email: string
    password: string
}
const VerificationCode: React.FC<Props> = ({ email, password }) => {
    const { initializeUser, requireConfirmation } = useAuth()
    const { isLoading, run } = useAsync()
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const referralDetails = useContext(ReferralDetailsContext)
    const { accountCreated } = useMixpanelEvent(mixpanelApiUrl)

    const resend = () => Auth.resendSignUp(email)

    return (
        <Page>
            <Header template="landing" />
            <Content>
                <Heading el="h1" size="heading2" color="mid" css={{ mb: 4 }}>
                    Create account
                </Heading>
                <Text el="p" css={{ mb: 4 }}>
                    We have sent a code to the email address you supplied. Please enter it below to verify your email
                    address.
                </Text>

                <Formik
                    initialValues={{
                        code: '',
                    }}
                    onSubmit={(values) => {
                        run(
                            handleConfirm({
                                code: values.code,
                                values: { email, password },
                                initializeUser,
                                requestConfirmation: requireConfirmation,
                            }).then((user) => {
                                if (user) {
                                    accountCreated(
                                        referralDetails?.data?.organisationCode,
                                        referralDetails?.data?.organisationName,
                                        referralDetails?.data?.publicId
                                    )
                                    navigate('/')
                                }
                            })
                        )
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Field>
                                <Label htmlFor="code">Verification code</Label>
                                <Input
                                    type="text"
                                    id="code"
                                    appearance="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.code}
                                />
                            </Field>
                            <div className={Style.action}>
                                <Button
                                    type="submit"
                                    appearance="primary"
                                    fullWidth
                                    size="large"
                                    label="Sign In"
                                    icon={null}
                                    disabled={!!isLoading}
                                />
                            </div>
                        </form>
                    )}
                </Formik>
                <Text css={{ mt: 5 }}>
                    Code expired? <Button appearance="link" onClick={resend} label="Resend it now" />
                </Text>
            </Content>
        </Page>
    )
}

export default VerificationCode
