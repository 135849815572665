import React, { useState } from 'react'
import { Input, Button, Text } from '@psyomics/components'
import Field from 'registration/components/Field'
import Label from 'registration/components/Label'
import Card from 'registration/components/Card'
import Auth from '@aws-amplify/auth'
import Validation from 'registration/components/Validation'
import { useAsync } from 'registration/hooks/use-async'
import { ResetPasswordFormProps } from '../use-ResetPassword'

type IResetPassword = ResetPasswordFormProps & React.ComponentPropsWithoutRef<'div'>

const ResetPasswordEnterEmail: React.FC<IResetPassword> = ({
    formikProps: { values, errors, touched, handleChange, handleBlur, validateForm },
    setPageState,
}) => {
    const asyncReqCode = useAsync()
    const [clickedSubmitEmail, setClickedSubmitEmail] = useState(false)

    return (
        <Card>
            <Text color="mid">
                Enter the email address associated with your account and we’ll send you a code to reset your password
            </Text>
            <Field>
                <Label htmlFor="email">Email</Label>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    appearance="outlined"
                    onChange={handleChange}
                    onKeyUp={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                />
                {clickedSubmitEmail && errors.email && <Validation>{errors.email}</Validation>}
            </Field>

            <Button
                type="submit"
                appearance="primary"
                fullWidth
                size="large"
                label="Continue"
                disabled={!!asyncReqCode.isLoading}
                icon={null}
                onClick={async () => {
                    setClickedSubmitEmail(true)
                    await validateForm()
                    if (touched.email && !errors.email) {
                        await asyncReqCode.run(Auth.forgotPassword(values.email))
                        setPageState('entercode')
                    }
                }}
            />
            {asyncReqCode.isError && asyncReqCode.error && <Validation>{asyncReqCode.error.message}</Validation>}
        </Card>
    )
}

export default ResetPasswordEnterEmail
