import React, { useContext } from 'react'
import { Heading, Text } from '@psyomics/components'
import RefIcon from '../../../images/ref-icon.svg?react'
import ReleaseIcon from '../../../images/release-date-icon.svg?react'
import ManufacturerIcon from '../../../images/manufacturer-icon.svg?react'
import UkcaIcon from '../../../images/ukca-icon.svg?react'
import { InfoContext } from 'configure'
import Styles from './IFUCommon.module.scss'
import EifuIcon from '../../../images/eIFU_Symbol.svg?react'

export const ScopeAndLimitations: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Scope and Limitations of Censeo Digital
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo Digital is intended solely as a clinical decision support tool and should be used by individuals
                who a mental health service provider has referred. This device is not a diagnostic tool, nor does it
                replace direct clinical assessments by mental health professionals.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo is not intended for crisis support; if you are in immediate need, please contact emergency
                services or a crisis support organisation.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                The device is designed for adults aged 18-65 with basic proficiency in English and access to an
                internet-enabled device.
            </Text>
        </>
    )
}

export const ConditionsCoverage: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Censeo Digital covers the following mental health conditions:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.unorderedList}>
                    <li>Bipolar disorder type I</li>
                    <li>Bipolar disorder type II</li>
                    <li>Major depressive disorder</li>
                    <li>Generalised anxiety disorder</li>
                    <li>Social anxiety disorder</li>
                    <li>Panic disorder</li>
                    <li>Obsessive-compulsive disorder</li>
                    <li>Insomnia</li>
                    <li>Psychosis</li>
                    <li>Emotionally unstable personality disorder</li>
                    <li>Eating disorders (anorexia nervosa and bulimia nervosa)</li>
                    <li>Trauma</li>
                    <li>Adjustment disorder</li>
                    <li>Alcohol abuse</li>
                    <li>Substance abuse</li>
                </ol>
            </Text>
        </>
    )
}

export const Warnings: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Warnings:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo Digital must <u>not</u> be used:
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ul className={Styles.unorderedList}>
                    <li>To diagnose</li>
                    <li>As a crisis service</li>
                </ul>
            </Text>
        </>
    )
}

export const ForeseeableMedicalEmergencySituations: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Foreseeable Medical Emergency Situations
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo is not suitable for anyone in a situation of crisis. However, if you feel immediately at risk of
                harm from yourself or anyone else please contact one of the services below as Censeo is not an
                appropriate tool to use in this situation.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ul className={Styles.unorderedList}>
                    <li>Samaritans - call 116 123, email jo@samaritans.org </li>
                    <li>Emergency services - call 999 </li>
                    <li>NHS 111 - call 111, select option 2 to speak to someone about your mental health needs </li>
                </ul>
            </Text>
        </>
    )
}

export const DecommissioningUninstalling: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Decommissioning/uninstalling
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                If you wish to discontinue use of Censeo you can deactivate your account by contacting{' '}
                <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a>. For detailed information on how you
                receive a copy of your data or request that your data be deleted please refer to our Privacy Policy.
            </Text>
        </>
    )
}

export const ProductSpecification: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Product Specification:
            </Heading>
            <table className={Styles.simpleTable}>
                <tr>
                    <td>Hardware Requirement</td>
                    <td>Mobile phone, tablet, or desktop</td>
                </tr>
                <tr>
                    <td>Internet access required</td>
                    <td>
                        Yes, Censeo requires access to the internet to view the online version of the mental health
                        report. The PDF in some occasions may be available offline.
                    </td>
                </tr>
                <tr>
                    <td>Minimum Internet browser version requirements</td>
                    <td>
                        Censeo is compatible with the following browsers:{' '}
                        <ul className={Styles.unorderedList}>
                            <li>Chrome</li>
                            <li>Firefox </li>
                            <li>iOS Safari</li>
                            <li>Opera </li>
                            <li>Opera mobile </li>
                            <li>Android - Chrome browser</li>
                            <li>Android - Firefox browser</li>
                            <li>Edge </li>
                            <li>Samsung browser</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Support Languages</td>
                    <td>en-GB</td>
                </tr>
            </table>
        </>
    )
}

export const IncidentReporting: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Incident Reporting:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Please contact us at vigilance@psyomics.com if you have discovered a potential issue with Censeo. In
                case a serious incident has occurred concerning the product, please also report it to the Medicines and
                Healthcare Products Regulatory Agency (MHRA) through the Yellow Card Reporting Scheme.
            </Text>
        </>
    )
}

export const SoftwareInformation: React.FC = () => {
    const info = useContext(InfoContext)
    const releaseDate = info?.date
        ? new Date(info.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })
        : 'No release date information'
    const version = info?.appVersion ? info?.appVersion : 'No version information'

    return (
        <>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Software information
            </Heading>
            <div>
                <EifuIcon />
            </div>
            <Text>App name:</Text>
            <Text weight="demibold" css={{ mb: 4 }}>
                Censeo Digital
            </Text>
            <div className={Styles.iconRow}>
                <RefIcon />
                <div>
                    <Text>App version</Text>
                    <Text weight="demibold" el="p">
                        {version}
                    </Text>
                </div>
            </div>
            <div className={Styles.iconRow}>
                <ReleaseIcon />
                <div>
                    <Text>Date of release</Text>
                    <Text weight="demibold" el="p">
                        {releaseDate}
                    </Text>
                </div>
            </div>
            <div className={Styles.iconRow}>
                <ManufacturerIcon />
                <div>
                    <Text>Manufacturer or maintainer</Text>
                    <Text el="p" weight="demibold">
                        Psyomics Ltd.
                        <br />
                        7 Quy Court Colliers Lane,
                        <br />
                        Stow-Cum-Quy,
                        <br />
                        Cambridge,
                        <br />
                        England,
                        <br />
                        CB25 9AU
                        <br />
                        UK
                        <br />
                    </Text>
                </div>
            </div>
            <UkcaIcon />
        </>
    )
}
