import { Button, Heading, Page } from '@psyomics/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Header from 'registration/components/Header'

interface Props {
    fail: boolean
}

export const AfterPayment: React.FC<Props> = ({ ...props }) => {
    const navigate = useNavigate()
    const { fail } = props

    return (
        <Page style={{ backgroundColor: 'transparent' }} layout="standard" width="narrow">
            <Header template="landing" />
            <Heading el="h2" size="heading2" color="mid" css={{ mb: 5 }}>
                {fail ? 'Payment failed!' : 'Payment successful!'}
            </Heading>
            <Button
                className="text-right"
                appearance="primary"
                size="large"
                label="Try again"
                css={{ mt: 5 }}
                onClick={() => navigate('/test-stripe')}
            />
        </Page>
    )
}
