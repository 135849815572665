import { UserType, ReferralCode, Referrals } from 'censeo-core'

export enum RegistrationStep {
    confirmation = 'confirmation',
    account = 'account',
    signup = 'signup',
    resetpassword = 'resetpassword',
    signin = 'signin',
    creationOptions = 'creationOptions',
    federatedSignIn = 'federatedSignIn',
}

export interface RegistrationState {
    userType?: UserType
    referralCode?: ReferralCode
    referralPublicId?: string
    organisationCode?: string
    organisationName?: string
}

export type RegistrationAction = D2CRegistrationAction | NhsRegistrationAction | EndRegistrationAction

export type D2CRegistrationAction = {
    type: RegistrationActionKind.StartD2C
}

export type NhsRegistrationAction = {
    type: RegistrationActionKind.StartNHS
    payload: {
        referralCode: ReferralCode
        referralPublicId?: string
        organisationCode?: string
        organisationName?: string
    }
}

export type EndRegistrationAction = {
    type: RegistrationActionKind.EndRegistration
    payload?: null
}

export enum RegistrationActionKind {
    StartNHS = 'START_NHS',
    EndRegistration = 'END_REGISTRATION',
    StartD2C = 'START_D2C',
}

export type Routes =
    | '/resetpassword'
    | '/signin'
    | '/signup'
    | '/signup/account'
    | '/signup/confirmation'
    | '/signup/sign-up-options'
    | '/federated-sign-in'

export const RegistrationRoutes: Record<RegistrationStep, Routes> = {
    account: '/signup/account',
    confirmation: '/signup/confirmation',
    signin: '/signin',
    signup: '/signup',
    resetpassword: '/resetpassword',
    creationOptions: '/signup/sign-up-options',
    federatedSignIn: '/federated-sign-in',
}

export const getReferralPaths = (): string[] =>
    Object.values(Referrals).map((referral) =>
        ['/nhs', referral.serviceCode, referral.pathwayCode].filter((str): str is string => !!str).join('/')
    )
