import React, { ReactNode } from 'react'
import { Page, Text, Button } from '@psyomics/components'
import { useNavigate } from 'react-router-dom'
import Header from 'registration/components/Header'
import { classNameMaker } from 'ui/utils'
import Styles from './CustomError.module.scss'

interface CustomErrorProps {
    header?: string
    message: ReactNode
    homepageRedirect?: string
}

const CustomError: React.FC<CustomErrorProps> = ({ header, message, homepageRedirect }) => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })

    const navigate = useNavigate()

    return (
        <Page>
            <Header template="landing" />
            <div className={containerClass}>
                <Text size="large" color="mid" role="heading">
                    {header || 'Unexpected error'}
                </Text>
            </div>
            <Text css={{ mt: 5 }}>{message}</Text>
            <div className={Styles.formActions}>
                <Button
                    css={{ mt: 4 }}
                    appearance="primary"
                    fullWidth
                    size="large"
                    label="Homepage"
                    onClick={() => {
                        navigate(homepageRedirect ?? '/')
                    }}
                />
            </div>
        </Page>
    )
}

export default CustomError
