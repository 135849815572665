"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.staticConditionOrdering = exports.conditionOrdering = exports.allConditionAspectNames = exports.conditionInformation = void 0;
const __1 = require("../..");
const Suicide_symptoms_1 = require("./Suicide.symptoms");
const GAD_symptoms_1 = require("./GAD.symptoms");
const AD_symptoms_1 = require("./AD.symptoms");
const SoA_symptoms_1 = require("./SoA.symptoms");
const Insomnia_symptoms_1 = require("./Insomnia.symptoms");
const PD_symptoms_1 = require("./PD.symptoms");
const EUPD_symptoms_1 = require("./EUPD.symptoms");
const ED_symptoms_1 = require("./ED.symptoms");
const BP_symptoms_1 = require("./BP.symptoms");
const MDD_symptoms_1 = require("./MDD.symptoms");
const Alcohol_symptoms_1 = require("./Alcohol.symptoms");
const Substance_symptoms_1 = require("./Substance.symptoms");
const OCD_symptoms_1 = require("./OCD.symptoms");
const Psychosis_symptoms_1 = require("./Psychosis.symptoms");
const PTSD_symptoms_1 = require("./PTSD.symptoms");
const PC_symptoms_1 = require("./PC.symptoms");
const ADHD_symptoms_1 = require("./ADHD.symptoms");
const answerSummaries_1 = require("../../answerSummaries");
const conditionInformation = (userType) => {
    return {
        simple_bd1: {
            name: 'Bipolar I',
            patientFacingName: 'Extremes in Mood and Energy',
            summaries: BP_symptoms_1.symptoms.filter((s) => (0, answerSummaries_1.questionIds)(s.answerSummary).every((questionId) => !questionId.startsWith('D-'))),
            summariesOrdering: [
                'Previous manic episode(s) details',
                'Current manic symptoms',
                'Hospitalised',
                'Duration of hospital stay:',
                'Duration of longest hospital stay:',
            ],
        },
        simple_bd2: {
            name: 'Bipolar II',
            nameContextual: 'Recurring Low Mood with Periods of Increased Energy',
            patientFacingName: 'Recurrent Depression with Intermittent Mild Highs',
            summaries: BP_symptoms_1.symptoms,
            summariesOrdering: [
                'Previous manic episode(s) details',
                'Previous depressive episode(s) details',
                'Current manic symptoms',
                'Hospitalised',
                'Duration of hospital stay:',
                'Duration of longest stay in hospital:',
                'Formal admittance to hospital due to symptoms:',
            ],
        },
        simple_mdd: {
            name: 'Major depressive disorder',
            patientFacingName: 'Low Mood / Enjoyment in Life',
            summaries: MDD_symptoms_1.symptoms,
            summariesOrdering: [
                'Current episode',
                'Past episode',
                'Typical episode',
                'Most severe episode',
                'Longest episode',
                'Previous episode(s) details',
                'Hospitalised',
                'Duration of hospital stay:',
                'Duration of longest stay in hospital:',
                'Formal admittance to hospital due to symptoms:',
            ],
        },
        gad_ce: {
            name: 'Generalised anxiety disorder',
            patientFacingName: 'Persistent Anxiety, Worrying and Tension',
            summaries: GAD_symptoms_1.symptoms,
            summariesOrdering: ['Current episode', 'Previous episode'],
        },
        adj_ce: {
            name: 'Adjustment disorder',
            patientFacingName: 'Difficulties Adjusting to Life Stressor(s)',
            summaries: AD_symptoms_1.symptoms,
            summariesOrdering: ['Recent major life event(s)', 'Symptoms'],
        },
        soa_ce: {
            name: 'Social anxiety disorder',
            patientFacingName: 'Strong Fear of Social Situations',
            summaries: SoA_symptoms_1.symptoms,
            summariesOrdering: ['Current episode'],
        },
        ins_ce: {
            name: 'Insomnia',
            nameContextual: 'Persistent Difficulties with Sleep',
            patientFacingName: 'Problems with Sleep',
            summaries: Insomnia_symptoms_1.symptoms,
            summariesOrdering: ['Current episode'],
        },
        pad_ce: {
            name: 'Panic disorder',
            patientFacingName: 'Unexpected Panic Attacks',
            summaries: PD_symptoms_1.symptoms,
            summariesOrdering: ['Current episode'],
        },
        emi_ce: {
            name: 'Emotional instability',
            patientFacingName: 'Emotional and Relationship Instability',
            summaries: EUPD_symptoms_1.symptoms,
            summariesOrdering: ['Current symptoms and impairment', 'Duration and frequency of symptoms'],
        },
        dse_ce: {
            name: 'Disordered eating',
            nameContextual: 'Difficult Relationship with Food and Eating',
            patientFacingName: 'Problematic Eating Patterns',
            summaries: ED_symptoms_1.symptoms,
            summariesOrdering: ['Current episode'],
        },
        s_current: {
            name: 'Suicide',
            summaries: Suicide_symptoms_1.symptoms.filter((s) => (0, answerSummaries_1.questionIds)(s.answerSummary).every((questionId) => questionId.startsWith('S-'))),
            summariesOrdering: ['Current period', 'Past period(s)'],
        },
        sh_current: {
            name: 'Self harm',
            summaries: Suicide_symptoms_1.symptoms.filter((s) => (0, answerSummaries_1.questionIds)(s.answerSummary).every((questionId) => questionId.startsWith('SH-'))),
            summariesOrdering: [
                'Frequency of self-harm behaviours',
                'Self-harm details',
                'Medical assistance and hospitalisation',
            ],
        },
        alc_ce: {
            name: 'Alcohol use',
            patientFacingName: 'Problematic Alcohol Use',
            summaries: Alcohol_symptoms_1.symptoms,
            summariesOrdering: ['Current episode (in the last year)', 'Previous episode'],
        },
        alc_ce_dep: {
            name: 'Alcohol use and dependency',
            summaries: Alcohol_symptoms_1.symptoms,
            summariesOrdering: ['Current episode (in the last year)', 'Previous episode'],
        },
        alc_pe_dep: {
            name: 'Past Alcohol use and dependency',
            summaries: Alcohol_symptoms_1.symptoms,
            summariesOrdering: ['Current episode (in the last year)', 'Previous episode'],
        },
        sbs_ce: {
            name: 'Substance abuse',
            patientFacingName: 'Problematic Drug Use',
            summaries: Substance_symptoms_1.symptoms.filter((s) => { var _a; return !s.answerSummary.tags || ((_a = s.answerSummary.tags) === null || _a === void 0 ? void 0 : _a.includes('sbs_ce')); }),
            summariesOrdering: ['Episode details'],
        },
        sbs_pe: {
            name: 'Past substance abuse',
            summaries: Substance_symptoms_1.symptoms.filter((s) => { var _a; return !s.answerSummary.tags || ((_a = s.answerSummary.tags) === null || _a === void 0 ? void 0 : _a.includes('sbs_pe')); }),
            summariesOrdering: ['Episode details'],
        },
        ocd_ce: {
            name: 'Obsessive compulsive disorder',
            patientFacingName: 'Unwanted Thoughts and Repetitive Behaviours',
            summaries: OCD_symptoms_1.symptoms,
            summariesOrdering: [
                'Recent experience of obsessions and compulsions',
                'Current episode',
                'Duration of symptoms',
            ],
        },
        pso_ce: {
            name: 'Psychotic symptoms',
            patientFacingName: 'Unusual Perceptions, Beliefs and Experiences',
            summaries: Psychosis_symptoms_1.symptoms,
            summariesOrdering: [
                'Recent symptoms (in the last year)',
                'Previous symptoms (not in the last year)',
                'Symptoms',
                'Duration and onset',
            ],
        },
        trm_re_ptsd: {
            name: userType === __1.UserType.D2C ? 'Traumatic Stress' : 'Trauma',
            nameContextual: 'Distress Reactions to Past Trauma',
            patientFacingName: 'Traumatic Stress',
            summaries: PTSD_symptoms_1.symptoms,
            summariesOrdering: ['Reported traumatic event(s)', 'Current trauma related symptoms'],
        },
        adhd: {
            name: 'ADHD',
            patientFacingName: 'ADHD',
            summaries: ADHD_symptoms_1.symptoms,
        },
        pc: {
            type: 'static',
            name: 'Patient context',
            patientFacingName: 'Your Circumstances',
            summaries: PC_symptoms_1.symptoms,
            summariesOrdering: [
                'Social support',
                'Relationship',
                'Housing',
                'Caring responsibilities',
                'Mental health',
                'Physical health',
            ],
        },
    };
};
exports.conditionInformation = conditionInformation;
const allConditionAspectNames = (userType) => Object.keys((0, exports.conditionInformation)(userType));
exports.allConditionAspectNames = allConditionAspectNames;
exports.conditionOrdering = [
    // [{
    //     aspectName: "s_current",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "sh_current",
    //     mininimSeverity: 0.25
    // }],
    [
        {
            aspectName: 'pso_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'sbs_ce',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'sbs_pe',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'alc_ce_dep',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'alc_ce',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'alc_pe_dep',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'simple_bd1',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'simple_bd2',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'simple_mdd',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'emi_ce',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'pso_ce',
            mininimSeverity: 0.25,
        },
    ],
    [
        {
            aspectName: 'trm_re_ptsd',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'gad_ce',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'soa_ce',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'pad_ce',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'ocd_ce',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'dse_ce',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'sbs_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'sbs_pe',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'alc_ce_dep',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'alc_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'alc_pe_dep',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'simple_mdd',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'emi_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'trm_re_ptsd',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'gad_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'soa_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'pad_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'ocd_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'dse_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'ins_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'adj_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'simple_bd1',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'simple_bd2',
            mininimSeverity: 0.25,
        },
    ],
    [
        {
            aspectName: 'simple_mdd',
            mininimSeverity: 0.25,
        },
    ],
    [
        {
            aspectName: 'emi_ce',
            mininimSeverity: 0.25,
        },
    ],
    [
        {
            aspectName: 'trm_re_ptsd',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'gad_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'soa_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'pad_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'ocd_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'dse_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'adhd',
            mininimSeverity: 0.25,
        },
    ],
    // [{
    //     aspectName: "sbs_ce",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "sbs_pe",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "alc_ce_dep",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "alc_ce",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "alc_pe_dep",
    //     mininimSeverity: 0.25
    // }],
    [
        {
            aspectName: 'ins_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'adj_ce',
            mininimSeverity: 0.25,
        },
    ],
];
exports.staticConditionOrdering = ['pc', 'alc_pe_dep', 'sbs_pe'];
