import React from 'react'

const OrgPrioryLogo: React.FC = () => (
    <svg viewBox="0 0 123 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M0 1.06949H123V0.000990015H0V1.06949Z" fill="#184766" />
            <path d="M0 35.2254H123V34.1569H0V35.2254Z" fill="#184766" />
            <path
                d="M3.70693 24.7022C3.70693 27.1184 3.74561 27.3343 0.000732422 27.4892V28.4107C1.75594 28.317 3.58848 28.2642 5.50082 28.2642C7.4115 28.2642 9.24746 28.317 11.1203 28.4107V27.4892C7.37195 27.3343 7.4115 27.1184 7.4115 24.7022V10.5296C7.4115 9.57498 7.4115 8.92944 7.64595 8.40618C8.89533 8.22237 10.1842 7.97557 11.5118 7.97557C15.4173 7.97557 17.8775 9.45269 17.8775 12.6523C17.8775 15.269 15.4173 17.1792 12.2143 17.0863C11.2388 17.054 10.3019 16.9314 9.71542 16.8089L10.1448 17.9557C10.6918 18.08 11.6286 18.1719 12.684 18.1422C17.565 17.9873 21.7778 15.4548 21.7778 11.6067C21.7778 8.13072 17.4869 6.82208 13.6999 6.82208C11.4328 6.91671 9.16846 6.95846 6.86621 6.95846C4.52538 6.95846 2.26432 6.91671 0.000732422 6.82307V7.75717C3.74561 7.91306 3.70693 8.13072 3.70693 10.5298V24.7022Z"
                fill="#184766"
            />
            <path
                d="M67.4194 27.5391C59.7055 27.5391 56.5094 21.889 56.5094 16.4877C56.5094 11.4249 59.5878 7.68948 66.3676 7.68948C73.6152 7.68948 77.0826 11.7636 77.0826 17.8754C77.0826 23.5549 73.9252 27.1987 67.4194 27.5389M66.99 6.38258C57.9512 6.2892 52.3557 11.209 52.3557 17.5061C52.3557 24.5443 58.263 28.845 66.1716 28.845C74.7057 28.845 81.2361 23.5549 81.2361 16.9191C81.2361 10.0681 75.3291 6.38258 66.99 6.38258Z"
                fill="#D41041"
            />
            <path
                d="M111.649 17.7349L115.667 12.7044C117.148 10.7272 118.591 8.81381 119.877 6.82307H122.998V7.29197C119.722 10.2934 115.004 15.5126 113.404 18.2606C112.936 19.0637 112.86 19.8336 112.86 20.5755V24.7118C112.86 27.1186 112.82 27.3355 116.564 27.4904V28.4117C114.693 28.317 112.86 28.2647 110.948 28.2647C109.037 28.2647 107.204 28.3173 105.449 28.4117V27.4904C109.193 27.3355 109.155 27.1186 109.155 24.7118V20.173C109.155 19.9282 109.155 19.5564 108.96 19.2169L104.28 12.734C102.992 10.974 100.616 7.9479 97.8462 8.19717V7.29197L101.978 6.63753C103.11 6.48288 103.694 7.01108 104.358 7.66626C104.982 8.31946 105.527 8.96797 106.074 9.67726"
                fill="#184766"
            />
            <path
                d="M40.4281 28.3907C42.0507 28.3119 43.7377 28.2644 45.4935 28.2644C47.4031 28.2644 49.2357 28.3188 51.1094 28.4107V27.4902C47.3645 27.3345 47.4031 27.1194 47.4031 24.7084V10.5205C47.4031 8.10947 47.3645 7.8933 51.1094 7.73741V6.81789C49.2357 6.91078 47.4031 6.96266 45.4935 6.96266C43.5818 6.96266 41.7477 6.91078 39.9942 6.81789V7.73741C43.7372 7.8933 43.6985 8.10947 43.6985 10.5205V24.7084C43.6985 27.045 43.7242 27.3187 40.3189 27.4751C39.573 27.378 38.801 27.1853 38.2065 26.6257L29.3403 17.4725C33.1366 16.8847 36.9579 14.8742 36.9579 11.5054C36.9579 8.9072 34.4625 6.60171 29.0679 6.81863C27.5508 6.87891 26.0274 6.91498 24.498 6.93672V8.14826C25.3043 8.0445 26.187 7.97903 27.0329 7.97903C29.9285 7.97903 33.0586 8.96921 33.0586 12.3084C33.0586 14.5968 30.7108 16.2661 27.9715 16.6379C26.7977 16.7926 25.585 16.8242 24.3726 16.7602L24.2146 17.2264L33.5266 26.686C34.0717 27.2127 34.5004 27.7647 35.1246 28.4097C36.022 28.3195 36.8806 28.2642 37.7781 28.2642C38.5262 28.2642 40.4281 28.3907 40.4281 28.3907Z"
                fill="#184766"
            />
            <path
                d="M97.7522 26.6265L88.8877 17.4767C92.6833 16.8882 96.5046 14.8794 96.5046 11.5089C96.5046 8.9114 94.0082 6.60419 88.6136 6.8211C87.0965 6.88237 85.5739 6.91819 84.0455 6.94117V8.15098C84.8517 8.04796 85.731 7.98347 86.5796 7.98347C89.4742 7.98347 92.605 8.97366 92.605 12.3121C92.605 14.5998 90.2572 16.2696 87.5179 16.6407C86.3442 16.7946 85.1325 16.8269 83.9183 16.7642L83.762 17.2277C83.762 17.2277 94.0486 27.7701 94.6712 28.4125C95.5695 28.3205 96.428 28.2669 97.3245 28.2669C98.221 28.2669 99.116 28.3205 99.9745 28.4125V27.4929C99.1948 27.402 98.3766 27.2155 97.7522 26.6267"
                fill="#184766"
            />
            <path
                d="M1.79738 47.6293H3.36108C4.48517 47.6293 5.04732 47.029 5.04732 45.8281C5.04732 45.2806 4.92576 44.8426 4.68262 44.5138C4.43949 44.1852 4.05153 44.0206 3.51868 44.0206H1.79738V47.6293ZM0.122925 50.8506V50.5826C0.146205 50.5752 0.200657 50.5589 0.28628 50.5342C0.371805 50.5095 0.423334 50.4934 0.440965 50.4858C0.4584 50.478 0.496291 50.462 0.554738 50.4373C0.612987 50.4126 0.647959 50.3909 0.659646 50.3716C0.671337 50.3529 0.691792 50.3252 0.720918 50.2889C0.750139 50.253 0.767674 50.213 0.77342 50.1692C0.779362 50.1255 0.782188 50.0734 0.782188 50.0124V44.4767C0.782188 44.4313 0.780143 44.3905 0.776342 44.3542C0.772545 44.3181 0.761829 44.285 0.744296 44.2544C0.726761 44.224 0.714098 44.1985 0.706403 44.1776C0.698513 44.1568 0.677182 44.1348 0.642211 44.1118C0.607145 44.0891 0.583767 44.0721 0.572174 44.0607C0.560486 44.0491 0.528437 44.0335 0.475933 44.0132C0.423334 43.9929 0.390311 43.9809 0.376675 43.9767C0.363038 43.9728 0.322224 43.9582 0.254231 43.9332C0.186143 43.9082 0.142309 43.8936 0.122925 43.8895V43.5989H3.34939C4.20132 43.5989 4.89079 43.7992 5.41788 44.2002C5.94484 44.6012 6.20835 45.1438 6.20835 45.8281C6.20835 46.5082 5.93414 47.0424 5.38574 47.4299C4.83731 47.8176 4.10791 48.0115 3.19772 48.0115H1.79738V50.0124C1.79738 50.0734 1.80128 50.1255 1.80907 50.1692C1.81686 50.213 1.8344 50.253 1.86157 50.2889C1.88885 50.3252 1.91028 50.3529 1.92577 50.3716C1.94126 50.3909 1.97632 50.4126 2.03087 50.4373C2.08532 50.462 2.12419 50.478 2.14747 50.4858C2.17085 50.4934 2.22238 50.5095 2.30215 50.5342C2.38183 50.5589 2.43726 50.5752 2.46843 50.5826V50.8506H0.122925Z"
                fill="#184766"
            />
            <path
                d="M8.16251 49.5259C8.16251 49.8019 8.25455 50.0252 8.43876 50.1957C8.62297 50.3662 8.87592 50.4514 9.19738 50.4514C9.34047 50.4514 9.47588 50.4294 9.60358 50.3854C9.73118 50.3415 9.8396 50.2905 9.92863 50.2333C10.0177 50.176 10.1067 50.0991 10.1956 50.0035C10.2846 49.9079 10.3523 49.8276 10.3988 49.7622C10.4451 49.6971 10.5022 49.6119 10.57 49.5067C10.6376 49.4014 10.6831 49.3335 10.7063 49.3031V47.9772C10.2706 48.0382 9.89424 48.1133 9.57727 48.2029C9.2603 48.2924 9.0123 48.385 8.83346 48.4802C8.65454 48.5755 8.51337 48.686 8.41041 48.8117C8.30737 48.9375 8.24024 49.0536 8.20917 49.1601C8.1779 49.2668 8.16251 49.3888 8.16251 49.5259ZM7.14722 49.5603C7.14722 49.315 7.19582 49.1015 7.29305 48.9194C7.39025 48.7379 7.5653 48.5615 7.81816 48.391C8.07094 48.2205 8.43564 48.0704 8.91226 47.9399C9.3886 47.8097 9.98668 47.6949 10.7063 47.5952V47.4527C10.7063 46.9282 10.6212 46.5386 10.451 46.2841C10.2807 46.0295 9.99438 45.902 9.59198 45.902C9.20898 45.902 8.93428 45.9711 8.7679 46.109C8.60155 46.2471 8.51836 46.5117 8.51836 46.9025C8.51836 47.006 8.45582 47.0848 8.33093 47.1384C8.20595 47.1921 8.06618 47.219 7.9116 47.219C7.77151 47.219 7.66066 47.1772 7.57894 47.0934C7.4973 47.01 7.45259 46.8997 7.44481 46.7629C7.44481 46.3523 7.65627 46.0358 8.07952 45.8135C8.50257 45.5914 9.02225 45.4803 9.63846 45.4803C10.29 45.4803 10.7885 45.6455 11.1338 45.9761C11.4789 46.3066 11.6515 46.7723 11.6515 47.3729V49.8976C11.6515 50.103 11.702 50.2365 11.8033 50.2975C11.8616 50.3318 11.9432 50.3489 12.0483 50.3489C12.1222 50.3489 12.2098 50.3393 12.3109 50.3204V50.6802C12.0191 50.8546 11.7391 50.9418 11.4707 50.9418C11.2918 50.9418 11.1371 50.8405 11.0069 50.638C10.8764 50.4354 10.7919 50.1505 10.753 49.7831C10.5944 50.1013 10.3622 50.3768 10.0565 50.6095C9.75075 50.8424 9.39279 50.9609 8.9827 50.9645C8.96722 50.9645 8.95171 50.9645 8.93632 50.9645C8.45143 50.9645 8.03491 50.8452 7.68687 50.6063C7.32713 50.36 7.14722 50.0114 7.14722 49.5603Z"
                fill="#184766"
            />
            <path
                d="M13.3315 46.1302V45.7021L14.6327 45.5257C14.656 45.522 14.6773 45.522 14.6968 45.5257C14.8407 45.5257 14.9283 45.5941 14.9594 45.7308C14.9749 45.7989 14.9847 45.873 14.9886 45.9528C14.9886 46.0665 14.9728 46.1937 14.9414 46.334C14.9378 46.3684 14.9361 46.395 14.9361 46.4138L14.8232 47.3956L14.9118 46.9282C14.9902 46.4949 15.1966 46.1527 15.5312 45.902C15.8656 45.6512 16.2721 45.5257 16.7506 45.5257C17.0151 45.5257 17.2232 45.5934 17.3749 45.7288C17.4526 45.7964 17.4917 45.8659 17.4917 45.9373C17.4917 46.0084 17.4547 46.0884 17.3808 46.1771L17.1066 46.51C17.0521 46.5786 16.9995 46.6179 16.949 46.628C16.8985 46.6381 16.846 46.6288 16.7915 46.6004C16.737 46.5719 16.6825 46.5386 16.6281 46.5006C16.5736 46.4625 16.5036 46.4267 16.4181 46.3923C16.3324 46.358 16.2449 46.341 16.1555 46.341C15.9726 46.341 15.8074 46.3904 15.6595 46.4892C15.5117 46.588 15.3931 46.7145 15.3037 46.8684C15.2142 47.0223 15.1451 47.1647 15.0965 47.2961C15.0478 47.4271 15.0099 47.5535 14.9828 47.6751V50.2291C14.9828 50.3585 15.0304 50.4457 15.126 50.4915C15.2214 50.5371 15.3838 50.5599 15.6129 50.5599V50.8506H13.4424V50.5599C13.656 50.5599 13.8087 50.5369 13.9002 50.4907C13.9917 50.4449 14.0376 50.3565 14.0376 50.2261V46.5905C14.0376 46.2759 13.8653 46.1186 13.521 46.1186C13.4635 46.1186 13.4004 46.1226 13.3315 46.1302Z"
                fill="#184766"
            />
            <path
                d="M18.699 46.0504V45.6285C18.999 45.6285 19.2602 45.5124 19.4824 45.2806C19.7046 45.0489 19.9112 44.741 20.1023 44.3571C20.1291 44.3038 20.1618 44.2677 20.2003 44.2487C20.2387 44.2297 20.2731 44.2306 20.304 44.2517C20.3347 44.2726 20.3502 44.3077 20.3502 44.3571V45.5143H21.7213V45.8792C21.7213 45.9476 21.7094 45.9931 21.6858 46.0161C21.6623 46.0388 21.6153 46.0504 21.5448 46.0504H20.3502V49.4368C20.3502 49.6952 20.4027 49.9195 20.5077 50.1095C20.6127 50.2995 20.7624 50.3946 20.9569 50.3946C21.272 50.3946 21.5521 50.3013 21.7971 50.1152C21.8166 50.0998 21.8525 50.1192 21.905 50.1722C21.9575 50.2254 21.9955 50.2824 22.0188 50.3432C22.0422 50.404 22.0343 50.4536 21.9955 50.4915C21.6298 50.7842 21.1962 50.9305 20.6943 50.9305C19.8192 50.9305 19.3815 50.3983 19.3815 49.334V46.0504H18.699Z"
                fill="#184766"
            />
            <path
                d="M27.1989 47.9823C27.1989 48.6835 27.3615 49.2665 27.6861 49.7315C28.0108 50.1963 28.4185 50.4286 28.9085 50.4286C29.8499 50.4286 30.3205 49.7619 30.3205 48.4283C30.3205 47.7269 30.1551 47.1422 29.8247 46.6734C29.4938 46.2048 29.0856 45.9706 28.5993 45.9706C27.6657 45.9706 27.1989 46.6411 27.1989 47.9823ZM26.2072 48.2509C26.2072 47.8413 26.2762 47.4621 26.4141 47.113C26.5523 46.7642 26.7371 46.4731 26.9685 46.2396C27.1999 46.0065 27.4682 45.8254 27.7737 45.6964C28.0791 45.5674 28.3988 45.5029 28.7335 45.5029C29.4492 45.5029 30.0541 45.7503 30.5481 46.2454C31.0419 46.7402 31.289 47.3783 31.289 48.1595C31.289 48.5671 31.2209 48.9479 31.0848 49.3021C30.9486 49.6564 30.7668 49.9534 30.5394 50.1934C30.3117 50.4334 30.0454 50.6218 29.7399 50.759C29.4345 50.8961 29.1145 50.9645 28.7801 50.9645C28.3213 50.9645 27.8951 50.8494 27.5024 50.6189C27.1094 50.3887 26.7954 50.065 26.56 49.648C26.3246 49.2307 26.2072 48.7653 26.2072 48.2509Z"
                fill="#184766"
            />
            <path
                d="M32.4673 45.8933V45.5143H33.2842V45.0356C33.2842 44.4236 33.4396 43.9419 33.7508 43.5903C34.062 43.2388 34.4996 43.0628 35.0637 43.0628C35.9125 43.0628 36.5327 43.3309 36.924 43.8667C37.01 43.9655 37.0561 44.073 37.0619 44.1889C37.0676 44.3047 37.0175 44.4083 36.9112 44.4997C36.801 44.5871 36.6848 44.6232 36.5626 44.6079C36.4404 44.5928 36.3519 44.5338 36.2968 44.4313C36.2377 44.3171 36.1943 44.2346 36.1669 44.1832C36.1392 44.1318 36.0913 44.055 36.0235 43.9522C35.9554 43.8497 35.8901 43.7745 35.828 43.7269C35.7657 43.6797 35.6851 43.635 35.5859 43.593C35.4866 43.5512 35.3806 43.5303 35.2679 43.5303C34.9178 43.5303 34.661 43.6501 34.4976 43.8895C34.3343 44.1288 34.2525 44.4484 34.2525 44.8473V45.5143H36.0671C35.9856 45.7411 35.925 45.8795 35.8863 45.9294C35.8474 45.9798 35.7716 46.0048 35.6587 46.0048H34.2525V50.256C34.2525 50.3936 34.2907 50.4826 34.3665 50.5225C34.4423 50.5626 34.6103 50.5826 34.8712 50.5826V50.8506H32.7299V50.5826C32.9593 50.5826 33.1092 50.5618 33.179 50.5196C33.249 50.4775 33.2842 50.3896 33.2842 50.256V46.0048H32.5897C32.5079 46.0048 32.4673 45.9674 32.4673 45.8933Z"
                fill="#184766"
            />
            <path
                d="M40.7747 50.8506V50.5826C41.0432 50.5027 41.2103 50.4361 41.2766 50.3827C41.3736 50.3029 41.4225 50.1735 41.4225 49.9944V44.5807C41.4225 44.3826 41.3706 44.2314 41.2677 44.1266C41.1647 44.0219 41.0003 43.9354 40.7747 43.8667V43.5989H42.5484L45.4832 48.8866L48.1848 43.5989H49.7366V43.8667C49.5189 43.9315 49.3671 44.0152 49.2815 44.118C49.196 44.2208 49.1534 44.3749 49.1534 44.5807V49.9944C49.1534 50.1849 49.1903 50.3123 49.2643 50.377C49.3379 50.4418 49.4953 50.5102 49.7366 50.5826V50.8506H47.531V50.5826C47.7761 50.4989 47.928 50.4341 47.9863 50.3884C48.0876 50.3086 48.138 50.1771 48.138 49.9944V44.8376L45.4483 50.2058C45.3937 50.3086 45.3453 50.376 45.3024 50.4085C45.2595 50.4408 45.2159 50.4408 45.171 50.4085C45.1264 50.376 45.0747 50.3086 45.0164 50.2058L41.9943 44.8033V49.9944C41.9943 50.0707 42.0038 50.1361 42.0232 50.1915C42.0427 50.2466 42.0631 50.2913 42.0846 50.3257C42.106 50.36 42.1477 50.3933 42.21 50.4257C42.2724 50.4581 42.321 50.4808 42.3559 50.4942C42.3908 50.5075 42.4589 50.5292 42.5601 50.5599C42.5913 50.5713 42.6145 50.5789 42.6302 50.5826V50.8506H40.7747Z"
                fill="#184766"
            />
            <path
                d="M51.1074 50.8506V50.5027C51.3705 50.423 51.5313 50.3602 51.5902 50.3146C51.6924 50.2347 51.7433 50.1052 51.7433 49.9264V44.4601C51.7433 44.3386 51.7176 44.2406 51.6667 44.1664C51.6156 44.0921 51.5648 44.0436 51.5136 44.0206C51.4625 43.998 51.3545 43.9619 51.1897 43.9124C51.1546 43.9016 51.1269 43.8927 51.1074 43.8855V43.5989H56.3292C56.4109 43.5989 56.4646 43.6123 56.4897 43.6387C56.5149 43.6654 56.5279 43.7224 56.5279 43.8097V44.9785H56.1835L56.0025 44.5909C55.9209 44.4276 55.805 44.3068 55.6554 44.2287C55.5058 44.1508 55.3043 44.1118 55.0514 44.1118H52.8168V46.9168H54.9803C55.0347 46.9168 55.0843 46.9129 55.1292 46.9053C55.1741 46.8975 55.2152 46.879 55.2521 46.8501C55.289 46.8212 55.3182 46.799 55.3397 46.7835C55.3614 46.7678 55.3866 46.7323 55.416 46.676C55.4452 46.6202 55.4636 46.5823 55.4713 46.5628C55.4791 46.5435 55.4995 46.4941 55.5327 46.4148C55.5658 46.3355 55.5863 46.2844 55.594 46.2612H55.81V48.0572H55.594C55.5826 48.0262 55.5591 47.9693 55.524 47.886C55.4888 47.803 55.4646 47.7467 55.4509 47.7178C55.4372 47.6889 55.4127 47.6464 55.3778 47.5903C55.3427 47.534 55.3095 47.4955 55.2786 47.4741C55.2472 47.4532 55.2063 47.4326 55.1556 47.4134C55.105 47.3941 55.0464 47.3843 54.9803 47.3843H52.8168V50.3602L55.5534 50.3375C55.6427 50.3375 55.7214 50.3318 55.7895 50.3204C55.8576 50.3089 55.9122 50.2975 55.9531 50.2861C55.9938 50.2748 56.0347 50.2511 56.0755 50.215C56.1164 50.1789 56.1456 50.1513 56.1631 50.1322C56.1805 50.1131 56.2107 50.0734 56.2536 50.0124C56.2962 49.9519 56.3292 49.9079 56.3526 49.8815L56.6621 49.4936H57.1054C56.9573 49.7801 56.8291 50.1401 56.7204 50.5737C56.697 50.66 56.6523 50.7279 56.586 50.7768C56.5199 50.8259 56.4461 50.8506 56.3643 50.8506H51.1074Z"
                fill="#184766"
            />
            <path
                d="M59.9756 49.88C59.9756 50.0134 60.0048 50.1041 60.0629 50.1518C60.1213 50.1994 60.2322 50.2327 60.3956 50.2518L61.0841 50.3035C61.2162 50.3148 61.3427 50.3185 61.4634 50.3148C61.8252 50.3148 62.1461 50.2688 62.4259 50.1774C62.7995 50.0554 63.098 49.856 63.3216 49.5793C63.5453 49.3029 63.7087 48.9721 63.8119 48.5866C63.9146 48.2013 63.9665 47.7494 63.9665 47.2306C63.9665 46.235 63.7358 45.4587 63.275 44.9016C62.8139 44.3448 62.113 44.0661 61.1716 44.0661H59.9756V49.88ZM58.1844 50.8506V50.4286C58.7016 50.3225 58.9602 50.1361 58.9602 49.8705V44.5163C58.9602 44.3833 58.8991 44.2712 58.7764 44.1803C58.654 44.0891 58.4565 44.011 58.1844 43.9465V43.5989H60.7165C61.4282 43.5989 62.0439 43.6617 62.5633 43.7875C63.0825 43.9134 63.5306 44.1165 63.9082 44.3967C64.2852 44.6769 64.5682 45.0524 64.757 45.5235C64.9455 45.9941 65.04 46.5633 65.04 47.2306C65.04 47.9011 64.9398 48.478 64.7395 48.961C64.5391 49.4439 64.2513 49.8237 63.876 50.1003C63.5004 50.3768 63.0638 50.574 62.5661 50.6915C62.1576 50.7862 61.7025 50.8336 61.2008 50.8336C61.0878 50.8336 60.9734 50.8313 60.8564 50.8277C60.2731 50.7921 59.8141 50.7746 59.4794 50.7746C59.4445 50.7746 59.4113 50.7746 59.3804 50.7746C59.038 50.7783 58.6393 50.8037 58.1844 50.8506Z"
                fill="#184766"
            />
            <path
                d="M66.5332 50.8506V50.5826C66.5564 50.5752 66.611 50.5589 66.6965 50.5339C66.7821 50.5092 66.8334 50.4932 66.8511 50.4855C66.8686 50.478 66.9065 50.4618 66.9649 50.4371C67.0232 50.4121 67.0581 50.3904 67.0698 50.3714C67.0816 50.3521 67.102 50.3247 67.1312 50.2883C67.1604 50.2523 67.1778 50.2123 67.1835 50.1686C67.1893 50.1248 67.1925 50.0724 67.1925 50.0114V44.4663C67.1925 44.4206 67.1905 44.3798 67.1865 44.3435C67.1826 44.3074 67.1721 44.2742 67.1544 44.2437C67.1369 44.2131 67.1244 44.1874 67.1165 44.1666C67.1087 44.1457 67.0873 44.1237 67.0524 44.1009C67.0172 44.0779 66.994 44.061 66.9823 44.0496C66.9708 44.0379 66.9387 44.0229 66.8861 44.0038C66.8334 43.9848 66.8003 43.9734 66.7868 43.9695C66.7733 43.9658 66.7324 43.9525 66.6644 43.9295C66.5963 43.9068 66.5527 43.8934 66.5332 43.8895V43.5989H68.8728V43.8895C68.8493 43.8974 68.8094 43.9098 68.7531 43.9267C68.6967 43.9438 68.6588 43.9552 68.6394 43.9611C68.6199 43.9666 68.5887 43.9772 68.5461 43.9923C68.5032 44.0076 68.4733 44.02 68.4556 44.0295C68.4381 44.039 68.4137 44.0532 68.3828 44.0723C68.3513 44.0913 68.3301 44.1087 68.3184 44.1237C68.3067 44.1389 68.2912 44.159 68.2718 44.1837C68.2523 44.2084 68.2396 44.2341 68.2339 44.2608C68.2281 44.2875 68.2222 44.3181 68.2164 44.3522C68.2104 44.3866 68.2074 44.4246 68.2074 44.4663V50.0114C68.2074 50.065 68.2124 50.1125 68.2222 50.1545C68.2319 50.1963 68.2416 50.2316 68.2513 50.2599C68.2611 50.2886 68.2805 50.3163 68.3097 50.3427C68.3389 50.3694 68.3623 50.3904 68.3798 50.4057C68.3972 50.4208 68.4304 50.4381 68.479 50.4571C68.5276 50.4761 68.5645 50.4894 68.5897 50.4969C68.6149 50.5048 68.6608 50.5179 68.7269 50.5371C68.793 50.5561 68.8416 50.5713 68.8728 50.5826V50.8506H66.5332Z"
                fill="#184766"
            />
            <path
                d="M72.3382 47.886H75.0047L73.6277 44.7675L72.3382 47.886ZM70.045 50.8506V50.5371C70.3251 50.4761 70.5216 50.4047 70.6345 50.3227C70.7473 50.2411 70.858 50.0707 70.967 49.8118L73.6392 43.5078L74.001 43.5989C74.1683 43.6521 74.2868 43.7622 74.3568 43.9295L76.8774 49.5114C77.0485 49.897 77.2024 50.1492 77.3385 50.2676C77.4744 50.3862 77.6806 50.4758 77.9569 50.5371V50.8506H75.3488V50.5371C75.5782 50.4989 75.7416 50.4541 75.8391 50.4025C75.9361 50.3509 75.983 50.2889 75.979 50.2159C75.975 50.1436 75.9478 50.0576 75.8974 49.9583L75.1972 48.3307H72.1455L71.5038 49.901C71.4804 49.962 71.4687 50.0194 71.4687 50.0729C71.4687 50.1646 71.5038 50.2409 71.5737 50.3019C71.6866 50.4015 71.8946 50.4798 72.1981 50.5371V50.8506H70.045Z"
                fill="#184766"
            />
            <path
                d="M78.9656 50.8507V50.5826C79.4284 50.4531 79.6601 50.2609 79.6601 50.006V44.4436C79.6601 44.1884 79.4284 43.9962 78.9656 43.8667V43.5989H80.5934L84.8469 48.8636V44.4436C84.8469 44.1847 84.6155 43.9925 84.1526 43.8667V43.5989H86.183V43.8667C85.7202 43.9962 85.4888 44.1884 85.4888 44.4436V50.8507C85.4888 50.9075 85.4626 50.9362 85.41 50.9362C85.3573 50.9362 85.308 50.9075 85.2611 50.8507L80.2084 44.8606V50.006C80.2084 50.2572 80.4358 50.4492 80.8912 50.5826V50.8507H78.9656Z"
                fill="#184766"
            />
            <path
                d="M90.3596 47.1165C90.3596 46.4208 90.5122 45.7891 90.8177 45.2209C91.1229 44.6526 91.5411 44.2089 92.0723 43.8895C92.6029 43.5703 93.1914 43.4107 93.837 43.4107C94.7637 43.4107 95.4936 43.7622 96.027 44.4653C96.1389 44.4653 96.221 44.3846 96.2731 44.2232C96.325 44.0614 96.3609 43.8267 96.3811 43.5189H96.8127L96.9818 45.8508L96.3928 46.0845C96.3726 46.0388 96.3369 45.956 96.2851 45.8365C96.2329 45.7167 96.1915 45.6208 96.1601 45.5487C95.9222 45.0052 95.5986 44.5948 95.1888 44.3171C94.7791 44.0397 94.3288 43.9011 93.837 43.9011C93.5959 43.9011 93.3665 43.9345 93.1488 44.0008C92.9308 44.0673 92.7216 44.1805 92.5214 44.3401C92.3211 44.4997 92.1481 44.697 92.0022 44.933C91.8563 45.1688 91.7396 45.4679 91.6521 45.8308C91.5646 46.1937 91.5209 46.6014 91.5209 47.0537C91.5209 47.6133 91.586 48.1168 91.7162 48.5642C91.8466 49.0114 92.0254 49.375 92.2531 49.655C92.4807 49.9345 92.7393 50.1488 93.0291 50.2973C93.3186 50.4457 93.6291 50.5201 93.9597 50.5201C94.4417 50.5201 94.8951 50.3842 95.319 50.1125C95.7429 49.8409 95.9552 49.5809 95.9552 49.332V48.2704C95.9552 48.1519 95.9215 48.0599 95.8544 47.995C95.7868 47.93 95.6938 47.8974 95.5749 47.8974H95.045V47.407L97.7112 47.3956V47.8974H97.3439C97.2249 47.8974 97.1329 47.93 97.0678 47.995C97.003 48.0599 96.9703 48.1519 96.9703 48.2704V50.7958C96.9703 50.8706 96.9137 50.9166 96.8012 50.9336C96.6883 50.9504 96.6242 50.9227 96.6085 50.8506L96.321 49.9402C95.9811 50.259 95.6255 50.5092 95.2544 50.6915C94.8834 50.8736 94.4517 50.9645 93.9597 50.9645C93.454 50.9645 92.9795 50.8714 92.5358 50.6851C92.0927 50.4991 91.7114 50.2407 91.3925 49.9099C91.0736 49.5793 90.8214 49.1724 90.6369 48.6899C90.4519 48.2072 90.3596 47.6827 90.3596 47.1165Z"
                fill="#184766"
            />
            <path
                d="M98.913 46.1302V45.7021L100.214 45.5257C100.237 45.522 100.259 45.522 100.278 45.5257C100.422 45.5257 100.51 45.5941 100.541 45.7308C100.556 45.7989 100.566 45.873 100.57 45.9528C100.57 46.0665 100.554 46.1937 100.523 46.334C100.519 46.3684 100.517 46.395 100.517 46.4138L100.405 47.3956L100.493 46.9282C100.571 46.4949 100.778 46.1527 101.113 45.902C101.447 45.6512 101.854 45.5257 102.332 45.5257C102.597 45.5257 102.805 45.5934 102.956 45.7288C103.034 45.7964 103.073 45.8659 103.073 45.9373C103.073 46.0084 103.036 46.0884 102.962 46.1771L102.688 46.51C102.633 46.5786 102.581 46.6179 102.531 46.628C102.48 46.6381 102.427 46.6288 102.373 46.6004C102.318 46.5719 102.264 46.5386 102.21 46.5006C102.155 46.4625 102.085 46.4267 101.999 46.3923C101.914 46.358 101.826 46.341 101.737 46.341C101.554 46.341 101.389 46.3904 101.241 46.4892C101.093 46.588 100.974 46.7145 100.885 46.8684C100.795 47.0223 100.727 47.1647 100.678 47.2961C100.629 47.4271 100.591 47.5535 100.564 47.6751V50.2291C100.564 50.3585 100.612 50.4457 100.707 50.4915C100.803 50.5371 100.965 50.5599 101.194 50.5599V50.8506H99.0237V50.5599C99.2374 50.5599 99.39 50.5369 99.4815 50.4907C99.573 50.4449 99.6189 50.3565 99.6189 50.2261V46.5905C99.6189 46.2759 99.4469 46.1186 99.1025 46.1186C99.0449 46.1186 98.9818 46.1226 98.913 46.1302Z"
                fill="#184766"
            />
            <path
                d="M104.899 47.9823C104.899 48.6835 105.061 49.2665 105.386 49.7315C105.711 50.1963 106.118 50.4286 106.608 50.4286C107.549 50.4286 108.02 49.7619 108.02 48.4283C108.02 47.7269 107.855 47.1422 107.524 46.6734C107.194 46.2048 106.785 45.9706 106.299 45.9706C105.366 45.9706 104.899 46.6411 104.899 47.9823ZM103.907 48.2509C103.907 47.8413 103.976 47.4621 104.114 47.113C104.252 46.7642 104.437 46.4731 104.668 46.2396C104.899 46.0065 105.168 45.8254 105.474 45.6964C105.779 45.5674 106.099 45.5029 106.433 45.5029C107.149 45.5029 107.754 45.7503 108.248 46.2454C108.742 46.7402 108.989 47.3783 108.989 48.1595C108.989 48.5671 108.921 48.9479 108.785 49.3021C108.648 49.6564 108.467 49.9534 108.239 50.1934C108.012 50.4334 107.745 50.6218 107.44 50.759C107.134 50.8961 106.814 50.9645 106.48 50.9645C106.021 50.9645 105.595 50.8494 105.202 50.6189C104.809 50.3887 104.495 50.065 104.26 49.648C104.024 49.2307 103.907 48.7653 103.907 48.2509Z"
                fill="#184766"
            />
            <path
                d="M110.12 45.9817V45.6683L111.304 45.4803C111.316 45.4803 111.328 45.4803 111.34 45.4803C111.499 45.4803 111.579 45.5539 111.579 45.7014V49.0114C111.579 49.499 111.675 49.8442 111.868 50.046C112.06 50.2479 112.37 50.3489 112.798 50.3489C113.518 50.3489 114.027 49.8536 114.327 48.8627V46.3592C114.327 46.2219 114.303 46.1248 114.254 46.0675C114.205 46.0104 114.123 45.9817 114.006 45.9817H113.825V45.6683L114.998 45.4803C115.025 45.4763 115.052 45.4746 115.08 45.4746C115.208 45.4746 115.272 45.5501 115.272 45.7014V49.8916C115.272 50.0937 115.323 50.2232 115.424 50.2805C115.486 50.3148 115.577 50.3318 115.698 50.3318C115.768 50.3318 115.85 50.326 115.943 50.3148V50.6738L115.307 50.9191C115.241 50.9419 115.179 50.9531 115.121 50.9531C114.957 50.9531 114.809 50.8695 114.677 50.7019C114.494 50.466 114.377 50.0873 114.327 49.5657V49.543C114.28 49.6801 114.215 49.818 114.132 49.9568C114.048 50.0959 113.936 50.2444 113.796 50.4023C113.656 50.5601 113.478 50.6898 113.262 50.7906C113.046 50.8914 112.812 50.9418 112.559 50.9418C111.96 50.9418 111.489 50.7723 111.147 50.4337C110.805 50.0946 110.634 49.6016 110.634 48.9542V46.3592C110.634 46.2256 110.608 46.1294 110.558 46.0705C110.507 46.0114 110.421 45.9817 110.301 45.9817H110.12Z"
                fill="#184766"
            />
            <path
                d="M118.603 49.1883C118.732 49.5467 118.933 49.8466 119.207 50.0884C119.481 50.3306 119.821 50.4514 120.225 50.4514C120.669 50.4514 121.002 50.2819 121.226 49.9427C121.449 49.6035 121.561 49.0758 121.561 48.3594C121.561 47.9592 121.518 47.5962 121.43 47.2703C121.343 46.9445 121.196 46.6723 120.99 46.4531C120.783 46.234 120.533 46.1243 120.237 46.1243C120.011 46.1243 119.814 46.1435 119.645 46.1817C119.475 46.2197 119.328 46.2817 119.201 46.3672C119.075 46.4531 118.971 46.5369 118.889 46.6189C118.808 46.7009 118.712 46.8123 118.603 46.9531V49.1883ZM116.952 46.1586V45.6569L118.277 45.4803C118.413 45.4573 118.511 45.4906 118.571 45.58C118.631 45.6695 118.656 45.7846 118.644 45.9252C118.632 46.066 118.597 46.2256 118.539 46.4045C118.543 46.4007 118.571 46.3711 118.624 46.3158C118.676 46.2607 118.712 46.2236 118.732 46.2043C118.751 46.1855 118.792 46.1465 118.854 46.0875C118.916 46.0287 118.967 45.985 119.006 45.9563C119.045 45.9278 119.1 45.8881 119.172 45.8365C119.244 45.7853 119.309 45.7454 119.368 45.7169C119.426 45.6882 119.496 45.6551 119.578 45.6171C119.659 45.5791 119.738 45.5504 119.814 45.5316C119.89 45.5124 119.974 45.4953 120.068 45.4803C120.161 45.4652 120.255 45.4573 120.348 45.4573C121.037 45.4573 121.572 45.6964 121.955 46.1742C122.338 46.6522 122.53 47.3292 122.53 48.205C122.53 49.0435 122.319 49.7053 121.897 50.1908C121.476 50.6763 120.959 50.9191 120.348 50.9191C119.928 50.9191 119.567 50.8309 119.266 50.654C118.964 50.4776 118.743 50.2708 118.603 50.0344V52.3569C118.603 52.4856 118.649 52.5729 118.74 52.6183C118.832 52.6635 118.984 52.6865 119.198 52.6865V53H117.098V52.6865C117.3 52.6865 117.444 52.6635 117.53 52.6178C117.615 52.5724 117.658 52.4849 117.658 52.3556V46.6147C117.658 46.3032 117.485 46.1473 117.139 46.1473C117.08 46.1473 117.018 46.151 116.952 46.1586Z"
                fill="#184766"
            />
        </g>
        <defs>
            <clipPath id="clip0_36_30">
                <rect width="123" height="53" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default OrgPrioryLogo
