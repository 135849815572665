import React from 'react'
import { Button, Modal } from '@psyomics/components'
import { useNavigate } from 'react-router-dom'
import Styles from './ReportNav.module.scss'
import { demoReports } from 'registration/pages/Demo/ClinicalReports/fakeData'
import { Maybe, AllExistingReports } from 'censeo-core/dist'
import CheckCircleIcon from '../../components/CheckCircleIcon'
import * as Sentry from '@sentry/react'
import { downloadFromLink } from '../../hooks/usePdfDownload'

interface Props {
    id: string
    loading: boolean
    pdfErrorToShow: boolean
    closePdfError: (...args: any[]) => any
    foundPdf: boolean
    isDemoAssessment: boolean
    allCompletedReportsForUser?: Maybe<Maybe<AllExistingReports>[]>
    isAuthorisedForPatient?: boolean
    handlePdfDownloadClick: () => void
    showShareConfirmationModal: boolean
    shareError: boolean
    handleShareConfirm: (...args: any[]) => any
    setShowShareConfirmationModal: (val: boolean) => void
    setShareError: (val: boolean) => void
    patientFacing: boolean
}

export const ReportNav: React.FC<Props> = ({
    id,
    loading,
    pdfErrorToShow,
    closePdfError,
    foundPdf,
    isDemoAssessment,
    allCompletedReportsForUser,
    isAuthorisedForPatient,
    handlePdfDownloadClick,
    showShareConfirmationModal,
    shareError,
    handleShareConfirm,
    setShowShareConfirmationModal,
    setShareError,
    patientFacing,
}) => {
    const navigate = useNavigate()

    const handleShareClick = () => {
        setShareError(false)
        setShowShareConfirmationModal(true)
    }

    return (
        <div>
            {
                <div style={{ padding: '0px 2px', display: 'flex', alignItems: 'center', height: '3.5rem' }}>
                    {pdfErrorToShow && (
                        <Modal onClose={closePdfError} header="PDF fetching Error">
                            Failed to download pdf (Report ID: {id}). <br /> If this continues, please contact support
                            at: <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a>
                        </Modal>
                    )}
                    {(foundPdf || isDemoAssessment) && (
                        <Button
                            id="pdf-button"
                            appearance="link"
                            icon="file"
                            label="Export PDF"
                            size="large"
                            onClick={() => {
                                if (isDemoAssessment) {
                                    if (id) {
                                        downloadFromLink(demoReports.pdfLocation(id))
                                    }
                                } else {
                                    handlePdfDownloadClick()
                                }
                            }}
                        ></Button>
                    )}
                    {!patientFacing && (
                        <>
                            {isAuthorisedForPatient === false && (
                                <span style={{ paddingLeft: '1.5rem', position: 'relative', top: '2px' }}>
                                    <Button
                                        appearance="link"
                                        icon="share"
                                        size="large"
                                        label="Share Report with patient"
                                        onClick={handleShareClick}
                                        loading={loading}
                                        aria-label="Share with patient"
                                    />
                                </span>
                            )}
                            {isAuthorisedForPatient === true && (
                                <span className={Styles.share}>
                                    <CheckCircleIcon label="Report shared with patient" />
                                </span>
                            )}
                            {allCompletedReportsForUser && allCompletedReportsForUser.length > 1 && (
                                <div style={{ display: 'inline-block' }}>
                                    <label
                                        style={{
                                            paddingLeft: '1.5rem',
                                            color: 'var(--c-teal-light)',
                                            fontSize: 'var(--t-medium-size)',
                                            fontWeight: 'var(--f-weight-demibold)',
                                        }}
                                        htmlFor="select-for-completed-reports"
                                    >
                                        Report
                                    </label>
                                    <select
                                        style={{ cursor: 'pointer' }}
                                        id="select-for-completed-reports"
                                        className={Styles.reportSelect}
                                        value={id}
                                        onChange={(event) => {
                                            navigate('/report/' + event.target.value)
                                        }}
                                    >
                                        {allCompletedReportsForUser
                                            .slice()
                                            .sort(
                                                (a, b) =>
                                                    (b?.createdAt ? +new Date(b.createdAt) : -1) -
                                                    (a?.createdAt ? +new Date(a.createdAt) : -1)
                                            )
                                            .map((report) => {
                                                if (!report?.assessmentId)
                                                    Sentry.captureException(
                                                        'No assessment id in list of reports for user ' +
                                                            JSON.stringify(allCompletedReportsForUser)
                                                    )
                                                if (!report?.createdAt)
                                                    Sentry.captureException(
                                                        'No createdAt in list of reports for user ' +
                                                            JSON.stringify(allCompletedReportsForUser)
                                                    )
                                                return (
                                                    <option key={report?.assessmentId} value={report?.assessmentId}>
                                                        {(() => {
                                                            if (!report?.createdAt) return 'Unknown Date'
                                                            const date = new Date(report?.createdAt)
                                                            const day = String(date.getDate()).padStart(2, '0')
                                                            const month = String(date.getMonth() + 1).padStart(2, '0')
                                                            const year = date.getFullYear()
                                                            return `${day}/${month}/${year}`
                                                        })()}
                                                    </option>
                                                )
                                            })}
                                    </select>
                                </div>
                            )}
                            {showShareConfirmationModal && (
                                <Modal
                                    header="Please confirm"
                                    showActions={true}
                                    onConfirm={handleShareConfirm}
                                    onClose={() => setShowShareConfirmationModal(false)}
                                >
                                    Are you sure you want to share the report with this patient?
                                </Modal>
                            )}
                            {shareError && (
                                <Modal
                                    header="Something went wrong"
                                    onClose={() => {
                                        setShareError(false)
                                    }}
                                >
                                    Failed to share the report with the patient. Please try again or contact{' '}
                                    <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a> to resolve this
                                    issue.
                                </Modal>
                            )}
                        </>
                    )}
                </div>
            }
        </div>
    )
}
