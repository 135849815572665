import React, { useContext, useRef, useState, useCallback } from 'react'
import * as Papa from 'papaparse'
import * as Sentry from '@sentry/react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Heading, Link, Page, Spinner, Text } from '@psyomics/components'
import Styles from './ReferPatients.module.scss'
import TopNavBar from 'ui/app/components/TopNavBar'
import Validation from 'registration/components/Validation'
import { InfoContext } from 'configure'
import { downloadCSV, generateCSVFile } from '../../utils/csvParser'
import { handleError } from 'registration/utils/errorHandler'
import { uploadFileForScan } from 'data/hooks/uploadFileForScan'
import { pollAvScanResult, ScanResult } from 'data/hooks/pollScanResult'
import { useAuth } from 'registration/context/auth'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import { ThemeProvider, createTheme } from '@mui/material/styles'

const steps = [
    { label: 'Scanning file for viruses' },
    { label: 'Validate file and create referrals' },
    { label: 'Results ready for download' },
]

const ReferPatients: React.FC = () => {
    const { organisationCode } = useParams<{ organisationCode: string }>()
    const navigate = useNavigate()
    const { state } = useAuth()
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const info = useContext(InfoContext)
    const [error, setError] = useState('')
    const [uploading, setUploading] = useState(false)
    const [activeStep, setActiveStep] = useState(1)
    const [completed, setCompleted] = useState(false)
    const practitionerId = state?.user?.id
    const [failedStep, setFailedStep] = useState<number | null>(null)
    const [csvContent, setCsvContent] = useState<string | null>(null)
    const [referralSummary, setReferralSummary] = useState<{ total: number; success: number; failed: number } | null>(
        null
    )

    const handleButtonClick = useCallback(() => {
        setError('')
        setActiveStep(1)
        setCompleted(false)
        setFailedStep(null)
        setReferralSummary(null)
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
        fileInputRef.current?.click()
    }, [])

    const handleGenerateCSV = (referralFile: ScanResult) => {
        const resultsSummary = referralFile.metadata['results-summary']
        generateCSVFile(referralFile.content, (result) => {
            if (!result.data || result.data.length === 0) {
                setError('The file appears to be empty. Please check the content and try again.')
                setFailedStep(3)
                setUploading(false)
                return
            }

            const csvString = Papa.unparse(result.data)
            setCsvContent(csvString)
            setUploading(false)
            setActiveStep((prevStep) => prevStep + 1)
            setCompleted(true)
            setReferralSummary(JSON.parse(resultsSummary))
        })
    }

    const handleDownloadClick = () => {
        if (csvContent) {
            downloadCSV(csvContent, 'referral-results.csv')
        }
    }

    const handleFileUpload = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedFile = event.target.files?.[0]
            if (!selectedFile) return setError('Please select a file to upload.')
            if (selectedFile.type !== 'text/csv') return setError('Invalid file type. Only .csv files are allowed.')

            setUploading(true)

            const fileTimestamp = Date.now().toString()

            if (!practitionerId) return setError('It seems that you are not logged in. Please refresh the page.')

            try {
                // 1. Upload file for virus scan
                const filename = `practitioners/${practitionerId}/referrals/${encodeURIComponent(fileTimestamp)}.csv`
                await uploadFileForScan(selectedFile, filename, info?.avScanApiUrl)

                // 2. Check upload status
                const scannedFile = await pollAvScanResult(
                    filename,
                    (result) => ['CLEAN', 'INFECTED', 'ERROR'].includes(result.tags['scan-status']),
                    info?.avScanApiUrl
                )
                if (scannedFile.tags['scan-status'] !== 'CLEAN') {
                    setError(
                        `We found an issue with file ${selectedFile.name}: ${scannedFile.tags[
                            'scan-status'
                        ].toLowerCase()}. Please check the file and try again.`
                    )
                    setFailedStep(1)
                    setUploading(false)
                    return
                }

                // 3. Fetch for referral validation/creation results
                setActiveStep((prevStep) => prevStep + 1)
                const referralFilename = `practitioners/${practitionerId}/referrals/${encodeURIComponent(
                    fileTimestamp
                )}-results.csv`

                const referralFile = await pollAvScanResult(
                    referralFilename,
                    (result) => ['PROCESSED', 'ERROR'].includes(result.tags['upload-status']),
                    info?.avScanApiUrl
                )

                if (referralFile.tags['upload-status'] === 'ERROR') {
                    const errorMessage = JSON.parse(referralFile.metadata['results-summary']).message
                    setError(errorMessage || 'Unable to parse file or validate referrals.')
                    setFailedStep(activeStep)
                    setUploading(false)
                    return
                }

                // 4. Prepare CSV file for download
                setActiveStep((prevStep) => prevStep + 1)
                handleGenerateCSV(referralFile)
            } catch (err) {
                const errorMessage = 'Failed to upload referrals'
                Sentry.captureException(err, { extra: { errorMessage, practitionerId } })
                setError(
                    handleError(err, 'There was an issue uploading your file. Please try again later.', {
                        practitionerId,
                        fileTimestamp: fileTimestamp,
                    })
                )
                setFailedStep(activeStep)
                setUploading(false)
            }
        },
        [practitionerId, info, activeStep]
    )

    return (
        <Page width="wide">
            <TopNavBar
                onHomepageClick={() => navigate(`/account/${organisationCode}`)}
                settings={{ enablePrevButton: !uploading, enableHomepageButton: !uploading }}
            />
            <Heading el="h1" size="heading1" color="mid" css={{ mt: 5, mb: 5 }}>
                Refer Patients
            </Heading>
            <Text>Please use the button below to refer patients.</Text>
            <Text css={{ mb: 5 }}>
                If you are having problems or need extra support, please contact us on{' '}
                <Link href="mailto:support@censeo.co.uk">support@censeo.co.uk</Link>.
            </Text>
            <input
                accept=".csv"
                style={{ display: 'none' }}
                id="upload-button-file"
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
            />
            <Button
                size="small"
                appearance="primary"
                label="Upload CSV"
                type="button"
                onClick={handleButtonClick}
                disabled={uploading}
                css={{ mt: 5, mb: 5 }}
            />

            {(uploading || completed || error) && (
                <ThemeProvider theme={theme}>
                    <Box sx={{ maxWidth: 400, mt: 3 }}>
                        <Stepper activeStep={activeStep - 1} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step
                                    key={step.label}
                                    completed={completed || index < activeStep - 1}
                                    sx={{
                                        '& .MuiStepLabel-root .Mui-completed': {
                                            color: getStepColor(activeStep, failedStep, index, referralSummary),
                                        },
                                        '& .MuiStepLabel-root .Mui-error': {
                                            color: getStepColor(activeStep, failedStep, index, referralSummary),
                                        },
                                        '& .MuiStepLabel-root .Mui-active': {
                                            color: getStepColor(activeStep, failedStep, index, referralSummary),
                                        },
                                        '& .MuiStepLabel-root .Mui-warning': {
                                            color: getStepColor(activeStep, failedStep, index, referralSummary),
                                        },
                                    }}
                                >
                                    <StepLabel
                                        error={
                                            (referralSummary &&
                                                referralSummary.failed > 0 &&
                                                index === steps.length - 1) ||
                                            index === failedStep
                                        }
                                        StepIconProps={{
                                            className: getStepIconClassName(index, referralSummary),
                                        }}
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        {activeStep - 1 === index && !completed && !error && <Spinner size="large" />}
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </ThemeProvider>
            )}

            {completed && referralSummary && (
                <Text css={{ mt: 3, mb: 3 }}>
                    {`${referralSummary.success} successful and ${referralSummary.failed} failed referrals. Click Download Results for more information.`}
                </Text>
            )}

            {completed && (
                <Button
                    size="small"
                    appearance="primary"
                    label="Download Results"
                    type="button"
                    onClick={handleDownloadClick}
                    css={{ mt: 5, mb: 5 }}
                />
            )}

            {error && <Validation className={Styles.referPatientsError}>{`Error: ${error}`}</Validation>}
        </Page>
    )
}

const getStepColor = (
    activeStep: number | null,
    failedStep: number | null,
    index: number,
    referralSummary: { total: number; failed: number } | null
) => {
    if (!referralSummary) {
        if (failedStep === index) {
            return 'red'
        }
        if (activeStep && activeStep - 1 === index) {
            return '#1976d2'
        }
        return 'green'
    }
    if (index === steps.length - 1) {
        if (referralSummary.failed === referralSummary.total) {
            return 'red'
        }
        if (referralSummary.failed > 0) {
            return 'orange'
        }
    }
    return 'green'
}

const getStepIconClassName = (index: number, referralSummary: { total: number; failed: number } | null) => {
    if (!referralSummary) return ''
    if (index === steps.length - 1) {
        if (referralSummary.failed === referralSummary.total) return 'Mui-error'
        if (referralSummary.failed > 0) return 'Mui-warning'
    }
    return 'Mui-completed'
}

const theme = createTheme({
    components: {
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-completed': {
                        color: 'green',
                    },
                    '&.Mui-error': {
                        color: 'red',
                    },
                    '&.Mui-active': {
                        color: '#1976d2',
                    },
                    '&.Mui-warning': {
                        color: '#ffa726',
                    },
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    '&.Mui-completed': {
                        borderColor: 'green',
                    },
                    '&.Mui-error': {
                        borderColor: 'red',
                    },
                    '&.Mui-active': {
                        borderColor: '#1976d2',
                    },
                },
            },
        },
    },
})

export default ReferPatients
