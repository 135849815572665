import React from 'react'
import { Page, Heading, Text } from '@psyomics/components'
import Header from 'ui/app/components/Header'
import Styles from './ClinicianIFU.module.scss'
import {
    ConditionsCoverage,
    ForeseeableMedicalEmergencySituations,
    IncidentReporting,
    ScopeAndLimitations,
    SoftwareInformation,
    Warnings,
} from './shared/IFUCommon'

interface ClinicianIfuProps {
    showMenu?: boolean
}
const ClinicianIFU: React.FC<ClinicianIfuProps> = ({ showMenu = true }) => {
    return (
        <Page width="wide" header={showMenu ? <Header patientFacing={false}></Header> : <></>}>
            <Heading el="h1" size="heading1" color="mid" css={{ mt: 5, mb: 5 }}>
                Instructions for Use
            </Heading>

            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                What is Censeo?
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The &apos;Censeo Digital&apos; medical device is software intended for use as a clinical decision
                support tool for mental health concerns in patients/service users who have been referred to Censeo by a
                healthcare service. This software is intended to be used by patients/service users aged 18-65 anywhere
                in which they have internet access with an internet-enabled device.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                This medical device is inappropriate for being used to make a diagnosis or as a risk assessment tool in
                place of a clinician directly assessing a patient. This medical device should not be used as a crisis
                support tool.{' '}
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                This medical device software uses service users&apos; answers to produce a mental health report. The
                report informs clinicians in making triage, assessment or treatment decisions related to mental health,
                by providing a consistent and comprehensive set of data to assist their decision-making.
            </Text>
            <ScopeAndLimitations />

            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Intended User
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The Censeo Digital report is to be used by healthcare professionals with appropriate clinical training
                in mental health assessment or treatment. This includes, but is not limited to:{' '}
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ul className={Styles.unorderedList}>
                    <li>
                        Mental health specialists such as psychiatrists, clinical psychologists, and mental health
                        nurses.
                    </li>
                    <li>
                        General practitioners (GPs) and other primary care providers who are involved in the initial
                        assessment, triage, and referral of patients with potential mental health conditions.
                    </li>
                </ul>
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>Both categories of professionals must be: </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.orderedList}>
                    <li>
                        Trained and competent to make autonomous clinical decisions about a patient&apos;s mental
                        health, including triage, referral, and treatment planning.
                    </li>
                    <li>
                        Experienced in interpreting mental health reports that reference DSM-5-TR and ICD-11 diagnosable
                        mental health-related conditions.
                    </li>
                    <li>
                        Able to integrate the report&apos;s triage prioritisation categories for suicidality risk,
                        self-harm risk, level of distress due to trauma symptoms, and tendency to engage in risky
                        impulsive behaviour into their clinical decision-making.
                    </li>
                    <li>
                        Capable of considering relevant social factors like the patient&apos;s housing situation,
                        relationship status, and employment status when determining appropriate next steps.
                    </li>
                    <li>
                        Responsible for making the final professional judgement on triage, referral and treatment
                        recommendations for the patient, using the Censeo Digital report as one component of a
                        comprehensive evaluation.
                    </li>
                </ol>
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                The required qualifications and training refer to the user&apos;s clinical expertise, not to specific
                training in the use of the Censeo Digital device itself. All users must comply with all relevant
                information management and security procedures at all times.
            </Text>

            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Intended Use Environment
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The Censeo Digital report is designed for use in clinical environments where triage, assessment, or
                treatment decisions are made, such as hospitals, mental health clinics, primary care settings and remote
                environments including professionals working from home. The report is accessed through a secure platform
                with appropriate authentication and authorisation. Compliance with data protection and security
                standards is required.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Patients can complete the adaptive questionnaire anywhere with internet access and an internet-enabled
                device.
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Patient indications for use
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.unorderedList}>
                    <li>Patients with mental health concerns aged 18-65</li>
                    <li>Mental health service provider referral to Censeo Digital.</li>
                </ol>
            </Text>

            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Mental healthcare professional indications for use
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.unorderedList}>
                    <li>
                        A mental healthcare professional requires a comprehensive summary of patient-reported symptoms
                        to support the decision-making process for clinical triage, assessment or treatment.
                    </li>
                </ol>
            </Text>
            <ConditionsCoverage />
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Contraindications for use:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.unorderedList}>
                    <li>
                        Using Censeo Digital by itself to diagnose or perform diagnostic activities - Censeo Digital
                        provides information that a clinician can consider alongside other inputs, and must use their
                        own expertise when performing diagnostic tasks.
                    </li>
                    <li>
                        Using the device as a risk assessment tool - triage information is only provided to inform
                        triage prioritisation.
                    </li>
                    <li>Patients with any indication of neurological deficit.</li>
                    <li>Using the device as a crisis support tool.</li>
                    <li>Using Censeo Digital without a basic understanding of the English language.</li>
                </ol>
            </Text>

            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                How it Works:
            </Heading>
            <Heading el="h3" size="heading3" color="mid" css={{ mt: 5, mb: 4 }}>
                1. Referral:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The service user will receive a referral to their phone, they will then use this unique link to sign up
                for the Censeo platform. This will ask them to enter an email address and password & additional
                demographic information used for identification purposes.
            </Text>
            <Heading el="h3" size="heading3" color="mid" css={{ mt: 5, mb: 4 }}>
                2. Reminders:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                After referral, Censeo will periodically remind them to complete the questionnaire on three occasions if
                they have not already done so. They can complete the questionnaire or opt out of these reminders which
                are available on the reminder SMS.
            </Text>
            <Heading el="h3" size="heading3" color="mid" css={{ mt: 5, mb: 4 }}>
                3. Questionnaire:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                They will answer questions regarding their mental health symptoms and other related factors such as
                their employment situation & current social context. These questions presented are adaptive based on
                their previously chosen answers.
            </Text>
            <Heading el="h3" size="heading3" color="mid" css={{ mt: 5, mb: 4 }}>
                4. Report Generation:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo analyses the responses and, once complete, produces a comprehensive mental health report which is
                delivered to the appropriate mental health service provider.
            </Text>
            <Heading el="h3" size="heading3" color="mid" css={{ mt: 5, mb: 4 }}>
                5. Crisis Support:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Throughout the questionnaire, crisis support information and contact details are prominently displayed
                for immediate access if needed. This information is in the top left-hand corner of your screen available
                after clicking on the logo shown for urgent help.
            </Text>
            <Heading el="h3" size="heading3" color="mid" css={{ mt: 5, mb: 4 }}>
                6. Review Process:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Only fully completed assessments are sent to and reviewed by clinicians. Partial or incomplete
                assessments are not transmitted and therefore not evaluated.
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Service User Referral
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                In the majority of occasions, referrals to Censeo are automatically sent to Censeo via a technical
                integration, which does not require any manual steps to be completed. On these occasions, you will not
                be involved in the referral process.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                The referral portal feature is a different method of referring service users to Censeo. It allows manual
                referrals to Censeo via a feature on the Censeo web portal. After logging into your Censeo account, you
                will see the &apos;Refer Patients&apos; button. Using this page you will then be able to refer service
                users to Censeo.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Detailed instructions and training on the standard operation procedure are provided upon implementation
                of the referral portal
            </Text>

            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Service-User Facing Report
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                A report can be made available to service users if the functionality is available and a clinician has
                judged that it is appropriate. To ensure that the report is both informative and appropriate for service
                users, the content and language had to be carefully adjusted for a layperson audience. Key adjustments
                include:
            </Text>

            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.unorderedList}>
                    <li>
                        <span className={Styles.emphasised}>Simplified Language:</span> The clinician-facing
                        report&apos;s medical terminology was replaced with non-technical, user-friendly language. We
                        deliberately avoided condition-based terms that could confuse or alarm users unfamiliar with
                        medical jargon.
                    </li>
                    <li>
                        {' '}
                        <span className={Styles.emphasised}>Psycho-Educational Material:</span> To add value, we
                        included psycho-educational content. This provides service users with easy-to-understand
                        information on their symptom profiles, helping them make sense of the data without needing
                        specialised knowledge in mental health.
                    </li>
                    <li>
                        {' '}
                        <span className={Styles.emphasised}>Sensitive Information Exclusion:</span> The patient-facing
                        report omits certain sensitive aspects of the clinician&apos;s report, such as triage priority,
                        which could be distressing or misunderstood by the patient. Instead, the focus remains on
                        educational content that promotes understanding without venturing into diagnostic or treatment
                        guidance.
                    </li>
                    <li>
                        {' '}
                        <span className={Styles.emphasised}>Visual Adjustments: </span> The layout and presentation of
                        the report have also been redesigned to be visually intuitive. Charts, graphs, or other data
                        representations are simplified to make the report accessible and easy to navigate for users
                        without a medical background.
                    </li>
                </ol>
            </Text>

            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Performance Characteristics:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo is properly installed and working if the report and dashboard are available. If there is an
                unforeseen issue that occurs whilst you are using Censeo, Censep will notify you of this via an error
                message.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Maintenance and updates are carried out on Censeo to ensure the device is as safe to use as possible.
                Maintenance occurs on an ad hoc basis. Maintenance updates happen on a new version of the device (which
                is then updated as the current version which you use) rather than happening on the “live” device on the
                website. As such any maintenance updates do not impact your ability to use the current version of the
                device.
            </Text>
            <Warnings />
            <ForeseeableMedicalEmergencySituations />
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Decommissioning/uninstalling
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                If you wish to discontinue use of Censeo you can deactivate your account by contacting{' '}
                <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a> for further assistance.
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Product Specification:
            </Heading>
            <table role="presentation" className={Styles.simpleTable}>
                <tr>
                    <td>Hardware Requirement</td>
                    <td>Mobile phone, tablet, or desktop</td>
                </tr>
                <tr>
                    <td>Internet access required</td>
                    <td>
                        Yes, Censeo requires access to the internet to view the online version of the mental health
                        report. The PDF in some occasions may be available offline.
                    </td>
                </tr>
                <tr>
                    <td>Minimum Internet browser version requirements</td>
                    <td>
                        Censeo is compatible with the following browsers:{' '}
                        <ul className={Styles.unorderedList}>
                            <li>Chrome</li>
                            <li>Firefox </li>
                            <li>iOS Safari</li>
                            <li>Opera </li>
                            <li>Opera mobile </li>
                            <li>Android - Chrome browser</li>
                            <li>Android - Firefox browser</li>
                            <li>Edge </li>
                            <li>Samsung browser</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Support Languages</td>
                    <td>en-GB</td>
                </tr>
            </table>
            <IncidentReporting />
            <SoftwareInformation />
        </Page>
    )
}

export default ClinicianIFU
