import React, { useContext } from 'react'
import ContentPage from '../components/ContentPage'
import { useNavigate } from 'react-router'
import { Text, Heading } from '@psyomics/components'
import { InfoContext } from '../../../configure'
import RefIcon from '../images/ref-icon.svg?react'
import ReleaseIcon from '../images/release-date-icon.svg?react'
import ManufacturerIcon from '../images/manufacturer-icon.svg?react'
import UkcaIcon from '../images/ukca-icon.svg?react'
import Styles from './RegulatoryInformation.module.scss'
import * as Sentry from '@sentry/react'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

export const RegulatoryInformation: React.FC = () => {
    const { demo } = useDemoContext()
    const navigate = useNavigate()
    const onMyAccount = () => {
        navigate(demo ? '/demo/service-user/userprofile' : '/userprofile')
    }
    const info = useContext(InfoContext)
    const releaseDate = info?.date
        ? new Date(info.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })
        : 'No release date information'
    const version = info?.appVersion ? info?.appVersion : 'No version information'
    if (!info?.appVersion) {
        Sentry.captureMessage('No app version found for regulatory page')
    }
    return (
        <ContentPage headingText={'About this app'} headingButton={{ text: 'My Account', link: onMyAccount }}>
            <div className={Styles.content}>
                <Heading el="h2" size="heading3" className={Styles.heading}>
                    How this app works
                </Heading>
                <Text el="p" css={{ mb: 4 }}>
                    This app lets you complete an assessment questionnaire.
                </Text>
                <Text el="p" css={{ mb: 5 }}>
                    This app provides healthcare professionals with symptom profiles of your mental conditions in order
                    to give you better help and treatment.
                </Text>
                <Heading el="h2" size="heading3" className={Styles.heading}>
                    Software information
                </Heading>
                <Text el="h4">App name</Text>
                <Text weight="demibold" css={{ mb: 4 }} el="p">
                    Censeo Digital
                </Text>
                <div className={Styles.iconRow}>
                    <RefIcon />
                    <div>
                        <Text el="h3">App version</Text>
                        <Text weight="demibold" el="p">
                            {version}
                        </Text>
                    </div>
                </div>
                <div className={Styles.iconRow}>
                    <ReleaseIcon />
                    <div>
                        <Text el="h3">Date of release</Text>
                        <Text weight="demibold" el="p">
                            {releaseDate}
                        </Text>
                    </div>
                </div>
                <div className={Styles.iconRow}>
                    <ManufacturerIcon />
                    <div>
                        <Text el="h3">Manufacturer or maintainer</Text>
                        <Text el="p" weight="demibold">
                            Psyomics Ltd.
                            <br />
                            7 Quy Court Colliers Lane,
                            <br />
                            Stow-Cum-Quy,
                            <br />
                            Cambridge,
                            <br />
                            England,
                            <br />
                            CB25 9AU
                            <br />
                        </Text>
                    </div>
                </div>
                <UkcaIcon />
            </div>
        </ContentPage>
    )
}
