import React from 'react'

const OrgHertsLogo: React.FC = () => (
    <svg viewBox="0 0 330 90" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M189 73.2879H190.84L194.827 80.954H194.98V73.2879H196.207V82.6405H194.52L190.227 74.9744V82.6405H189V73.2879Z"
            fill="#005EB8"
        />
        <path
            d="M198.661 73.2879H200.041V77.2743H204.488V73.2879H205.868V82.6405H204.488V78.5009H200.041V82.6405H198.661V73.2879Z"
            fill="#005EB8"
        />
        <path
            d="M212.615 74.6678C212.002 74.3612 211.542 74.2078 210.928 74.2078C210.162 74.2078 209.242 74.5145 209.242 75.4344C209.242 77.2743 213.229 76.8143 213.229 79.8807C213.229 81.8739 211.695 82.6405 209.855 82.6405C209.242 82.6405 208.628 82.4872 208.015 82.1806L208.168 80.954C208.628 81.1073 209.395 81.414 210.162 81.414C210.928 81.414 212.002 80.954 212.002 79.8807C212.002 77.7342 208.015 78.3475 208.015 75.4344C208.015 73.7479 209.395 72.8279 211.082 72.8279C211.695 72.8279 212.462 72.9813 213.075 73.1346L212.615 74.6678Z"
            fill="#005EB8"
        />
        <path
            d="M218.749 73.2879H223.809V74.5145H220.129V77.2743H223.656V78.5009H220.129V82.6405H218.749V73.2879Z"
            fill="#005EB8"
        />
        <path
            d="M228.41 75.7411C230.557 75.7411 231.937 77.2743 231.937 79.2675C231.937 81.2606 230.557 82.7939 228.41 82.7939C226.416 82.7939 224.883 81.1073 224.883 79.2675C225.036 77.121 226.416 75.7411 228.41 75.7411ZM228.41 81.8739C229.943 81.8739 230.557 80.494 230.557 79.2675C230.557 77.8876 229.79 76.661 228.41 76.661C227.03 76.661 226.263 77.8876 226.263 79.2675C226.263 80.494 227.03 81.8739 228.41 81.8739Z"
            fill="#005EB8"
        />
        <path
            d="M239.757 82.6405H238.531V81.5673C238.224 82.3339 237.304 82.7939 236.23 82.7939C234.39 82.7939 233.624 81.7206 233.624 79.8807V75.7411H234.85V79.2675C234.85 80.8007 235.157 81.5673 236.384 81.7206C237.764 81.7206 238.531 80.494 238.531 78.9608V75.7411H239.757V82.6405Z"
            fill="#005EB8"
        />
        <path
            d="M241.904 75.8944H243.131V76.9676C243.438 76.201 244.358 75.7411 245.431 75.7411C247.271 75.7411 248.038 76.8143 248.038 78.6542V82.6405H246.811V79.1141C246.811 77.5809 246.505 76.661 245.431 76.661C244.051 76.661 243.284 77.8876 243.284 79.4208V82.6405H242.058V75.8944H241.904Z"
            fill="#005EB8"
        />
        <path
            d="M254.939 81.7206C254.632 82.1806 253.712 82.7939 252.638 82.7939C250.492 82.7939 249.725 80.954 249.725 79.1141C249.725 77.2743 250.645 75.5877 252.638 75.5877C253.865 75.5877 254.479 76.0477 254.939 76.661V72.5213H256.165V82.6405H254.939V81.7206ZM252.945 81.8739C254.325 81.8739 254.939 80.494 254.939 79.2675C254.939 78.0409 254.325 76.661 252.945 76.661C251.565 76.661 251.105 77.8876 251.105 79.2675C250.952 80.3407 251.565 81.8739 252.945 81.8739Z"
            fill="#005EB8"
        />
        <path
            d="M258.619 76.3543C259.232 75.8944 259.999 75.7411 260.919 75.7411C262.913 75.7411 263.679 76.661 263.679 78.5009V81.2606C263.679 82.0273 263.679 82.3339 263.679 82.6405H262.453V81.7206C262.146 82.1806 261.532 82.7939 260.306 82.7939C258.772 82.7939 257.852 82.1806 257.852 80.6474C257.852 78.9608 259.692 78.3475 261.072 78.3475C261.532 78.3475 261.993 78.3475 262.453 78.3475C262.453 77.121 261.993 76.5077 260.766 76.5077C259.999 76.5077 259.232 76.8143 258.772 77.2743L258.619 76.3543ZM262.453 79.4208C262.146 79.4208 261.839 79.4208 261.686 79.4208C261.072 79.4208 259.232 79.5741 259.232 80.8007C259.232 81.5673 259.999 81.8739 260.459 81.8739C261.686 81.8739 262.299 81.1073 262.299 80.0341V79.4208H262.453Z"
            fill="#005EB8"
        />
        <path
            d="M269.353 76.8143H267.513V80.494C267.513 81.2606 267.973 81.7206 268.586 81.7206C268.893 81.7206 269.2 81.5673 269.506 81.5673V82.6405C269.2 82.7939 268.893 82.7939 268.433 82.7939C267.053 82.7939 266.286 82.1806 266.286 80.8007V76.8143H264.753V75.7411H266.286V74.2078L267.513 73.7479V75.7411H269.353V76.8143Z"
            fill="#005EB8"
        />
        <path
            d="M271.193 82.6405H272.42V75.7411H271.193V82.6405ZM271.04 74.3612H272.42V72.9813H271.04V74.3612Z"
            fill="#005EB8"
        />
        <path
            d="M277.787 75.7411C279.934 75.7411 281.314 77.2743 281.314 79.2675C281.314 81.2606 279.781 82.7939 277.787 82.7939C275.64 82.7939 274.26 81.1073 274.26 79.2675C274.26 77.121 275.64 75.7411 277.787 75.7411ZM277.787 81.8739C279.321 81.8739 279.934 80.494 279.934 79.2675C279.934 77.8876 279.167 76.661 277.787 76.661C276.407 76.661 275.64 77.8876 275.64 79.2675C275.64 80.494 276.254 81.8739 277.787 81.8739Z"
            fill="#005EB8"
        />
        <path
            d="M283.001 75.8944H284.228V76.9676C284.534 76.201 285.454 75.7411 286.528 75.7411C288.368 75.7411 289.135 76.8143 289.135 78.6542V82.6405H287.908V79.1141C287.908 77.5809 287.601 76.661 286.375 76.661C284.994 76.661 284.228 77.8876 284.228 79.4208V82.6405H283.001V75.8944Z"
            fill="#005EB8"
        />
        <path d="M296.955 74.5145H294.042V73.2879H301.249V74.5145H298.335V82.6405H296.955V74.5145Z" fill="#005EB8" />
        <path
            d="M301.402 75.8944H302.629V76.9676C302.936 76.201 303.703 75.7411 304.469 75.7411C304.776 75.7411 305.083 75.7411 305.236 75.8944V76.9676C304.929 76.8143 304.776 76.8143 304.469 76.8143C303.396 76.8143 302.629 77.8876 302.629 79.5741V82.6405H301.402V75.8944Z"
            fill="#005EB8"
        />
        <path
            d="M312.75 82.6405H311.523V81.5673C311.217 82.3339 310.296 82.7939 309.223 82.7939C307.383 82.7939 306.616 81.7206 306.616 79.8807V75.7411H307.843V79.2675C307.843 80.8007 308.15 81.5673 309.376 81.7206C310.756 81.7206 311.523 80.494 311.523 78.9608V75.7411H312.75V82.6405Z"
            fill="#005EB8"
        />
        <path
            d="M314.283 81.414C314.744 81.7206 315.51 81.8739 315.817 81.8739C316.43 81.8739 317.044 81.7206 317.044 80.954C317.044 79.7274 314.13 79.8807 314.13 77.8876C314.13 76.5077 315.204 75.7411 316.584 75.7411C317.197 75.7411 317.657 75.8944 318.117 76.0477V77.121C317.81 76.9676 317.044 76.8143 316.737 76.8143C316.124 76.8143 315.51 77.121 315.51 77.5809C315.51 78.9608 318.424 78.6542 318.424 80.8007C318.424 82.3339 317.197 82.9472 315.97 82.9472C315.357 82.9472 314.59 82.7939 314.13 82.4872L314.283 81.414Z"
            fill="#005EB8"
        />
        <path
            d="M323.791 76.8143H321.951V80.494C321.951 81.2606 322.411 81.7206 323.024 81.7206C323.331 81.7206 323.638 81.5673 323.944 81.5673V82.6405C323.638 82.7939 323.178 82.7939 322.717 82.7939C321.337 82.7939 320.571 82.1806 320.571 80.8007V76.8143H319.037V75.7411H320.571V74.2078L321.797 73.7479V75.7411H323.638V76.8143H323.791Z"
            fill="#005EB8"
        />
        <path
            d="M253 33.8842H323.692V5.21295H253V33.8842ZM268.795 30.971L264.041 15.1789L260.821 30.971H254.993L259.901 7.97274H267.568L272.322 23.9182L275.542 7.97274H281.369L276.462 30.971H268.795ZM292.257 30.971L294.25 21.1584H286.889L284.896 30.971H278.762L283.516 7.97274H289.65L287.963 16.8654H295.323L297.164 7.97274H303.297L298.544 30.971H292.257ZM319.552 13.1857C318.325 12.5724 316.792 12.1124 314.492 12.1124C312.038 12.1124 310.045 12.4191 310.045 14.2589C310.045 17.4787 318.939 16.2521 318.939 23.3049C318.939 29.7445 312.958 31.2777 307.744 31.2777C305.444 31.2777 302.684 30.6644 300.691 30.0511L302.071 25.2981C303.297 26.0647 305.751 26.678 307.744 26.678C309.585 26.678 312.651 26.3714 312.651 23.9182C312.651 20.2385 303.757 21.6184 303.757 15.1789C303.757 9.19932 308.971 7.51278 314.032 7.51278C316.792 7.51278 319.552 7.81942 321.086 8.58603L319.552 13.1857Z"
            fill="#005EB8"
        />
        <path
            d="M6.84015 46.1499V52.2828H13.8941V46.1499H15.7342V60.5622H13.8941V53.816H6.84015V60.5622H5V46.1499H6.84015Z"
            fill="#005EB8"
        />
        <path
            d="M20.0279 55.8092C20.0279 58.4157 21.7147 59.4889 23.5548 59.4889C24.935 59.4889 25.7017 59.1823 26.4684 58.8756L26.7751 60.2555C26.1617 60.5622 24.935 60.8688 23.2482 60.8688C20.0279 60.8688 18.1877 58.7223 18.1877 55.6559C18.1877 52.5894 20.0279 49.983 23.0948 49.983C26.4684 49.983 27.3885 52.8961 27.3885 54.8893C27.3885 55.3492 27.3885 55.5026 27.3885 55.8092H20.0279ZM25.5483 54.4293C25.5483 53.2027 25.0883 51.3629 22.9415 51.3629C21.1013 51.3629 20.1812 53.0494 20.0279 54.4293H25.5483Z"
            fill="#005EB8"
        />
        <path
            d="M29.6887 53.5094C29.6887 52.2828 29.6887 51.2095 29.5353 50.2896H31.2221V52.2828H31.3755C31.8355 50.9029 32.9089 49.983 34.2891 49.983C34.4424 49.983 34.5957 49.983 34.7491 49.983V51.8228C34.5957 51.8228 34.2891 51.8228 34.1357 51.8228C32.7556 51.8228 31.8355 52.8961 31.5288 54.276C31.5288 54.5826 31.3755 54.8893 31.3755 55.1959V60.7155H29.5353V53.5094H29.6887Z"
            fill="#005EB8"
        />
        <path
            d="M39.5028 47.3765V50.2896H42.263V51.6695H39.5028V57.3424C39.5028 58.569 39.8095 59.3356 40.8829 59.3356C41.3429 59.3356 41.803 59.3356 41.9563 59.1823L42.1097 60.5622C41.803 60.7155 41.1896 60.8688 40.4229 60.8688C39.5028 60.8688 38.8894 60.5622 38.4294 60.1022C37.816 59.4889 37.6626 58.569 37.6626 57.3424V51.6695H35.9758V50.2896H37.6626V47.8365L39.5028 47.3765Z"
            fill="#005EB8"
        />
        <path
            d="M44.5632 60.7155V51.8228H43.0297V50.4429H44.5632V49.983C44.5632 48.4497 44.8699 47.2232 45.7899 46.3032C46.5567 45.6899 47.4768 45.3833 48.3968 45.3833C49.0102 45.3833 49.6236 45.5366 50.0836 45.6899L49.7769 47.2232C49.4702 47.0698 49.1636 46.9165 48.5502 46.9165C47.0167 46.9165 46.5567 48.2964 46.5567 49.983V50.5962H49.0102V51.9761H46.4033V60.8688H44.5632V60.7155Z"
            fill="#005EB8"
        />
        <path
            d="M59.7444 55.3492C59.7444 59.1823 57.1375 60.8688 54.5307 60.8688C51.7704 60.8688 49.6236 58.8756 49.6236 55.5026C49.6236 51.9761 51.9238 49.983 54.8374 49.983C57.7509 49.983 59.7444 52.2828 59.7444 55.3492ZM51.4637 55.5026C51.4637 57.8024 52.8439 59.4889 54.684 59.4889C56.5242 59.4889 57.9043 57.8024 57.9043 55.5026C57.9043 53.816 56.9842 51.5162 54.8374 51.5162C52.6905 51.5162 51.4637 53.5094 51.4637 55.5026Z"
            fill="#005EB8"
        />
        <path
            d="M62.198 53.5094C62.198 52.2828 62.198 51.2095 62.0446 50.2896H63.7314V52.2828H63.8848C64.3448 50.9029 65.4182 49.983 66.7983 49.983C66.9517 49.983 67.105 49.983 67.2584 49.983V51.8228C67.105 51.8228 66.7983 51.8228 66.645 51.8228C65.2649 51.8228 64.3448 52.8961 64.0381 54.276C64.0381 54.5826 63.8848 54.8893 63.8848 55.1959V60.7155H62.0446V53.5094H62.198Z"
            fill="#005EB8"
        />
        <path
            d="M77.9926 45.3833V57.9557C77.9926 58.8756 77.9926 59.9489 78.1459 60.7155H76.4591L76.3058 58.8756C75.6924 60.1022 74.4656 60.8688 72.7788 60.8688C70.3253 60.8688 68.3318 58.7223 68.3318 55.6559C68.3318 52.1295 70.4786 50.1363 72.9322 50.1363C74.4656 50.1363 75.539 50.9029 75.9991 51.6695V45.5366H77.9926V45.3833ZM75.9991 54.5826C75.9991 54.276 75.9991 53.9693 75.8457 53.816C75.539 52.5894 74.4656 51.6695 73.0855 51.6695C71.092 51.6695 70.0186 53.356 70.0186 55.6559C70.0186 57.8024 71.092 59.4889 73.0855 59.4889C74.3123 59.4889 75.539 58.7223 75.8457 57.1891C75.8457 56.8824 75.9991 56.7291 75.9991 56.4225V54.5826Z"
            fill="#005EB8"
        />
        <path
            d="M80.7528 58.7223C81.3662 59.029 82.2863 59.4889 83.2063 59.4889C84.5864 59.4889 85.1998 58.8756 85.1998 57.9557C85.1998 57.0358 84.7398 56.5758 83.2063 56.1158C81.3662 55.5025 80.4461 54.4293 80.4461 53.2027C80.4461 51.5162 81.8262 50.1363 83.9731 50.1363C85.0465 50.1363 85.9666 50.4429 86.5799 50.7496L86.2732 52.1295C85.8132 51.8228 85.0465 51.5162 84.1264 51.5162C83.053 51.5162 82.4396 52.1295 82.4396 52.8961C82.4396 53.816 83.053 54.1227 84.4331 54.5826C86.2732 55.1959 87.1933 56.1158 87.1933 57.6491C87.1933 59.4889 85.8132 60.7155 83.3597 60.7155C82.2863 60.7155 81.2128 60.4089 80.4461 59.9489L80.7528 58.7223Z"
            fill="#005EB8"
        />
        <path
            d="M89.4935 45.3833H91.3336V51.8228C91.6403 51.2095 92.1004 50.7496 92.7137 50.4429C93.3271 50.1363 93.9405 49.8296 94.7072 49.8296C96.0873 49.8296 98.3875 50.7496 98.3875 54.276V60.4089H96.5474V54.4293C96.5474 52.7428 95.934 51.3629 94.0938 51.3629C92.8671 51.3629 91.947 52.2828 91.487 53.2027C91.3336 53.5094 91.3336 53.816 91.3336 54.1227V60.4089H89.4935V45.3833Z"
            fill="#005EB8"
        />
        <path
            d="M103.601 47.3765C103.601 47.9898 103.141 48.6031 102.375 48.6031C101.761 48.6031 101.301 48.1431 101.301 47.3765C101.301 46.7632 101.761 46.1499 102.528 46.1499C103.141 46.1499 103.601 46.7632 103.601 47.3765ZM101.454 60.7155V50.2896H103.295V60.7155H101.454Z"
            fill="#005EB8"
        />
        <path
            d="M106.515 53.5094C106.515 52.2828 106.515 51.2095 106.362 50.2896H108.048V52.2828H108.202C108.662 50.9029 109.735 49.983 111.115 49.983C111.269 49.983 111.422 49.983 111.575 49.983V51.8228C111.422 51.8228 111.115 51.8228 110.962 51.8228C109.582 51.8228 108.662 52.8961 108.355 54.276C108.355 54.5826 108.202 54.8893 108.202 55.1959V60.7155H106.362V53.5094H106.515Z"
            fill="#005EB8"
        />
        <path
            d="M114.336 55.8092C114.336 58.4157 116.022 59.4889 117.862 59.4889C119.243 59.4889 120.009 59.1823 120.776 58.8756L121.083 60.2555C120.469 60.5622 119.243 60.8688 117.556 60.8688C114.336 60.8688 112.495 58.7223 112.495 55.6559C112.495 52.5894 114.336 49.983 117.402 49.983C120.776 49.983 121.696 52.8961 121.696 54.8893C121.696 55.3492 121.696 55.5026 121.696 55.8092H114.336ZM119.856 54.4293C119.856 53.2027 119.396 51.3629 117.249 51.3629C115.409 51.3629 114.489 53.0494 114.336 54.4293H119.856Z"
            fill="#005EB8"
        />
        <path
            d="M128.75 46.3032C129.67 46.1499 130.897 45.9966 132.277 45.9966C134.117 45.9966 135.497 46.4566 136.264 47.2232C137.031 47.8365 137.491 48.9097 137.491 50.2896C137.491 51.6695 137.031 52.5894 136.417 53.356C135.497 54.4293 133.81 54.8893 131.97 54.8893C131.357 54.8893 130.897 54.8893 130.437 54.7359V60.5622H128.597V46.3032H128.75ZM130.59 53.356C131.05 53.5094 131.51 53.5094 132.124 53.5094C134.424 53.5094 135.804 52.4361 135.804 50.4429C135.804 48.6031 134.424 47.6831 132.43 47.6831C131.664 47.6831 131.05 47.6831 130.744 47.8365V53.356H130.59Z"
            fill="#005EB8"
        />
        <path
            d="M145.311 60.7155L145.158 59.3356C144.545 60.1022 143.471 60.8688 141.938 60.8688C139.791 60.8688 138.717 59.3356 138.717 57.9557C138.717 55.5026 141.018 54.1227 145.005 54.1227V53.9693C145.005 53.0494 144.698 51.5162 142.704 51.5162C141.784 51.5162 140.711 51.8228 139.944 52.2828L139.484 51.0562C140.404 50.4429 141.631 50.1363 142.858 50.1363C146.078 50.1363 146.845 52.2828 146.845 54.4293V58.2623C146.845 59.1823 146.845 60.1022 146.998 60.7155H145.311ZM145.005 55.3492C143.011 55.3492 140.558 55.6559 140.558 57.6491C140.558 58.8756 141.324 59.4889 142.398 59.4889C143.778 59.4889 144.545 58.569 144.851 57.8024C144.851 57.6491 145.005 57.3424 145.005 57.1891V55.3492Z"
            fill="#005EB8"
        />
        <path
            d="M149.758 53.5094C149.758 52.2828 149.758 51.2095 149.605 50.2896H151.292V52.2828H151.445C151.905 50.9029 152.979 49.983 154.359 49.983C154.512 49.983 154.665 49.983 154.819 49.983V51.8228C154.665 51.8228 154.359 51.8228 154.205 51.8228C152.825 51.8228 151.905 52.8961 151.599 54.276C151.599 54.5826 151.445 54.8893 151.445 55.1959V60.7155H149.605V53.5094H149.758Z"
            fill="#005EB8"
        />
        <path
            d="M159.572 47.3765V50.2896H162.333V51.6695H159.572V57.3424C159.572 58.569 159.879 59.3356 160.953 59.3356C161.413 59.3356 161.873 59.3356 162.026 59.1823L162.179 60.5622C161.873 60.7155 161.259 60.8688 160.493 60.8688C159.572 60.8688 158.959 60.5622 158.499 60.1022C157.886 59.4889 157.732 58.569 157.732 57.3424V51.6695H156.046V50.2896H157.732V47.8365L159.572 47.3765Z"
            fill="#005EB8"
        />
        <path
            d="M164.48 53.0494C164.48 51.9761 164.48 51.0562 164.326 50.2896H166.013L166.166 51.9761C166.626 51.0562 167.853 49.983 169.54 49.983C170.92 49.983 173.22 50.9029 173.22 54.4293V60.5622H171.38V54.5826C171.38 52.8961 170.767 51.5162 168.927 51.5162C167.7 51.5162 166.78 52.4361 166.32 53.5094C166.166 53.816 166.166 54.1227 166.166 54.4293V60.7155H164.48V53.0494Z"
            fill="#005EB8"
        />
        <path
            d="M177.361 55.8092C177.361 58.4157 179.047 59.4889 180.888 59.4889C182.268 59.4889 183.034 59.1823 183.801 58.8756L184.108 60.2555C183.494 60.5622 182.268 60.8688 180.581 60.8688C177.361 60.8688 175.52 58.7223 175.52 55.6559C175.52 52.5894 177.361 49.983 180.428 49.983C183.801 49.983 184.721 52.8961 184.721 54.8893C184.721 55.3492 184.721 55.5026 184.721 55.8092H177.361ZM183.034 54.4293C183.034 53.2027 182.574 51.3629 180.428 51.3629C178.587 51.3629 177.667 53.0494 177.514 54.4293H183.034Z"
            fill="#005EB8"
        />
        <path
            d="M187.175 53.5094C187.175 52.2828 187.175 51.2095 187.021 50.2896H188.708V52.2828H188.862C189.322 50.9029 190.395 49.983 191.775 49.983C191.928 49.983 192.082 49.983 192.235 49.983V51.8228C192.082 51.8228 191.775 51.8228 191.622 51.8228C190.242 51.8228 189.322 52.8961 189.015 54.276C189.015 54.5826 188.862 54.8893 188.862 55.1959V60.7155H187.021V53.5094H187.175Z"
            fill="#005EB8"
        />
        <path
            d="M193.922 58.7223C194.535 59.029 195.455 59.4889 196.375 59.4889C197.756 59.4889 198.369 58.8756 198.369 57.9557C198.369 57.0358 197.909 56.5758 196.375 56.1158C194.535 55.5025 193.615 54.4293 193.615 53.2027C193.615 51.5162 194.995 50.1363 197.142 50.1363C198.216 50.1363 199.136 50.4429 199.749 50.7496L199.289 52.1295C198.829 51.8228 198.062 51.5162 197.142 51.5162C196.069 51.5162 195.455 52.1295 195.455 52.8961C195.455 53.816 196.069 54.1227 197.449 54.5826C199.289 55.1959 200.209 56.1158 200.209 57.6491C200.209 59.4889 198.829 60.7155 196.375 60.7155C195.302 60.7155 194.229 60.4089 193.462 59.9489L193.922 58.7223Z"
            fill="#005EB8"
        />
        <path
            d="M202.663 45.3833H204.503V51.8228C204.809 51.2095 205.269 50.7496 205.883 50.4429C206.496 50.1363 207.11 49.8296 207.876 49.8296C209.256 49.8296 211.557 50.7496 211.557 54.276V60.4089H209.717V54.4293C209.717 52.7428 209.103 51.3629 207.263 51.3629C206.036 51.3629 205.116 52.2828 204.656 53.2027C204.503 53.5094 204.503 53.816 204.503 54.1227V60.4089H202.663V45.3833Z"
            fill="#005EB8"
        />
        <path
            d="M216.617 47.3765C216.617 47.9898 216.157 48.6031 215.39 48.6031C214.777 48.6031 214.317 48.1431 214.317 47.3765C214.317 46.7632 214.777 46.1499 215.544 46.1499C216.157 46.1499 216.617 46.7632 216.617 47.3765ZM214.624 60.7155V50.2896H216.464V60.7155H214.624Z"
            fill="#005EB8"
        />
        <path
            d="M219.531 53.6627C219.531 52.2828 219.531 51.2095 219.377 50.2896H221.064L221.217 52.1295C221.984 50.9029 223.211 50.1363 224.898 50.1363C227.351 50.1363 229.345 52.2828 229.345 55.3492C229.345 59.029 227.045 60.8688 224.591 60.8688C223.211 60.8688 221.984 60.2555 221.371 59.1823V64.8552H219.531V53.6627ZM221.524 56.4225C221.524 56.7291 221.524 57.0358 221.678 57.1891C221.984 58.569 223.211 59.3356 224.438 59.3356C226.431 59.3356 227.658 57.6491 227.658 55.3492C227.658 53.2027 226.585 51.5162 224.591 51.5162C223.364 51.5162 222.138 52.4361 221.678 53.816C221.678 54.1227 221.524 54.276 221.524 54.5826V56.4225Z"
            fill="#005EB8"
        />
        <path
            d="M238.239 46.1499V54.7359C238.239 57.9557 239.619 59.3356 241.612 59.3356C243.759 59.3356 245.139 57.9557 245.139 54.7359V46.1499H246.98V54.5826C246.98 59.029 244.679 60.8688 241.459 60.8688C238.546 60.8688 236.245 59.1823 236.245 54.7359V46.1499H238.239Z"
            fill="#005EB8"
        />
        <path
            d="M250.2 53.0494C250.2 51.9761 250.2 51.0562 250.047 50.2896H251.733L251.887 51.9761C252.347 51.0562 253.573 49.983 255.26 49.983C256.64 49.983 258.941 50.9029 258.941 54.4293V60.5622H257.1V54.5826C257.1 52.8961 256.487 51.5162 254.647 51.5162C253.42 51.5162 252.5 52.4361 252.04 53.5094C251.887 53.816 251.887 54.1227 251.887 54.4293V60.7155H250.047V53.0494H250.2Z"
            fill="#005EB8"
        />
        <path
            d="M264.308 47.3765C264.308 47.9898 263.848 48.6031 263.081 48.6031C262.467 48.6031 262.007 48.1431 262.007 47.3765C262.007 46.7632 262.467 46.1499 263.234 46.1499C263.848 46.1499 264.308 46.7632 264.308 47.3765ZM262.161 60.7155V50.2896H264.001V60.7155H262.161Z"
            fill="#005EB8"
        />
        <path
            d="M267.988 50.2896L269.981 56.1158C270.288 57.0358 270.595 57.9557 270.748 58.7223C271.055 57.8024 271.208 57.0358 271.668 56.1158L273.662 50.2896H275.655L271.515 60.7155H269.675L265.688 50.2896H267.988Z"
            fill="#005EB8"
        />
        <path
            d="M278.415 55.8092C278.415 58.4157 280.102 59.4889 281.942 59.4889C283.323 59.4889 284.089 59.1823 284.856 58.8756L285.163 60.2555C284.549 60.5622 283.323 60.8688 281.636 60.8688C278.415 60.8688 276.575 58.7223 276.575 55.6559C276.575 52.5894 278.415 49.983 281.482 49.983C284.856 49.983 285.776 52.8961 285.776 54.8893C285.776 55.3492 285.776 55.5026 285.776 55.8092H278.415ZM283.936 54.4293C283.936 53.2027 283.476 51.3629 281.329 51.3629C279.489 51.3629 278.569 53.0494 278.415 54.4293H283.936Z"
            fill="#005EB8"
        />
        <path
            d="M288.076 53.5094C288.076 52.2828 288.076 51.2095 287.923 50.2896H289.61V52.2828H289.763C290.223 50.9029 291.296 49.983 292.677 49.983C292.83 49.983 292.983 49.983 293.137 49.983V51.8228C292.983 51.8228 292.677 51.8228 292.523 51.8228C291.143 51.8228 290.223 52.8961 289.916 54.276C289.916 54.5826 289.763 54.8893 289.763 55.1959V60.7155H287.923V53.5094H288.076Z"
            fill="#005EB8"
        />
        <path
            d="M294.823 58.7223C295.437 59.029 296.357 59.4889 297.277 59.4889C298.657 59.4889 299.27 58.8756 299.27 57.9557C299.27 57.0358 298.81 56.5758 297.277 56.1158C295.437 55.5025 294.517 54.4293 294.517 53.2027C294.517 51.5162 295.897 50.1363 298.044 50.1363C299.117 50.1363 300.037 50.4429 300.651 50.7496L300.191 52.1295C299.731 51.8228 298.964 51.5162 298.044 51.5162C296.97 51.5162 296.357 52.1295 296.357 52.8961C296.357 53.816 296.97 54.1227 298.35 54.5826C300.191 55.1959 301.111 56.1158 301.111 57.6491C301.111 59.4889 299.731 60.7155 297.277 60.7155C296.204 60.7155 295.13 60.4089 294.363 59.9489L294.823 58.7223Z"
            fill="#005EB8"
        />
        <path
            d="M305.711 47.3765C305.711 47.9898 305.251 48.6031 304.484 48.6031C303.871 48.6031 303.411 48.1431 303.411 47.3765C303.411 46.7632 303.871 46.1499 304.638 46.1499C305.251 46.1499 305.711 46.7632 305.711 47.3765ZM303.564 60.7155V50.2896H305.404V60.7155H303.564Z"
            fill="#005EB8"
        />
        <path
            d="M310.925 47.3765V50.2896H313.685V51.6695H310.925V57.3424C310.925 58.569 311.231 59.3356 312.305 59.3356C312.765 59.3356 313.225 59.3356 313.378 59.1823L313.532 60.5622C313.225 60.7155 312.612 60.8688 311.845 60.8688C310.925 60.8688 310.311 60.5622 309.851 60.1022C309.238 59.4889 309.085 58.569 309.085 57.3424V51.6695H307.398V50.2896H309.085V47.8365L310.925 47.3765Z"
            fill="#005EB8"
        />
        <path
            d="M316.598 50.2896L318.899 56.4225C319.205 57.0358 319.359 57.9557 319.512 58.569C319.665 57.9557 319.972 57.1891 320.125 56.4225L322.119 50.2896H324.112L321.352 57.6491C319.972 61.1755 319.052 63.0153 317.825 64.0886C316.905 64.8552 315.985 65.1618 315.525 65.3152L315.065 63.7819C315.525 63.6286 316.138 63.322 316.752 62.862C317.212 62.402 317.979 61.7888 318.285 60.7155C318.439 60.5622 318.439 60.4089 318.439 60.2555C318.439 60.1022 318.439 59.9489 318.285 59.7956L314.452 50.2896H316.598Z"
            fill="#005EB8"
        />
    </svg>
)

export default OrgHertsLogo
