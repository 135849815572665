import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { classNameMaker } from '../../utils'
import Styles from './Welcome.module.scss'
import { Page, Button } from '@psyomics/components'
import Header from '../../components/Header'
import { RegistrationRoutes } from 'registration/context/registration/registration.definitions'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'

export const Homepage: React.FC = ({ ...props }) => {
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { signInClicked } = useMixpanelEvent(mixpanelApiUrl)

    function handleClick(path: string) {
        signInClicked(undefined, undefined)
        navigate(path)
    }
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })

    return (
        <Page>
            <Header template="landing" />
            <div className={containerClass} {...props}>
                <div className={Styles.formActions}>
                    <Button
                        appearance="secondary"
                        fullWidth
                        size="large"
                        label="Sign in"
                        onClick={() => handleClick(RegistrationRoutes.signin)}
                    />
                </div>
            </div>
        </Page>
    )
}
