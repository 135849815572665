import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Heading, Button, Text } from '@psyomics/components'
import Content from 'registration/components/Content'
import OrBreak from 'registration/components/OrBreak'
import NhsButton from 'registration/components/NhsButton'
import { SignUpFormProps } from '../use-Signup'
import Styles from './CreationOptions.module.scss'
import { UserType } from 'censeo-core'
import { useRegistration } from 'registration/context/registration/useRegistration'
import { RegistrationStep } from 'registration/context/registration/registration.definitions'
import { ProgressDotsRegistration } from 'registration/components/ProgressDotsRegistration'
import Card from 'registration/components/Card'

const CreationOptions: React.FC<SignUpFormProps> = () => {
    const navigate = useNavigate()
    const { state, getNextStepRoute } = useRegistration()
    const isUserNhs = state?.userType === UserType.Nhs

    const [demoNhsModal, setDemoNhsModal] = useState<boolean>(false)

    const handleSubmit = async () => {
        // note: TODO mixpanel event here possibly
        navigate(getNextStepRoute(RegistrationStep.creationOptions))
    }

    return (
        <Content>
            <div className={Styles.intro}>
                <Heading el="h1" size="heading2">
                    Create account
                </Heading>
                <Text el="p" size="medium">
                    There are two ways you can complete an assessment. Connect using your NHS account or creating an
                    account through our Censeo platform.
                </Text>
                <Text el="p" size="medium">
                    During this process, we will perform an eligibility check.
                </Text>
            </div>
            {isUserNhs && <ProgressDotsRegistration step={RegistrationStep.creationOptions} />}
            <div className={Styles.buttonContainer}>
                <Button
                    type="button"
                    name="Create an account with Censeo"
                    appearance="primary"
                    fullWidth
                    size="large"
                    label="Create account with Censeo"
                    onClick={handleSubmit}
                />
                <OrBreak />
                <span
                    style={{ position: 'relative' }}
                    onClick={() => {
                        setDemoNhsModal(true)
                    }}
                >
                    <span style={{ pointerEvents: 'none' }}>
                        <NhsButton />
                    </span>
                    <span
                        style={{ cursor: 'pointer', position: 'absolute', top: 0, width: '100%', height: '100%' }}
                    ></span>
                </span>
            </div>
            <div className={`${Styles.demoNhsLoginCard} ${demoNhsModal ? Styles.active : ''}`}>
                <Card>
                    The NHS Login feature, whilst being available to service users, is not accessible within Demo
                    accounts. Please click &quot;Create account with Censeo&quot; to continue.
                </Card>
            </div>
        </Content>
    )
}

export default CreationOptions
