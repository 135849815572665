import React from 'react'

const OrgMidlandsLogo: React.FC = () => (
    <svg viewBox="0 0 325 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M325.379 30.9682V0H249V30.9682H325.379ZM279.71 2.98751L274.436 27.9158H266.16L260.958 10.6655H260.887L257.427 27.9158H251.152L256.467 2.98751H264.769L269.868 20.2735H269.939L273.431 2.98751H279.71ZM303.377 2.98751L298.207 27.9158H291.545L293.755 17.2406H285.875L283.665 27.9158H277L282.171 2.98751H288.833L286.874 12.5242H294.75L296.712 2.98751H303.377ZM322.519 3.6687L320.911 8.63166C319.629 8.02507 317.883 7.4931 315.425 7.4931C312.79 7.4931 310.648 7.88235 310.648 9.88699C310.648 13.4195 320.309 12.1025 320.309 19.6734C320.309 26.5632 313.927 28.3505 308.151 28.3505C305.584 28.3505 302.627 27.7407 300.452 27.0595L302.021 21.9895C303.335 22.8491 305.977 23.42 308.151 23.42C310.223 23.42 313.46 23.0275 313.46 20.4552C313.46 16.4524 303.799 17.9542 303.799 10.9218C303.799 4.48937 309.433 2.56257 314.887 2.56257C317.957 2.56257 320.844 2.88371 322.519 3.6687Z"
                fill="#005EB8"
            />
            <path
                d="M0 40.3654H4.86108L8.4626 51.9229H8.50448L12.106 40.3654H16.9671V55.6176H14.0582V43.0318H14.0163L9.938 55.6176H7.02908L2.9508 43.0318L2.90892 43.0739V55.6176H0V40.3654Z"
                fill="#005EB8"
            />
            <path
                d="M20.2883 39.5123H23.1972V42.3084H20.2883V39.5123ZM20.2883 44.365H23.1972V55.6176H20.2883V44.365Z"
                fill="#005EB8"
            />
            <path
                d="M33.699 54.3493H33.6571C32.7584 55.384 31.618 55.8933 30.2296 55.8803C28.5384 55.8511 27.3303 55.2478 26.6055 54.0671C25.8807 52.9155 25.5263 51.5467 25.5425 49.9605C25.5263 48.358 25.8807 46.9892 26.6055 45.8538C27.3271 44.7185 28.5384 44.1347 30.2296 44.1055C31.6341 44.1055 32.7455 44.6309 33.5702 45.6787H33.612V39.2333H36.521V55.6208H33.699V54.3493ZM29.0989 52.3608C29.2857 52.7112 29.5402 53.0031 29.8591 53.2334C30.1619 53.4799 30.5549 53.6064 31.0317 53.6064C31.5375 53.6064 31.9498 53.4832 32.2687 53.2334C32.6005 52.9999 32.8615 52.7079 33.0515 52.3608C33.2545 51.9975 33.3994 51.605 33.4864 51.1801C33.5573 50.7584 33.5959 50.3497 33.5959 49.9572C33.5959 49.1138 33.3994 48.3191 33.0096 47.5763C32.7938 47.213 32.5264 46.921 32.2075 46.7037C31.8757 46.4864 31.4698 46.3761 30.9931 46.3761C30.0814 46.4053 29.4436 46.8043 29.0828 47.5763C28.9088 47.9266 28.7864 48.3126 28.7123 48.7343C28.6253 49.156 28.5835 49.5647 28.5835 49.9572C28.5641 50.7746 28.7381 51.5726 29.0989 52.3608Z"
                fill="#005EB8"
            />
            <path d="M39.5813 39.2268H42.4902V55.6143H39.5813V39.2268Z" fill="#005EB8" />
            <path
                d="M46.0466 44.9521C47.2901 44.3974 48.5851 44.1152 49.9316 44.099C53.1852 44.0568 54.7927 45.6365 54.7476 48.8414V50.2848C54.7476 51.378 54.7605 52.3154 54.7895 53.1037C54.8185 53.9341 54.8603 54.771 54.9183 55.6176H52.3573C52.2703 54.9331 52.2285 54.372 52.2285 53.9341H52.1866C51.8387 54.5471 51.3555 55.0207 50.7337 55.3548C50.0959 55.7052 49.4323 55.8803 48.7365 55.8803C47.7249 55.8641 46.8326 55.5754 46.0659 55.0077C45.2541 54.4401 44.8418 53.6162 44.8289 52.5392C44.8289 51.6959 45.0158 51.0082 45.3927 50.4859C45.7406 49.9605 46.1883 49.5744 46.7392 49.3279C47.3191 49.0522 47.9343 48.8705 48.5851 48.783C49.2519 48.7116 49.8865 48.6727 50.4953 48.6727H52.0996C52.0996 47.7709 51.8967 47.1351 51.4908 46.7718C51.0849 46.4215 50.4503 46.2463 49.5805 46.2463C48.308 46.2626 47.158 46.668 46.1304 47.4692L46.0466 44.9521ZM48.1534 53.3891C48.4852 53.6227 48.9201 53.7394 49.4548 53.7394C50.3504 53.7265 51.0301 53.3891 51.494 52.7339C51.9128 52.0786 52.1157 51.3066 52.1028 50.4178H50.8433C50.6854 50.4178 50.5179 50.4178 50.3439 50.4178C50.17 50.4178 49.996 50.4308 49.8221 50.46C49.4452 50.5022 49.0908 50.5897 48.759 50.7227C48.4272 50.8525 48.1598 51.0504 47.9569 51.3131C47.7249 51.5758 47.609 51.9262 47.609 52.3608C47.609 52.8279 47.7894 53.1685 48.1534 53.3891Z"
                fill="#005EB8"
            />
            <path
                d="M57.6146 44.365H60.3689V45.896H60.4108C61.3353 44.6861 62.5595 44.0892 64.0767 44.1055C65.3943 44.1217 66.3639 44.5369 66.9856 45.3511C67.5784 46.1685 67.8748 47.2227 67.8748 48.5202V55.6208H64.9658V49.6134C64.9658 49.2501 64.9594 48.877 64.9433 48.4975C64.9304 48.118 64.8563 47.7774 64.7274 47.4692C64.6276 47.1351 64.444 46.8659 64.1863 46.6615C63.9124 46.4734 63.542 46.3761 63.0781 46.3761C62.5562 46.3761 62.1374 46.4994 61.8185 46.7491C61.4996 46.9956 61.2387 47.3103 61.039 47.6898C60.8521 48.0693 60.72 48.4683 60.6492 48.89C60.5622 49.3279 60.5203 49.7366 60.5203 50.1129V55.6208H57.6114V44.365H57.6146Z"
                fill="#005EB8"
            />
            <path
                d="M78.3153 54.3493H78.2734C77.3747 55.384 76.2343 55.8933 74.8459 55.8803C73.1546 55.8511 71.9466 55.2478 71.2218 54.0671C70.497 52.9155 70.1426 51.5467 70.1587 49.9605C70.1426 48.358 70.497 46.9892 71.2218 45.8538C71.9434 44.7185 73.1546 44.1347 74.8459 44.1055C76.2504 44.1055 77.3618 44.6309 78.1864 45.6787H78.2283V39.2333H81.1372V55.6208H78.3153V54.3493ZM73.7151 52.3608C73.902 52.7112 74.1565 53.0031 74.4754 53.2334C74.7782 53.4799 75.1712 53.6064 75.648 53.6064C76.1537 53.6064 76.5661 53.4832 76.885 53.2334C77.2168 52.9999 77.4777 52.7079 77.6678 52.3608C77.8707 51.9975 78.0157 51.605 78.1027 51.1801C78.1736 50.7584 78.2122 50.3497 78.2122 49.9572C78.2122 49.1138 78.0157 48.3191 77.6259 47.5763C77.4101 47.213 77.1427 46.921 76.8238 46.7037C76.492 46.4864 76.0861 46.3761 75.6093 46.3761C74.6977 46.4053 74.0598 46.8043 73.699 47.5763C73.5251 47.9266 73.4027 48.3126 73.3286 48.7343C73.2416 49.156 73.1997 49.5647 73.1997 49.9572C73.1804 50.7746 73.3544 51.5726 73.7151 52.3608Z"
                fill="#005EB8"
            />
            <path
                d="M90.7725 46.7232C90.3537 46.5642 89.9478 46.4377 89.558 46.3501C89.1682 46.2788 88.7043 46.2399 88.1696 46.2399C87.7637 46.2399 87.39 46.3209 87.0421 46.4799C86.6652 46.668 86.4719 47.0054 86.4558 47.4855C86.4687 47.9072 86.7393 48.2056 87.2579 48.3807C87.5027 48.4813 87.7798 48.5786 88.0826 48.6662C88.3854 48.7538 88.6914 48.8543 88.9943 48.9711C89.3261 49.0749 89.645 49.2047 89.9478 49.3636C90.2377 49.5388 90.4986 49.7431 90.7274 49.9767C90.9722 50.2232 91.1687 50.5216 91.3136 50.872C91.4425 51.2353 91.5102 51.6797 91.5102 52.2051C91.4812 53.5026 91.0044 54.4401 90.0766 55.024C89.1521 55.5916 88.0665 55.8771 86.823 55.8771C85.7084 55.8609 84.6099 55.7019 83.5243 55.397L83.6983 52.9934C84.6099 53.4897 85.5635 53.7362 86.5621 53.7362C86.968 53.7362 87.3803 53.6356 87.7991 53.4313C88.234 53.2431 88.4563 52.8863 88.4724 52.3608C88.4724 52.0689 88.408 51.8289 88.2759 51.6407C88.1309 51.4656 87.9344 51.3261 87.6896 51.2255C87.1838 51.0212 86.604 50.833 85.9533 50.6579C85.6504 50.5573 85.3444 50.4308 85.0416 50.2848C84.7388 50.1389 84.4617 49.9507 84.2169 49.7172C83.9721 49.4836 83.782 49.1917 83.6532 48.8446C83.4953 48.5105 83.4148 48.0953 83.4148 47.599C83.4277 46.3761 83.8851 45.4873 84.7807 44.9326C85.6182 44.3779 86.6265 44.1022 87.7959 44.1022C88.4756 44.1022 89.0683 44.1379 89.5741 44.2125C90.067 44.3001 90.5276 44.4006 90.9625 44.5174L90.7725 46.7232Z"
                fill="#005EB8"
            />
            <path
                d="M99.7988 40.3654H103.639C105.346 40.3524 106.815 40.6573 108.042 41.2834C109.286 41.9386 109.924 43.1713 109.953 44.9748C109.936 46.7232 109.379 47.9688 108.281 48.7116C107.182 49.4706 105.794 49.8404 104.115 49.8275H102.837V55.6176H99.7988V40.3654ZM102.837 47.4238H103.9C104.695 47.4401 105.375 47.2779 105.939 46.9438C106.49 46.6096 106.77 46.0128 106.786 45.1532C106.786 44.7023 106.712 44.3228 106.57 44.0179C106.412 43.713 106.2 43.4729 105.942 43.2978C105.391 42.9474 104.711 42.7723 103.903 42.7723H102.84V47.4238H102.837Z"
                fill="#005EB8"
            />
            <path
                d="M112.362 44.9521C113.606 44.3974 114.901 44.1152 116.247 44.099C119.501 44.0568 121.108 45.6365 121.063 48.8414V50.2848C121.063 51.378 121.076 52.3154 121.105 53.1037C121.134 53.9341 121.176 54.771 121.234 55.6176H118.673C118.586 54.9331 118.544 54.372 118.544 53.9341H118.502C118.154 54.5471 117.671 55.0207 117.049 55.3548C116.412 55.7052 115.748 55.8803 115.052 55.8803C114.041 55.8641 113.148 55.5754 112.382 55.0077C111.57 54.4401 111.157 53.6162 111.145 52.5392C111.145 51.6959 111.331 51.0082 111.708 50.4859C112.056 49.9605 112.504 49.5744 113.055 49.3279C113.635 49.0522 114.25 48.8705 114.901 48.783C115.568 48.7116 116.202 48.6727 116.811 48.6727H118.415C118.415 47.7709 118.212 47.1351 117.806 46.7718C117.4 46.4215 116.766 46.2463 115.896 46.2463C114.624 46.2626 113.474 46.668 112.446 47.4692L112.362 44.9521ZM114.469 53.3891C114.801 53.6227 115.236 53.7394 115.77 53.7394C116.666 53.7265 117.346 53.3891 117.81 52.7339C118.228 52.0786 118.431 51.3066 118.418 50.4178H117.159C117.001 50.4178 116.834 50.4178 116.66 50.4178C116.486 50.4178 116.312 50.4308 116.138 50.46C115.761 50.5022 115.406 50.5897 115.075 50.7227C114.743 50.8525 114.475 51.0504 114.273 51.3131C114.041 51.5758 113.925 51.9262 113.925 52.3608C113.925 52.8279 114.105 53.1685 114.469 53.3891Z"
                fill="#005EB8"
            />
            <path
                d="M123.995 44.365H126.578V46.921H126.62C126.707 46.3372 127.026 45.7338 127.574 45.1078C128.108 44.4655 128.811 44.1314 129.677 44.1022C129.967 44.1022 130.264 44.1314 130.566 44.1898V47.1384C130.264 46.9794 129.829 46.8983 129.265 46.8983C128.685 46.8983 128.237 47.0573 127.918 47.3784C127.599 47.6833 127.361 48.0856 127.203 48.5819C127.058 49.049 126.971 49.542 126.942 50.0675C126.913 50.5768 126.9 51.0504 126.9 51.4883V55.6176H123.991V44.365H123.995Z"
                fill="#005EB8"
            />
            <path
                d="M133.324 46.5058H131.175V44.365H133.324V42.1138L136.233 41.1731V44.365H138.816V46.5058H136.233V51.751C136.22 52.2473 136.313 52.669 136.516 53.0193C136.703 53.3988 137.067 53.5935 137.602 53.6097C138.153 53.6097 138.594 53.5091 138.926 53.3048L139.013 55.6208C138.433 55.796 137.718 55.8836 136.864 55.8836C134.519 55.8252 133.34 54.6088 133.327 52.2343V46.5058H133.324Z"
                fill="#005EB8"
            />
            <path
                d="M140.81 44.365H143.565V45.896H143.607C144.531 44.6861 145.755 44.0892 147.272 44.1055C148.59 44.1217 149.56 44.5369 150.181 45.3511C150.774 46.1685 151.07 47.2227 151.07 48.5202V55.6208H148.162V49.6134C148.162 49.2501 148.155 48.877 148.139 48.4975C148.126 48.118 148.052 47.7774 147.923 47.4692C147.823 47.1351 147.64 46.8659 147.382 46.6615C147.108 46.4734 146.738 46.3761 146.274 46.3761C145.752 46.3761 145.333 46.4994 145.014 46.7491C144.695 46.9956 144.434 47.3103 144.235 47.6898C144.048 48.0693 143.916 48.4683 143.845 48.89C143.758 49.3279 143.716 49.7366 143.716 50.1129V55.6208H140.807V44.365H140.81Z"
                fill="#005EB8"
            />
            <path
                d="M163.032 55.0045C162.004 55.5884 160.725 55.8771 159.192 55.8771C155.342 55.8187 153.396 53.8757 153.354 50.0415C153.354 48.3678 153.789 46.96 154.656 45.8247C155.522 44.7023 156.817 44.1282 158.541 44.099C160.58 44.1152 161.991 44.7477 162.774 45.9998C163.512 47.2519 163.872 48.8835 163.859 50.8947H156.135C156.205 51.7964 156.531 52.4906 157.111 52.9707C157.69 53.4799 158.428 53.7362 159.324 53.7362C160.032 53.7362 160.699 53.6194 161.321 53.3859C161.93 53.1815 162.5 52.9188 163.035 52.6009V55.0045H163.032ZM161.079 48.8868C161.05 48.1439 160.854 47.5244 160.493 47.0281C160.116 46.5188 159.523 46.2561 158.715 46.2399C157.919 46.2561 157.304 46.5026 156.869 46.9827C156.434 47.479 156.189 48.1115 156.131 48.8835H161.079V48.8868Z"
                fill="#005EB8"
            />
            <path
                d="M166.201 44.365H168.785V46.921H168.827C168.914 46.3372 169.233 45.7338 169.78 45.1078C170.315 44.4655 171.017 44.1314 171.884 44.1022C172.174 44.1022 172.47 44.1314 172.773 44.1898V47.1384C172.47 46.9794 172.035 46.8983 171.472 46.8983C170.892 46.8983 170.444 47.0573 170.125 47.3784C169.806 47.6833 169.568 48.0856 169.41 48.5819C169.265 49.049 169.178 49.542 169.149 50.0675C169.12 50.5768 169.107 51.0504 169.107 51.4883V55.6176H166.198V44.365H166.201Z"
                fill="#005EB8"
            />
            <path
                d="M181.216 46.7232C180.798 46.5642 180.392 46.4377 180.002 46.3501C179.612 46.2788 179.148 46.2399 178.613 46.2399C178.208 46.2399 177.834 46.3209 177.486 46.4799C177.109 46.668 176.916 47.0054 176.9 47.4855C176.913 47.9072 177.183 48.2056 177.702 48.3807C177.947 48.4813 178.224 48.5786 178.526 48.6662C178.829 48.7538 179.135 48.8543 179.438 48.9711C179.77 49.0749 180.089 49.2047 180.392 49.3636C180.682 49.5388 180.942 49.7431 181.171 49.9767C181.416 50.2232 181.613 50.5216 181.757 50.872C181.886 51.2353 181.954 51.6797 181.954 52.2051C181.925 53.5026 181.448 54.4401 180.52 55.024C179.596 55.5916 178.51 55.8771 177.267 55.8771C176.152 55.8609 175.054 55.7019 173.968 55.397L174.142 52.9934C175.054 53.4897 176.007 53.7362 177.006 53.7362C177.412 53.7362 177.824 53.6356 178.243 53.4313C178.678 53.2431 178.9 52.8863 178.916 52.3608C178.916 52.0689 178.852 51.8289 178.72 51.6407C178.575 51.4656 178.378 51.3261 178.133 51.2255C177.628 51.0212 177.048 50.833 176.397 50.6579C176.094 50.5573 175.788 50.4308 175.485 50.2848C175.183 50.1389 174.906 49.9507 174.661 49.7172C174.416 49.4836 174.226 49.1917 174.097 48.8446C173.939 48.5105 173.859 48.0953 173.859 47.599C173.872 46.3761 174.329 45.4873 175.225 44.9326C176.062 44.3779 177.07 44.1022 178.24 44.1022C178.919 44.1022 179.512 44.1379 180.018 44.2125C180.511 44.3001 180.971 44.4006 181.406 44.5174L181.216 46.7232Z"
                fill="#005EB8"
            />
            <path
                d="M184.212 39.2268H187.121V45.8928H187.163C187.946 44.7283 189.115 44.1314 190.678 44.1022C191.995 44.1184 192.965 44.5336 193.586 45.3478C194.179 46.1652 194.476 47.2195 194.476 48.517V55.6176H191.567V49.6101C191.567 49.2468 191.56 48.8738 191.544 48.4943C191.531 48.1148 191.457 47.7742 191.328 47.466C191.228 47.1319 191.045 46.8627 190.787 46.6583C190.513 46.4702 190.143 46.3729 189.679 46.3729C189.157 46.3729 188.738 46.4961 188.419 46.7459C188.1 46.9924 187.839 47.3071 187.64 47.6866C187.453 48.0661 187.321 48.4651 187.25 48.8868C187.163 49.3247 187.121 49.7334 187.121 50.1097V55.6176H184.212V39.2268Z"
                fill="#005EB8"
            />
            <path
                d="M197.536 39.5123H200.445V42.3084H197.536V39.5123ZM197.536 44.365H200.445V55.6176H197.536V44.365Z"
                fill="#005EB8"
            />
            <path
                d="M203.502 44.365H206.279V45.9609H206.321C206.942 44.7672 208.099 44.1476 209.793 44.1022C211.488 44.1314 212.702 44.7153 213.44 45.8506C214.149 46.9859 214.497 48.3548 214.48 49.9572C214.493 51.5467 214.149 52.9155 213.44 54.0638C212.702 55.2445 211.465 55.8479 209.729 55.8771C209.12 55.8771 208.556 55.7668 208.038 55.5495C207.503 55.3321 206.975 54.9234 206.453 54.3266H206.411V60.2043H203.502V44.365ZM207.039 47.5763C206.633 48.3191 206.43 49.1138 206.43 49.9572C206.414 50.8038 206.611 51.605 207.016 52.3608C207.219 52.7112 207.48 53.0031 207.799 53.2334C208.118 53.4799 208.514 53.6064 208.991 53.6064C209.468 53.6064 209.874 53.4832 210.206 53.2334C210.525 52.9999 210.769 52.7079 210.943 52.3608C211.13 51.9813 211.269 51.5823 211.356 51.1574C211.414 50.7357 211.443 50.3335 211.443 49.9572C211.443 49.143 211.282 48.3483 210.966 47.5763C210.792 47.213 210.547 46.921 210.228 46.7037C209.909 46.4864 209.513 46.3761 209.036 46.3761C208.109 46.402 207.445 46.8043 207.039 47.5763Z"
                fill="#005EB8"
            />
            <path
                d="M222.946 40.3654H225.984V49.1722C225.971 50.4827 226.209 51.5175 226.699 52.2765C227.192 53.0777 228.001 53.4799 229.128 53.4799C230.285 53.4799 231.103 53.081 231.58 52.2765C232.043 51.5207 232.275 50.4859 232.275 49.1722V40.3654H235.313V50.1097C235.255 54.0152 233.193 55.9355 229.128 55.8771C225.063 55.9355 223.001 54.0119 222.943 50.1097V40.3654H222.946Z"
                fill="#005EB8"
            />
            <path
                d="M238.46 44.365H241.218V45.896H241.26C242.184 44.6861 243.409 44.0892 244.926 44.1055C246.243 44.1217 247.213 44.5369 247.835 45.3511C248.428 46.1685 248.724 47.2227 248.724 48.5202V55.6208H245.815V49.6134C245.815 49.2501 245.809 48.877 245.792 48.4975C245.78 48.118 245.705 47.7774 245.577 47.4692C245.477 47.1351 245.293 46.8659 245.035 46.6615C244.762 46.4734 244.391 46.3761 243.927 46.3761C243.405 46.3761 242.987 46.4994 242.668 46.7491C242.349 46.9956 242.088 47.3103 241.885 47.6898C241.698 48.0693 241.566 48.4683 241.495 48.89C241.408 49.3279 241.366 49.7366 241.366 50.1129V55.6208H238.457V44.365H238.46Z"
                fill="#005EB8"
            />
            <path
                d="M251.784 39.5123H254.693V42.3084H251.784V39.5123ZM251.784 44.365H254.693V55.6176H251.784V44.365Z"
                fill="#005EB8"
            />
            <path
                d="M256.516 44.365H259.641L262.418 52.5587H262.46L265.15 44.365H268.059L263.935 55.6176H260.659L256.516 44.365Z"
                fill="#005EB8"
            />
            <path
                d="M278.779 55.0045C277.752 55.5884 276.473 55.8771 274.94 55.8771C271.09 55.8187 269.144 53.8757 269.102 50.0415C269.102 48.3678 269.537 46.96 270.404 45.8247C271.27 44.7023 272.565 44.1282 274.289 44.099C276.328 44.1152 277.739 44.7477 278.522 45.9998C279.259 47.2519 279.62 48.8835 279.607 50.8947H271.882C271.953 51.7964 272.279 52.4906 272.859 52.9707C273.438 53.4799 274.176 53.7362 275.072 53.7362C275.78 53.7362 276.447 53.6194 277.069 53.3859C277.678 53.1815 278.248 52.9188 278.783 52.6009V55.0045H278.779ZM276.827 48.8868C276.798 48.1439 276.602 47.5244 276.241 47.0281C275.864 46.5188 275.271 46.2561 274.463 46.2399C273.667 46.2561 273.052 46.5026 272.617 46.9827C272.182 47.479 271.937 48.1115 271.879 48.8835H276.827V48.8868Z"
                fill="#005EB8"
            />
            <path
                d="M281.949 44.365H284.533V46.921H284.575C284.662 46.3372 284.981 45.7338 285.528 45.1078C286.063 44.4655 286.765 44.1314 287.632 44.1022C287.922 44.1022 288.218 44.1314 288.521 44.1898V47.1384C288.218 46.9794 287.783 46.8983 287.219 46.8983C286.64 46.8983 286.192 47.0573 285.873 47.3784C285.554 47.6833 285.316 48.0856 285.158 48.5819C285.013 49.049 284.926 49.542 284.897 50.0675C284.868 50.5768 284.855 51.0504 284.855 51.4883V55.6176H281.946V44.365H281.949Z"
                fill="#005EB8"
            />
            <path
                d="M296.964 46.7232C296.545 46.5642 296.14 46.4377 295.75 46.3501C295.36 46.2788 294.896 46.2399 294.361 46.2399C293.955 46.2399 293.578 46.3209 293.234 46.4799C292.857 46.668 292.664 47.0054 292.648 47.4855C292.66 47.9072 292.931 48.2056 293.45 48.3807C293.694 48.4813 293.972 48.5786 294.274 48.6662C294.577 48.7538 294.883 48.8543 295.186 48.9711C295.518 49.0749 295.837 49.2047 296.139 49.3636C296.429 49.5388 296.69 49.7431 296.922 49.9767C297.167 50.2232 297.364 50.5216 297.509 50.872C297.637 51.2353 297.705 51.6797 297.705 52.2051C297.676 53.5026 297.199 54.4401 296.272 55.024C295.347 55.5916 294.261 55.8771 293.018 55.8771C291.903 55.8609 290.805 55.7019 289.719 55.397L289.893 52.9934C290.805 53.4897 291.758 53.7362 292.757 53.7362C293.163 53.7362 293.575 53.6356 293.994 53.4313C294.429 53.2431 294.651 52.8863 294.667 52.3608C294.667 52.0689 294.603 51.8289 294.471 51.6407C294.326 51.4656 294.133 51.3261 293.885 51.2255C293.379 51.0212 292.799 50.833 292.148 50.6579C291.845 50.5573 291.539 50.4308 291.237 50.2848C290.934 50.1389 290.657 49.9507 290.412 49.7172C290.167 49.4836 289.977 49.1917 289.848 48.8446C289.687 48.5105 289.61 48.0953 289.61 47.599C289.623 46.3761 290.08 45.4873 290.976 44.9326C291.813 44.3779 292.821 44.1022 293.991 44.1022C294.671 44.1022 295.263 44.1379 295.769 44.2125C296.262 44.3001 296.723 44.4006 297.157 44.5174L296.964 46.7232Z"
                fill="#005EB8"
            />
            <path
                d="M300.025 39.5123H302.933V42.3084H300.025V39.5123ZM300.025 44.365H302.933V55.6176H300.025V44.365Z"
                fill="#005EB8"
            />
            <path
                d="M306.947 46.5058H304.799V44.365H306.947V42.1138L309.856 41.1731V44.365H312.44V46.5058H309.856V51.751C309.84 52.2473 309.937 52.669 310.136 53.0193C310.323 53.3988 310.687 53.5935 311.222 53.6097C311.773 53.6097 312.214 53.5091 312.546 53.3048L312.633 55.6208C312.053 55.796 311.338 55.8836 310.484 55.8836C308.142 55.8252 306.96 54.6088 306.947 52.2343V46.5058Z"
                fill="#005EB8"
            />
            <path
                d="M318.905 52.5782H318.947L321.637 44.3617H324.587L320.4 55.5267C320.184 56.1398 319.958 56.7367 319.726 57.3173C319.494 57.9142 319.214 58.4461 318.879 58.9132C318.56 59.3803 318.154 59.7501 317.665 60.0291C317.143 60.321 316.508 60.467 315.754 60.467C315.058 60.467 314.372 60.3664 313.693 60.1621L313.889 57.9985C314.221 58.1283 314.72 58.1964 315.387 58.1964C315.98 58.1802 316.444 57.992 316.775 57.6287C317.107 57.2654 317.275 56.7821 317.275 56.1852L313 44.365H316.212L318.905 52.5782Z"
                fill="#005EB8"
            />
            <path
                d="M178 63.6589H180.487L184.336 71.2623H184.365V63.6589H186.305V73.8281H183.831L179.968 66.2247H179.939V73.8281H178V63.6589Z"
                fill="#005EB8"
            />
            <path
                d="M188.544 63.6589H190.57V67.7655H194.619V63.6589H196.645V73.8281H194.619V69.3711H190.57V73.8281H188.544V63.6589Z"
                fill="#005EB8"
            />
            <path
                d="M202.36 65.0861C202.186 65.0861 202.012 65.1056 201.838 65.1445C201.655 65.1834 201.481 65.2548 201.316 65.3618C201.162 65.4592 201.033 65.6051 200.927 65.7997C200.82 65.9846 200.769 66.2182 200.769 66.5004C200.769 66.7145 200.836 66.8929 200.972 67.0389C201.107 67.1848 201.275 67.3146 201.478 67.4314C201.7 67.5481 201.941 67.6552 202.202 67.7525C202.454 67.8595 202.718 67.9698 202.998 68.0866C203.278 68.1936 203.549 68.3202 203.81 68.4661C204.08 68.6218 204.316 68.81 204.519 69.0338C204.721 69.2479 204.889 69.5041 205.024 69.8058C205.14 70.1172 205.198 70.4902 205.198 70.9281C205.179 72.0245 204.808 72.8128 204.084 73.2864C203.359 73.7632 202.47 74 201.407 74C200.885 74 200.447 73.9611 200.089 73.8832C199.722 73.8151 199.3 73.7243 198.817 73.6075L199.003 71.7715C199.719 72.1802 200.479 72.3878 201.291 72.3976C201.706 72.3976 202.106 72.2905 202.492 72.0764C202.879 71.8623 203.075 71.5315 203.085 71.0871C203.085 70.8438 203.027 70.6362 202.911 70.461C202.776 70.2859 202.608 70.1399 202.405 70.0231C201.961 69.7896 201.455 69.5658 200.885 69.3517C200.605 69.2349 200.34 69.0987 200.089 68.943C199.828 68.797 199.587 68.6218 199.364 68.4175C199.161 68.2034 198.994 67.9471 198.859 67.6454C198.723 67.3438 198.656 66.9902 198.656 66.5815C198.675 65.4559 199.045 64.6482 199.77 64.1649C200.466 63.7075 201.271 63.4805 202.186 63.4805C203.053 63.4805 203.858 63.6005 204.602 63.8438L204.415 65.5175C203.768 65.2418 203.085 65.0958 202.36 65.0861Z"
                fill="#005EB8"
            />
            <path
                d="M211.055 63.6589H216.666V65.2613H213.078V67.7655H216.492V69.3679H213.078V73.8248H211.052V63.6589H211.055Z"
                fill="#005EB8"
            />
            <path
                d="M218.767 67.1978C219.492 66.5069 220.41 66.1598 221.531 66.1501C222.668 66.1598 223.599 66.5101 224.324 67.1978C225.058 67.8985 225.429 68.8716 225.438 70.1269C225.438 71.2233 225.1 72.1381 224.427 72.8647C223.75 73.6043 222.787 73.9805 221.534 74C220.3 73.9805 219.343 73.601 218.67 72.8647C217.984 72.1348 217.642 71.2233 217.642 70.1269C217.646 68.8716 218.023 67.8952 218.767 67.1978ZM219.691 70.3281C219.711 70.4935 219.733 70.6492 219.762 70.7952C219.82 71.1066 219.917 71.3888 220.052 71.6385C220.197 71.8916 220.39 72.0894 220.632 72.2354C220.864 72.4008 221.163 72.4819 221.527 72.4819C221.904 72.4819 222.217 72.4041 222.468 72.2484C222.71 72.1024 222.903 71.9045 223.048 71.6515C223.193 71.3985 223.29 71.1163 223.338 70.8081C223.386 70.5065 223.409 70.2016 223.409 69.8902C223.409 69.2868 223.261 68.7678 222.961 68.3299C222.642 67.892 222.165 67.6682 221.527 67.6584C220.909 67.6682 220.448 67.8952 220.139 68.3429C219.82 68.7808 219.662 69.2933 219.662 69.8869C219.662 70.0394 219.672 70.1853 219.691 70.3281Z"
                fill="#005EB8"
            />
            <path
                d="M233.798 73.8248H231.962V72.8063H231.933C231.324 73.5848 230.509 73.9805 229.488 74C228.611 73.9903 227.973 73.7081 227.577 73.1534C227.162 72.6084 226.956 71.911 226.956 71.0547V66.3188H228.895V70.3248C228.895 70.4319 228.904 70.5421 228.924 70.6589C228.924 70.7757 228.927 70.8957 228.937 71.0222C228.956 71.2752 228.998 71.512 229.066 71.7358C229.153 71.9499 229.278 72.1251 229.442 72.2613C229.616 72.4073 229.852 72.4787 230.151 72.4787C230.509 72.4787 230.802 72.3878 231.034 72.203C231.256 72.0375 231.424 71.8299 231.54 71.5769C231.675 71.3239 231.762 71.0579 231.8 70.7757C231.839 70.4935 231.858 70.2307 231.858 69.9875V66.3155H233.798V73.8248Z"
                fill="#005EB8"
            />
            <path
                d="M235.792 66.322H237.628V67.3405H237.657C238.276 66.5361 239.091 66.1371 240.102 66.1468C240.978 66.1566 241.626 66.4323 242.041 66.9772C242.438 67.5222 242.634 68.2261 242.634 69.0889V73.8248H240.695V69.8188C240.695 69.5755 240.688 69.329 240.682 69.076C240.672 68.8229 240.624 68.5959 240.537 68.3915C240.469 68.1677 240.35 67.9893 240.176 67.8531C239.993 67.7266 239.748 67.6649 239.438 67.6649C239.091 67.6649 238.81 67.746 238.598 67.9114C238.385 68.0769 238.211 68.2845 238.076 68.5375C237.95 68.7905 237.863 69.0565 237.815 69.3387C237.757 69.6306 237.728 69.9031 237.728 70.1529V73.8248H235.789V66.322H235.792Z"
                fill="#005EB8"
            />
            <path
                d="M249.595 72.9814H249.566C248.967 73.6724 248.207 74.0097 247.279 74C246.152 73.9805 245.346 73.5783 244.863 72.7901C244.38 72.0245 244.145 71.1098 244.155 70.0523C244.145 68.9851 244.38 68.0704 244.863 67.3146C245.346 66.5555 246.152 66.1695 247.279 66.1501C248.213 66.1501 248.958 66.5004 249.508 67.1978H249.537V62.8998H251.477V73.8248H249.595V72.9814ZM246.525 71.6547C246.651 71.8883 246.819 72.0829 247.031 72.2386C247.234 72.4041 247.495 72.4852 247.811 72.4852C248.149 72.4852 248.423 72.4041 248.635 72.2386C248.858 72.0829 249.032 71.8883 249.157 71.6547C249.293 71.4115 249.389 71.1487 249.447 70.8665C249.496 70.5843 249.518 70.3118 249.518 70.0523C249.518 69.4879 249.389 68.9592 249.128 68.4629C248.983 68.2196 248.806 68.025 248.594 67.879C248.371 67.733 248.101 67.6617 247.785 67.6617C247.176 67.6811 246.754 67.9471 246.513 68.4629C246.397 68.6964 246.316 68.9527 246.268 69.2349C246.21 69.5171 246.181 69.7896 246.181 70.0523C246.171 70.5973 246.287 71.1293 246.525 71.6547Z"
                fill="#005EB8"
            />
            <path
                d="M253.803 66.7177C254.631 66.3479 255.497 66.1598 256.393 66.1501C258.564 66.1209 259.633 67.1751 259.604 69.3127V70.2729C259.604 71.0027 259.614 71.6288 259.633 72.151C259.653 72.7057 259.682 73.2636 259.72 73.8248H258.013C257.955 73.3674 257.926 72.9944 257.926 72.7025H257.897C257.665 73.1112 257.343 73.4258 256.927 73.6497C256.502 73.8832 256.061 74 255.597 74C254.92 73.9902 254.328 73.7956 253.819 73.4161C253.278 73.0366 253.004 72.4884 252.994 71.7715C252.994 71.2071 253.12 70.753 253.371 70.4026C253.603 70.0523 253.903 69.7961 254.267 69.6306C254.653 69.4457 255.062 69.3257 255.497 69.2673C255.942 69.2187 256.364 69.196 256.77 69.196H257.839C257.839 68.5926 257.704 68.1709 257.433 67.9277C257.163 67.6941 256.737 67.5773 256.161 67.5773C255.313 67.5871 254.547 67.8595 253.861 68.3948L253.803 66.7177ZM255.207 72.3392C255.429 72.4949 255.719 72.5727 256.074 72.5727C256.673 72.563 257.124 72.3392 257.433 71.9013C257.713 71.4634 257.849 70.9508 257.839 70.3572H256.998C256.892 70.3572 256.782 70.3572 256.666 70.3572C256.551 70.3572 256.435 70.367 256.319 70.3864C256.067 70.4156 255.832 70.474 255.61 70.5616C255.388 70.6492 255.21 70.7789 255.075 70.9541C254.92 71.1292 254.843 71.3628 254.843 71.6547C254.846 71.9661 254.966 72.1932 255.207 72.3392Z"
                fill="#005EB8"
            />
            <path
                d="M262.152 67.7525H260.719V66.3252H262.152V64.8234L264.092 64.1973V66.3252H265.812V67.7525H264.092V71.2493C264.082 71.5801 264.143 71.8623 264.279 72.0959C264.404 72.3489 264.646 72.4787 265.003 72.4884C265.371 72.4884 265.664 72.4203 265.886 72.284L265.944 73.8281C265.557 73.9448 265.081 74.0032 264.511 74.0032C262.948 73.9643 262.162 73.1534 262.152 71.5704V67.7525Z"
                fill="#005EB8"
            />
            <path
                d="M267.184 63.088H269.124V64.9531H267.184V63.088ZM267.184 66.322H269.124V73.8248H267.184V66.322Z"
                fill="#005EB8"
            />
            <path
                d="M271.813 67.1978C272.538 66.5069 273.456 66.1598 274.577 66.1501C275.715 66.1598 276.646 66.5101 277.37 67.1978C278.105 67.8985 278.475 68.8716 278.485 70.1269C278.485 71.2233 278.147 72.1381 277.473 72.8647C276.797 73.6043 275.834 73.9805 274.581 74C273.347 73.9805 272.39 73.601 271.717 72.8647C271.031 72.1348 270.689 71.2233 270.689 70.1269C270.696 68.8716 271.072 67.8952 271.813 67.1978ZM272.741 70.3281C272.761 70.4935 272.783 70.6492 272.812 70.7952C272.87 71.1066 272.967 71.3888 273.102 71.6385C273.247 71.8916 273.44 72.0894 273.682 72.2354C273.914 72.4008 274.213 72.4819 274.577 72.4819C274.954 72.4819 275.267 72.4041 275.518 72.2484C275.76 72.1024 275.953 71.9045 276.098 71.6515C276.243 71.3985 276.339 71.1163 276.388 70.8081C276.436 70.5065 276.459 70.2016 276.459 69.8902C276.459 69.2868 276.31 68.7678 276.011 68.3299C275.692 67.892 275.215 67.6682 274.577 67.6584C273.959 67.6682 273.498 67.8952 273.189 68.3429C272.87 68.7808 272.712 69.2933 272.712 69.8869C272.712 70.0394 272.722 70.1853 272.741 70.3281Z"
                fill="#005EB8"
            />
            <path
                d="M280.002 66.322H281.838V67.3405H281.867C282.486 66.5361 283.301 66.1371 284.312 66.1468C285.189 66.1566 285.836 66.4323 286.252 66.9772C286.648 67.5222 286.844 68.2261 286.844 69.0889V73.8248H284.905V69.8188C284.905 69.5755 284.899 69.329 284.892 69.076C284.883 68.8229 284.834 68.5959 284.747 68.3915C284.68 68.1677 284.56 67.9893 284.386 67.8531C284.203 67.7266 283.958 67.6649 283.649 67.6649C283.301 67.6649 283.021 67.746 282.808 67.9114C282.595 68.0769 282.421 68.2845 282.286 68.5375C282.161 68.7905 282.074 69.0565 282.025 69.3387C281.967 69.6306 281.938 69.9031 281.938 70.1529V73.8248H279.999V66.322H280.002Z"
                fill="#005EB8"
            />
            <path
                d="M294.875 65.2613H291.983V63.6589H299.794V65.2613H296.902V73.8281H294.875V65.2613Z"
                fill="#005EB8"
            />
            <path
                d="M299.62 66.322H301.341V68.025H301.37C301.428 67.6357 301.64 67.2335 302.008 66.815C302.365 66.3869 302.832 66.163 303.412 66.1436C303.605 66.1436 303.802 66.163 304.005 66.202V68.1677C303.802 68.0606 303.512 68.0087 303.138 68.0087C302.752 68.0087 302.452 68.1158 302.243 68.3299C302.03 68.5342 301.872 68.8002 301.766 69.1311C301.669 69.4425 301.611 69.7734 301.592 70.1204C301.573 70.461 301.563 70.7757 301.563 71.0676V73.8216H299.624V66.322H299.62Z"
                fill="#005EB8"
            />
            <path
                d="M312.045 73.8248H310.209V72.8063H310.18C309.571 73.5848 308.756 73.9805 307.735 74C306.859 73.9903 306.221 73.7081 305.825 73.1534C305.409 72.6084 305.203 71.911 305.203 71.0547V66.3188H307.142V70.3248C307.142 70.4319 307.152 70.5421 307.171 70.6589C307.171 70.7757 307.175 70.8957 307.184 71.0222C307.204 71.2752 307.246 71.512 307.313 71.7358C307.4 71.9499 307.526 72.1251 307.69 72.2613C307.864 72.4073 308.099 72.4787 308.399 72.4787C308.756 72.4787 309.05 72.3878 309.281 72.203C309.504 72.0375 309.671 71.8299 309.787 71.5769C309.923 71.3239 310.009 71.0579 310.048 70.7757C310.087 70.4935 310.106 70.2307 310.106 69.9875V66.3155H312.045V73.8248Z"
                fill="#005EB8"
            />
            <path
                d="M318.469 67.8952C318.189 67.7882 317.918 67.7071 317.657 67.6487C317.396 67.6001 317.087 67.5774 316.733 67.5774C316.462 67.5774 316.211 67.6325 315.979 67.7363C315.727 67.8628 315.599 68.0866 315.589 68.4078C315.599 68.69 315.776 68.8878 316.124 69.0046C316.288 69.0727 316.472 69.1344 316.675 69.1927C316.877 69.2511 317.08 69.3193 317.283 69.3971C317.506 69.4652 317.718 69.5528 317.921 69.6598C318.115 69.7766 318.288 69.9129 318.443 70.0686C318.607 70.234 318.736 70.4319 318.833 70.6654C318.92 70.9087 318.962 71.2039 318.962 71.5542C318.942 72.4203 318.623 73.0463 318.008 73.4323C317.39 73.8119 316.668 74 315.837 74C315.093 73.9903 314.362 73.8832 313.637 73.6789L313.753 72.0765C314.362 72.4073 314.996 72.5727 315.663 72.5727C315.934 72.5727 316.207 72.5046 316.488 72.3684C316.778 72.2419 316.926 72.0051 316.935 71.6548C316.935 71.4601 316.894 71.3012 316.807 71.1747C316.71 71.0579 316.581 70.9671 316.417 70.899C316.079 70.7627 315.692 70.6362 315.26 70.5194C315.057 70.4513 314.854 70.3702 314.652 70.2729C314.449 70.1756 314.265 70.0491 314.101 69.8934C313.936 69.7377 313.811 69.5431 313.724 69.3095C313.617 69.0857 313.566 68.81 313.566 68.4791C313.576 67.6649 313.878 67.0713 314.478 66.7015C315.038 66.3317 315.708 66.1469 316.488 66.1469C316.942 66.1469 317.335 66.1728 317.673 66.2182C318.002 66.2766 318.311 66.3447 318.598 66.4226L318.469 67.8952Z"
                fill="#005EB8"
            />
            <path
                d="M321.104 67.7525H319.67V66.3252H321.104V64.8234L323.043 64.1973V66.3252H324.763V67.7525H323.043V71.2493C323.034 71.5801 323.095 71.8623 323.23 72.0959C323.356 72.3489 323.597 72.4787 323.955 72.4884C324.322 72.4884 324.615 72.4203 324.838 72.284L324.896 73.8281C324.509 73.9448 324.032 74.0032 323.462 74.0032C321.9 73.9643 321.114 73.1534 321.104 71.5704V67.7525Z"
                fill="#005EB8"
            />
        </g>
        <defs>
            <clipPath id="clip0_33_506">
                <rect width="325" height="74" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default OrgMidlandsLogo
