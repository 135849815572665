import React from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './DemoFooter.module.scss'
import { Button } from '@psyomics/components'
import { useAuth } from 'registration/context/auth'

interface DemoFooterProps {
    footerType: 'patient' | 'clinician'
}

const DemoFooter: React.FC<DemoFooterProps> = ({ footerType }) => {
    const navigate = useNavigate()
    const { signOutUser } = useAuth()
    return (
        <div className={Styles.demoFooterContainer}>
            <footer className={Styles.demoFooter}>
                {footerType === 'clinician' ? (
                    <>
                        <div className={Styles.demoFooterText}>
                            Use this button to go directly to the service user experience
                        </div>
                        <Button
                            className={Styles.demoFooterButton}
                            label="Service User Experience"
                            onClick={() => {
                                navigate('/demo/service-user/checkin?start')
                            }}
                        />
                    </>
                ) : (
                    <>
                        <div className={Styles.demoFooterText}>
                            To jump back to the clinician&#39;s experience click on this button
                        </div>
                        <Button
                            className={Styles.demoFooterButton}
                            label="Clinician Experience"
                            onClick={async () => {
                                await signOutUser()
                                navigate('/demo/healthcare')
                            }}
                        />
                    </>
                )}
            </footer>
        </div>
    )
}

export default DemoFooter
