import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Heading, Page, Table, Button } from '@psyomics/components'
import { InfoContext } from 'configure'
import { Auth } from 'aws-amplify'
import Error from '../Error'
import { ragScoresNames } from 'censeo-core'
import { DateFilter } from './DateFilter'
import { filteringFunctions } from './filteringFunctions'
import Styles from './PatientReferrals.module.scss'
import { useParams } from 'react-router-dom'
import Header from 'ui/app/components/Header'

interface TableData {
    assessmentCompletedAt?: string
    assessmentCompletedOrCancelled?: string
    patientIdentifierLogical?: string
    patientName?: string
    ragScore?: number
    referralCreatedAt?: string
    suppliedHealthCareId?: string
    quicksilvaStatus?: string
    organisationConfig?: {
        quicksilva: boolean
    }
}

const PatientReferrals: React.FC = () => {
    const info = useContext(InfoContext)
    const [referralsData, setReferralsData] = useState<TableData[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<{ title: string; message: string } | undefined>()
    const [filterSettings, setFilterSettings] = useState<{
        assessmentCompletedAt?: {
            to: string | null
            from: string | null
        }
        referralCreatedAt?: {
            to: string | null
            from: string | null
        }
        suppliedHealthCareId?: string
    }>({})
    const { organisationCode } = useParams<{ organisationCode: string }>()

    const [currentPage, setCurrentPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [totalCount, setTotalCount] = useState<number>(0)

    // TODO: Fix filters before re-enabling, currently only works for the subset of data that is returned by the API
    const filtersEnabled = false

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            const res = await Auth.currentSession()
            const jwt = res.getAccessToken().getJwtToken()
            const result = await fetch(
                `${
                    info?.referralApiUrl
                }practitioner/patient-referrals?organisationCode=${organisationCode}&limit=${pageSize}&offset=${
                    currentPage * pageSize
                }`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (result.status === 401) {
                setErrorMessage({
                    title: 'Access Denied',
                    message: 'Please contact support if you believe you should have access to this page.',
                })
                return
            }
            const jsonParsed = await result.json()
            if (jsonParsed && jsonParsed.data) {
                setReferralsData(jsonParsed.data as TableData[])
                setTotalCount(jsonParsed.totalCount)
            } else {
                setReferralsData([])
                setTotalCount(0)
            }
            setIsLoading(false)
        }
        fetchData()
    }, [organisationCode, currentPage, pageSize])

    const filteredData = useMemo(() => {
        if (!referralsData) {
            return undefined
        }
        let noFilter = true
        Object.values(filterSettings).forEach((value) => {
            if (value !== undefined) noFilter = false
        })
        if (noFilter) {
            return referralsData
        }

        return referralsData.reduce((acc: typeof referralsData, item) => {
            let pass = true
            if (filterSettings.assessmentCompletedAt) {
                const filterResult = filteringFunctions.assessmentCompletedAt(
                    filterSettings.assessmentCompletedAt.from,
                    filterSettings.assessmentCompletedAt.to,
                    item?.assessmentCompletedOrCancelled
                )
                if (filterResult === false) {
                    pass = false
                }
            }
            if (filterSettings.referralCreatedAt) {
                const filterResult = filteringFunctions.referralCreatedAt(
                    filterSettings.referralCreatedAt.from,
                    filterSettings.referralCreatedAt.to,
                    item?.referralCreatedAt
                )
                if (filterResult === false) {
                    pass = false
                }
            }
            if (pass) {
                acc.push(item)
            }
            return acc
        }, [] as typeof referralsData)
    }, [filterSettings, referralsData])

    const adjustedTotalCount = useMemo(() => {
        let noFilter = true
        Object.values(filterSettings).forEach((value) => {
            if (value !== undefined) noFilter = false
        })
        return noFilter ? totalCount : filteredData?.length || 0
    }, [filterSettings, totalCount, filteredData])

    if (errorMessage) {
        return (
            <Error
                customMessage={errorMessage.message}
                customTitle={errorMessage.title}
                homepageRedirect={`/account/${organisationCode}`}
            ></Error>
        )
    }

    const columns = [
        { title: 'Name', field: 'patientName' },
        {
            title: 'User ID',
            field: 'patientIdentifierLogical',
            type: 'numeric',
        },
        {
            title: 'Priority',
            field: 'ragScore',
            lookup: ragScoresNames,
            FilterCell: <div></div>,
        },
        {
            title: 'Referred Date',
            field: 'referralCreatedAt',
            type: 'date',
            dateSetting: {
                locale: 'en-gb',
                format: 'YYYY-MM-DD',
            },
            FilterCell: filtersEnabled ? (
                <DateFilter
                    to={filterSettings.referralCreatedAt?.to || null}
                    from={filterSettings.referralCreatedAt?.from || null}
                    triggerFilter={(from, to) => {
                        setFilterSettings((prev) => ({
                            ...prev,
                            referralCreatedAt:
                                !to && !from
                                    ? undefined
                                    : {
                                          from,
                                          to,
                                      },
                        }))
                        setCurrentPage(0)
                    }}
                ></DateFilter>
            ) : null,
        },
        {
            title: 'Completed',
            field: 'assessmentCompletedOrCancelled',
            type: 'date',
            dateSetting: {
                locale: 'en-gb',
                format: 'YYYY-MM-DD',
            },
            FilterCell: filtersEnabled ? (
                <DateFilter
                    to={filterSettings.assessmentCompletedAt?.to || null}
                    from={filterSettings.assessmentCompletedAt?.from || null}
                    triggerFilter={(from, to) => {
                        setFilterSettings((prev) => ({
                            ...prev,
                            assessmentCompletedAt:
                                !to && !from
                                    ? undefined
                                    : {
                                          from,
                                          to,
                                      },
                        }))
                        setCurrentPage(0)
                    }}
                ></DateFilter>
            ) : null,
        },
        ...(filteredData?.[0]?.organisationConfig?.quicksilva
            ? [
                  {
                      title: 'Quicksilva',
                      field: 'quicksilvaStatus',
                  },
              ]
            : []),
        {
            title: 'Report',
            field: 'assessmentId',
            render: (rowData: any) => (
                <div
                    style={{
                        verticalAlign: 'middle',
                        textAlign: 'center',
                    }}
                >
                    {rowData.assessmentId && rowData.assessmentCompletedAt && (
                        <a rel="noreferrer" href={'/report/' + rowData.assessmentId} target="_blank">
                            <Button size="small" appearance="primary" label="View" type="button" />
                        </a>
                    )}
                </div>
            ),
        },
    ]

    return (
        <Page width="x-wide" header={<Header patientFacing={false}></Header>}>
            <Heading el="h1" size="heading1" color="mid" css={{ mt: 5, mb: 5 }}>
                Censeo Referrals
            </Heading>

            <div
                style={{
                    maxWidth: '100%',
                    paddingTop: '30px',
                    paddingBottom: '200px',
                }}
                className={Styles.table}
            >
                <Table
                    title={''}
                    isLoading={isLoading}
                    columns={columns}
                    data={filteredData}
                    options={{
                        search: filtersEnabled,
                        exportButton: true,
                        filtering: true,
                        pageSize: pageSize,
                        pageSizeOptions: [5, 10, 20, 50],
                        paginationType: 'stepped',
                    }}
                    totalCount={adjustedTotalCount}
                    page={currentPage}
                    onChangePage={(page: React.SetStateAction<number>) => setCurrentPage(page)}
                    onChangeRowsPerPage={(size: React.SetStateAction<number>) => {
                        setPageSize(size)
                        setCurrentPage(0)
                    }}
                />
            </div>
        </Page>
    )
}

export default PatientReferrals
