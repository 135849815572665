import React from 'react'
import { Page, Text, Button, Link } from '@psyomics/components'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from 'registration/components/Header'
import { classNameMaker } from 'ui/utils'
import Styles from './NotFound.module.scss'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

interface NotFoundProps {
    homepageButton?: boolean
}

const NotFound: React.FC<NotFoundProps> = ({ homepageButton = true }) => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })

    const location = useLocation()
    const navigate = useNavigate()
    const { demo, demoFrom } = useDemoContext()

    const accountPathMatch = location.pathname.match(/^\/account\/[^/]+/)

    return (
        <Page isInsideMobileFrame={demo && demoFrom === 'start'}>
            <Header template="landing" />
            <div className={containerClass}>
                <Text size="large" color="mid" role="heading">
                    <b>Page not found</b>
                </Text>
            </div>

            <Text css={{ mt: 4 }}>
                Sorry we can&#39;t find that page, please check that you have typed the URL correctly. If this problem
                persists, please get in touch with us at{' '}
                <Link href="mailto:support@censeo.co.uk">support@censeo.co.uk</Link>
            </Text>

            {homepageButton && (
                <div className={Styles.formActions}>
                    <Button
                        css={{ mt: 4 }}
                        appearance="primary"
                        fullWidth
                        size="large"
                        label="Homepage"
                        onClick={() => {
                            if (accountPathMatch) {
                                navigate(accountPathMatch[0])
                            } else {
                                navigate(demo ? '/demo' : '/')
                            }
                        }}
                    />
                </div>
            )}
        </Page>
    )
}

export default NotFound
