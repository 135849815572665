import React, { useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { Heading, Button, Input, Page, Text, List, ListItem, Spinner } from '@psyomics/components'
import { useAuth } from 'registration/context/auth'
import { useAsync } from 'registration/hooks/use-async'
import Header from 'registration/components/Header'
import Content from 'registration/components/Content'
import { CognitoUserWithAttributes, handleSignin } from 'registration/pages/SignIn/use-Signin'
import Card from 'registration/components/Card'
import Field from 'registration/components/Field'
import Label from 'registration/components/Label'
import Validation from 'registration/components/Validation'
import * as Sentry from '@sentry/react'
import Auth from '@aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

type ISignInProps = React.ComponentPropsWithoutRef<'div'> & { reportId?: string; showHostedUIButton?: boolean }

interface FormValues {
    email: string
    password: string
    newPassword: string
}

const PractitionerCognitoLogin: React.FC<ISignInProps> = ({ reportId, showHostedUIButton = true }) => {
    const { initializeUser, requireConfirmation } = useAuth()
    const { error, isLoading, isError, run } = useAsync()
    const navigate = useNavigate()
    const [newPasswordRequired, setNewPasswordRequired] = useState(false)
    const [cognitoUser, setCognitoUser] = useState<CognitoUserWithAttributes | null>(null)

    const handleNewPassword = async (
        newPassword: string,
        setFieldError: FormikHelpers<FormValues>['setFieldError']
    ) => {
        try {
            await Auth.completeNewPassword(cognitoUser, newPassword)
            history.go(0)
        } catch (error) {
            if (error instanceof Error) {
                setFieldError('newPassword', error.message)
            } else {
                console.error(error)
                Sentry.captureException(error, {
                    extra: { errorMessage: 'Failed to handle practitioner Auth.completeNewPassword' },
                })
                setFieldError('newPassword', 'An unknown error occurred. Please contact support.')
            }
        }
    }

    const newPasswordSchema = Yup.string()
        .required('Please enter a new password')
        .min(8, 'Your password must be at least 8 characters')

    const FormSchema = Yup.object().shape({
        email: Yup.string()
            .email("That doesn't look like a valid email address. Please try again.")
            .required('You must supply an email address'),
        password: Yup.string().required('You must enter a password'),
        newPassword: newPasswordRequired ? newPasswordSchema : Yup.string().optional(),
    })

    return (
        <Page>
            <Header template="landing" />
            <Content>
                <Heading el="h1" size="heading2" color="mid" css={{ mb: 4 }}>
                    Sign in to your account
                </Heading>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        newPassword: '',
                    }}
                    validationSchema={FormSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={async (values, { setFieldError }) => {
                        if (newPasswordRequired) {
                            await handleNewPassword(values.newPassword, setFieldError)
                        } else {
                            run(
                                handleSignin({ values, initializeUser, requestConfirmation: requireConfirmation }).then(
                                    async (user) => {
                                        if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                                            setCognitoUser(user)
                                            setNewPasswordRequired(true)
                                        }
                                    }
                                )
                            )
                                .then(() => {
                                    if (reportId) {
                                        navigate('/report/' + reportId)
                                    }
                                })
                                .catch((error) => {
                                    Sentry.captureException(error, {
                                        extra: { errorMessage: 'Failed to handle practitioner signin ' },
                                    })
                                })
                        }
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <Card>
                                <Field>
                                    <Label htmlFor="email">Email</Label>
                                    <Input
                                        type="email"
                                        id="email"
                                        appearance="outlined"
                                        placeholder="e.g. Jane@psyomics.com"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    {errors.email && touched.email && <Validation>{errors.email}</Validation>}
                                </Field>
                                <Field>
                                    <Label htmlFor="password">Password</Label>
                                    <Input
                                        type="password"
                                        id="password"
                                        appearance="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                    />
                                    {errors.password && touched.password && <Validation>{errors.password}</Validation>}
                                </Field>
                                {isError && error && <Validation>{error.message}</Validation>}
                                {newPasswordRequired && (
                                    <React.Suspense fallback={<p>loading...</p>}>
                                        <Text>Please enter your new password below.</Text>
                                        <Field>
                                            <Label htmlFor="newPassword">New Password</Label>
                                            <Input
                                                type="password"
                                                id="newPassword"
                                                appearance="outlined"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.newPassword}
                                            />
                                            {errors.newPassword && touched.newPassword && (
                                                <Validation>{errors.newPassword}</Validation>
                                            )}
                                        </Field>
                                        <Text color="light" size="small">
                                            Your password must contain:
                                            <List spacing={1} css={{ mt: 2 }}>
                                                <ListItem>At least 8 characters</ListItem>
                                                <ListItem>One uppercase character</ListItem>
                                                <ListItem>One lowercase character</ListItem>
                                                <ListItem>One numeric character</ListItem>
                                                <ListItem>One symbol character (* ! £ $ @)</ListItem>
                                            </List>
                                        </Text>
                                    </React.Suspense>
                                )}
                                <Button
                                    type="submit"
                                    appearance="primary"
                                    fullWidth
                                    size="large"
                                    label={newPasswordRequired ? 'Change Password' : 'Sign In'}
                                    icon={null}
                                    disabled={isLoading || isSubmitting}
                                />
                                {isSubmitting && <Spinner size="medium" />}

                                {showHostedUIButton && (
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            Auth.federatedSignIn()
                                        }}
                                        appearance="secondary"
                                        fullWidth
                                        size="large"
                                        label="Hosted UI page"
                                        icon={null}
                                    />
                                )}
                            </Card>
                        </form>
                    )}
                </Formik>
            </Content>
        </Page>
    )
}

export default PractitionerCognitoLogin
