import { Button, Heading, Page, Text } from '@psyomics/components'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from 'ui/app/components/Header'
import { TypeformEmbed, TypeformType } from 'ui/app/pages/CheckIn/TypeformEmbed'
import { useDemoContext } from './DemoContext'

const DemoEndScreen: React.FC = () => {
    const navigate = useNavigate()
    const [showFeedback, setShowFeedback] = useState<boolean>(false)
    const { demoFrom } = useDemoContext()

    return (
        <Page
            isInsideMobileFrame={demoFrom === 'start'}
            layout="standard"
            width="standard"
            header={<Header patientFacing={true} />}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <svg width="153" height="153" viewBox="0 0 153 153" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_413_342)">
                        <mask
                            id="mask0_413_342"
                            // style="mask-type:luminance"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="153"
                            height="153"
                        >
                            <path d="M0 0H153V153H0V0Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_413_342)">
                            <path
                                d="M50.8355 34.1754C50.3883 34.6405 50.0234 35.1783 49.7565 35.7658L49.7243 35.7336L4.56512 137.458L4.60941 137.503C3.77193 139.125 5.17309 142.427 8.04385 145.302C10.9106 148.168 14.2122 149.57 15.8348 148.732L15.875 148.772L117.608 103.617L117.576 103.581C118.172 103.299 118.711 102.957 119.166 102.502C125.455 96.2128 115.257 75.8196 96.3893 56.9522C77.522 38.0809 57.1246 27.8863 50.8355 34.1754Z"
                                fill="#DD2E44"
                            />
                            <path
                                d="M56.3677 52.3422L5.70054 134.906L4.56512 137.463L4.60941 137.503C3.77193 139.129 5.17309 142.427 8.04385 145.302C8.90716 146.155 9.86488 146.908 10.8985 147.544L72.473 72.4738L56.3677 52.3422Z"
                                fill="#EA596E"
                            />
                            <path
                                d="M96.6785 56.6342C115.481 75.4452 125.922 95.4962 119.987 101.427C114.056 107.358 94.005 96.9215 75.1901 78.1186C56.3831 59.3077 45.9469 39.2526 51.8777 33.3178C57.8125 27.3871 77.8635 37.8273 96.6785 56.6342Z"
                                fill="#A0041E"
                            />
                            <path
                                d="M78.8757 58.8244C78.4651 59.1574 77.9924 59.4055 77.485 59.5541C76.9776 59.7027 76.4458 59.7489 75.9204 59.69C72.4255 59.3115 69.4863 58.0956 67.4289 56.175C65.2506 54.1418 64.1756 51.4159 64.4695 48.678C64.9849 43.8787 69.8004 39.4739 78.01 40.3637C81.2029 40.7059 82.6282 39.6792 82.6725 39.188C82.7289 38.7008 81.5572 37.3923 78.3684 37.046C74.8695 36.6675 71.9343 35.4516 69.8729 33.531C67.6946 31.4978 66.6156 28.7679 66.9135 26.034C67.4369 21.2347 72.2484 16.8299 80.45 17.7197C82.7772 17.9693 84.0012 17.4862 84.5206 17.1762C84.9393 16.9225 85.1044 16.6809 85.1205 16.544C85.1648 16.0568 84.0092 14.7483 80.8083 14.402C79.7632 14.2676 78.8124 13.7288 78.16 12.9012C77.5076 12.0737 77.2057 11.0233 77.319 9.97569C77.4323 8.92804 77.9518 7.96655 78.766 7.29761C79.5802 6.62867 80.6243 6.30558 81.674 6.39771C89.8756 7.27947 93.6442 12.6063 93.1248 17.4097C92.6054 22.209 87.794 26.6098 79.5843 25.724C77.2571 25.4704 76.0371 25.9535 75.5177 26.2635C75.103 26.5172 74.9339 26.7628 74.9178 26.8957C74.8655 27.3869 76.0291 28.6954 79.23 29.0417C87.4316 29.9235 91.2002 35.2503 90.6809 40.0537C90.1615 44.853 85.346 49.2578 77.1444 48.368C74.8172 48.1184 73.5932 48.5975 73.0697 48.9075C72.655 49.1612 72.494 49.4068 72.4779 49.5437C72.4255 50.0309 73.5891 51.3394 76.786 51.6857C77.5795 51.7682 78.3305 52.0849 78.9433 52.5957C79.5561 53.1064 80.0031 53.788 80.2272 54.5536C80.4513 55.3192 80.4424 56.1342 80.2018 56.8948C79.9611 57.6553 79.4995 58.3271 78.8757 58.8244Z"
                                fill="#AA8DD8"
                            />
                            <path
                                d="M127.477 96.0558C135.421 93.8131 140.901 97.3563 142.206 102.007C143.51 106.653 140.684 112.535 132.748 114.766C129.647 115.636 128.717 117.117 128.842 117.593C128.979 118.068 130.553 118.849 133.646 117.971C141.585 115.74 147.065 119.284 148.37 123.93C149.686 128.58 146.852 134.455 138.908 136.693C135.812 137.563 134.878 139.049 135.015 139.52C135.147 139.991 136.718 140.768 139.814 139.902C140.328 139.744 140.868 139.689 141.403 139.743C141.938 139.796 142.457 139.956 142.929 140.213C143.401 140.47 143.817 140.819 144.152 141.24C144.487 141.661 144.735 142.144 144.88 142.662C145.025 143.179 145.065 143.721 144.998 144.254C144.93 144.788 144.757 145.302 144.487 145.767C144.218 146.233 143.858 146.639 143.428 146.963C142.999 147.287 142.509 147.521 141.988 147.653C134.056 149.888 128.568 146.353 127.256 141.698C125.951 137.052 128.782 131.177 136.73 128.939C139.826 128.065 140.76 126.587 140.623 126.112C140.49 125.641 138.924 124.856 135.828 125.726C127.884 127.964 122.404 124.429 121.096 119.775C119.787 115.124 122.618 109.25 130.565 107.011C133.654 106.146 134.588 104.656 134.455 104.189C134.318 103.71 132.756 102.929 129.656 103.802C129.14 103.968 128.597 104.028 128.058 103.979C127.518 103.93 126.995 103.773 126.518 103.517C126.041 103.261 125.62 102.911 125.282 102.489C124.943 102.067 124.693 101.581 124.546 101.06C124.4 100.538 124.36 99.993 124.429 99.4561C124.498 98.9191 124.674 98.4016 124.948 97.9345C125.222 97.4674 125.587 97.0603 126.021 96.7375C126.456 96.4147 126.951 96.1828 127.477 96.0558Z"
                                fill="#77B255"
                            />
                            <path
                                d="M96.6342 85.1968C95.8004 85.1956 94.9875 84.9359 94.3075 84.4535C93.6275 83.971 93.1139 83.2895 92.8374 82.5029C92.5609 81.7163 92.5352 80.8633 92.7638 80.0615C92.9924 79.2597 93.464 78.5485 94.1137 78.0259C94.9914 77.3213 115.928 60.8778 145.518 65.1095C146.041 65.184 146.544 65.361 146.999 65.6302C147.454 65.8995 147.852 66.2557 148.169 66.6787C148.486 67.1016 148.716 67.5829 148.847 68.095C148.978 68.6071 149.007 69.14 148.932 69.6632C148.86 70.1878 148.684 70.6929 148.415 71.1491C148.146 71.6054 147.789 72.0038 147.365 72.3213C146.942 72.6388 146.459 72.8691 145.946 72.9989C145.432 73.1287 144.898 73.1555 144.374 73.0776C118.231 69.3653 99.3318 84.166 99.1466 84.315C98.4343 84.8871 97.5477 85.1982 96.6342 85.1968Z"
                                fill="#AA8DD8"
                            />
                            <path
                                d="M27.1938 68.4473C26.5658 68.4475 25.9464 68.3008 25.3852 68.0189C24.8239 67.7371 24.3364 67.3278 23.9616 66.8239C23.5868 66.32 23.3351 65.7353 23.2266 65.1168C23.118 64.4982 23.1557 63.8628 23.3366 63.2614C27.8984 48.0701 32.0334 23.8276 26.9522 17.5103C26.3805 16.7937 25.5269 16.0891 23.562 16.238C19.7813 16.5239 20.1437 24.496 20.1477 24.5765C20.1989 25.1099 20.143 25.6482 19.9834 26.1597C19.8239 26.6713 19.5638 27.1458 19.2185 27.5556C18.8731 27.9653 18.4495 28.302 17.9723 28.5459C17.4952 28.7898 16.9742 28.936 16.4398 28.9759C15.9055 29.0158 15.3685 28.9487 14.8604 28.7784C14.3523 28.6082 13.8833 28.3382 13.4809 27.9843C13.0785 27.6304 12.7508 27.1998 12.517 26.7177C12.2832 26.2355 12.148 25.7115 12.1193 25.1765C11.7005 19.6242 13.4318 8.93026 22.9541 8.20956C27.2059 7.88745 30.7369 9.36511 33.2333 12.4654C42.7796 24.347 33.0883 58.7962 31.047 65.5805C30.7978 66.4089 30.2886 67.135 29.5946 67.6513C28.9006 68.1676 28.0588 68.4467 27.1938 68.4473Z"
                                fill="#77B255"
                            />
                            <path
                                d="M112.735 42.2764C112.735 43.0698 112.579 43.8554 112.275 44.5883C111.971 45.3212 111.526 45.987 110.964 46.5478C110.403 47.1086 109.737 47.5534 109.004 47.8568C108.271 48.1602 107.485 48.3162 106.692 48.3159C105.898 48.3157 105.113 48.1591 104.38 47.8553C103.647 47.5514 102.981 47.1062 102.42 46.545C101.86 45.9838 101.415 45.3176 101.111 44.5845C100.808 43.8515 100.652 43.0658 100.652 42.2724C100.653 40.6701 101.29 39.1337 102.423 38.001C103.557 36.8684 105.094 36.2324 106.696 36.2329C108.298 36.2335 109.835 36.8705 110.967 38.0039C112.1 39.1373 112.736 40.6741 112.735 42.2764Z"
                                fill="#5C913B"
                            />
                            <path
                                d="M20.1308 76.5001C20.1305 77.5579 19.9219 78.6052 19.5169 79.5823C19.1119 80.5595 18.5183 81.4473 17.7702 82.195C17.0221 82.9428 16.134 83.5358 15.1566 83.9404C14.1793 84.3449 13.1319 84.553 12.0741 84.5527C11.0164 84.5525 9.96902 84.3439 8.99189 83.9388C8.01476 83.5338 7.12697 82.9403 6.37921 82.1922C5.63146 81.444 5.03838 80.5559 4.63384 79.5786C4.2293 78.6013 4.02122 77.5538 4.02148 76.4961C4.02202 74.3599 4.87114 72.3113 6.38206 70.8012C7.89297 69.291 9.94192 68.4429 12.0781 68.4434C14.2144 68.444 16.2629 69.2931 17.773 70.804C19.2832 72.3149 20.1313 74.3639 20.1308 76.5001Z"
                                fill="#9266CC"
                            />
                            <path
                                d="M140.921 82.5394C140.921 83.3328 140.764 84.1183 140.46 84.8512C140.156 85.5841 139.711 86.25 139.15 86.8108C138.589 87.3716 137.923 87.8164 137.189 88.1197C136.456 88.4231 135.671 88.5791 134.877 88.5789C134.084 88.5786 133.298 88.4221 132.566 88.1182C131.833 87.8143 131.167 87.3691 130.606 86.8079C130.045 86.2467 129.6 85.5806 129.297 84.8475C128.994 84.1144 128.838 83.3287 128.838 82.5354C128.838 80.9331 129.475 79.3966 130.609 78.264C131.742 77.1314 133.279 76.4954 134.881 76.4959C136.484 76.4964 138.02 77.1334 139.153 78.2668C140.285 79.4002 140.921 80.9371 140.921 82.5394Z"
                                fill="#5C913B"
                            />
                            <path
                                d="M104.683 130.855C104.682 131.649 104.526 132.434 104.222 133.167C103.918 133.9 103.473 134.566 102.912 135.127C102.35 135.688 101.684 136.132 100.951 136.436C100.218 136.739 99.4325 136.895 98.6391 136.895C97.8457 136.895 97.0601 136.738 96.3273 136.434C95.5944 136.13 94.9285 135.685 94.3677 135.124C93.8069 134.563 93.3621 133.896 93.0587 133.163C92.7554 132.43 92.5993 131.645 92.5996 130.851C92.6001 129.249 93.2372 127.713 94.3705 126.58C95.5039 125.447 97.0408 124.811 98.6431 124.812C100.245 124.812 101.782 125.449 102.914 126.583C104.047 127.716 104.683 129.253 104.683 130.855Z"
                                fill="#5C913B"
                            />
                            <path
                                d="M124.814 20.1317C124.814 21.1894 124.606 22.2368 124.2 23.2139C123.795 24.1911 123.202 25.0788 122.454 25.8266C121.706 26.5744 120.818 27.1674 119.84 27.572C118.863 27.9765 117.815 28.1846 116.758 28.1843C115.7 28.1841 114.653 27.9755 113.675 27.5704C112.698 27.1654 111.811 26.5719 111.063 25.8238C110.315 25.0756 109.722 24.1875 109.317 23.2102C108.913 22.2329 108.705 21.1854 108.705 20.1277C108.706 17.9914 109.555 15.9429 111.066 14.4328C112.577 12.9226 114.626 12.0745 116.762 12.075C118.898 12.0756 120.946 12.9247 122.457 14.4356C123.967 15.9465 124.815 17.9955 124.814 20.1317Z"
                                fill="#FFCC4D"
                            />
                            <path
                                d="M140.921 38.2501C140.921 39.0435 140.764 39.829 140.46 40.5619C140.156 41.2948 139.711 41.9606 139.15 42.5215C138.589 43.0823 137.923 43.5271 137.189 43.8304C136.456 44.1338 135.671 44.2898 134.877 44.2896C134.084 44.2893 133.298 44.1328 132.566 43.8289C131.833 43.525 131.167 43.0798 130.606 42.5186C130.045 41.9574 129.6 41.2913 129.297 40.5582C128.994 39.8251 128.838 39.0394 128.838 38.2461C128.838 36.6437 129.475 35.1073 130.609 33.9747C131.742 32.842 133.279 32.206 134.881 32.2066C136.484 32.2071 138.02 32.8441 139.153 33.9775C140.285 35.1109 140.921 36.6478 140.921 38.2501Z"
                                fill="#FFCC4D"
                            />
                            <path
                                d="M128.841 54.3553C128.841 55.1487 128.684 55.9342 128.38 56.6671C128.076 57.4 127.631 58.0659 127.07 58.6267C126.509 59.1875 125.843 59.6323 125.109 59.9357C124.376 60.239 123.591 60.395 122.797 60.3948C122.004 60.3945 121.218 60.238 120.485 59.9341C119.753 59.6303 119.087 59.185 118.526 58.6238C117.965 58.0627 117.52 57.3965 117.217 56.6634C116.914 55.9303 116.758 55.1447 116.758 54.3513C116.758 52.749 117.395 51.2125 118.529 50.0799C119.662 48.9473 121.199 48.3113 122.801 48.3118C124.404 48.3123 125.94 48.9494 127.073 50.0827C128.205 51.2161 128.841 52.753 128.841 54.3553Z"
                                fill="#FFCC4D"
                            />
                            <path
                                d="M40.2627 98.6449C40.2624 99.4382 40.1059 100.224 39.802 100.957C39.4982 101.69 39.0529 102.355 38.4917 102.916C37.9305 103.477 37.2644 103.922 36.5313 104.225C35.7982 104.529 35.0125 104.685 34.2192 104.684C33.4258 104.684 32.6402 104.528 31.9073 104.224C31.1745 103.92 30.5086 103.475 29.9478 102.913C29.387 102.352 28.9422 101.686 28.6388 100.953C28.3354 100.22 28.1794 99.4342 28.1797 98.6408C28.1802 97.0385 28.8172 95.5021 29.9506 94.3694C31.084 93.2368 32.6209 92.6008 34.2232 92.6014C35.8255 92.6019 37.362 93.2389 38.4946 94.3723C39.6272 95.5057 40.2632 97.0426 40.2627 98.6449Z"
                                fill="#FFCC4D"
                            />
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_413_342">
                            <rect width="153" height="153" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <Heading el="h2" size="heading2" color="mid" css={{ mt: 6 }}>
                    Thank you for trying Censeo!
                </Heading>
                <Text css={{ mt: 3, mb: 4 }}>Let us know what you think</Text>
                {!showFeedback && (
                    <div>
                        <Button
                            className="text-right"
                            appearance="primary"
                            size="large"
                            label="Give Feedback"
                            onClick={() => {
                                setShowFeedback(true)
                            }}
                        />
                    </div>
                )}

                {showFeedback && <TypeformEmbed type={TypeformType.DEMO_FEEDBACK} contract={'demo'}></TypeformEmbed>}

                <div>
                    <Button
                        css={{ mt: 4 }}
                        className="text-right"
                        appearance="secondary"
                        size="large"
                        label="Start Again"
                        onClick={() => {
                            navigate(`/demo/service-user/checkIn${demoFrom ? `?${demoFrom}` : ''}`)
                        }}
                    />
                </div>
            </div>
        </Page>
    )
}

export default DemoEndScreen
