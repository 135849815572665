import React from 'react'

const OrgSussexLogo: React.FC = () => (
    <svg viewBox="0 0 194 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.72928 52.3833C3.82326 52.9665 5.02664 53.331 6.19356 53.331C7.32401 53.331 8.89206 52.7478 8.89206 51.3626C8.89206 50.1961 7.87101 49.6857 6.33943 49.1025C4.33379 48.3369 2.21875 47.4621 2.21875 44.6187C2.21875 41.3379 4.69845 39.9527 7.57928 39.9527C8.85559 39.9527 10.0954 40.135 11.2259 40.4995L10.9342 43.0148C9.91311 42.5773 8.85559 42.3586 7.83454 42.3586C6.70409 42.3586 5.42777 42.869 5.42777 44.4729C5.42777 45.6394 6.74055 46.0769 8.01687 46.5872C10.059 47.3527 12.1375 48.3005 12.1375 51.1438C12.1375 54.0966 10.0225 55.7734 6.37589 55.7734C4.80785 55.7734 3.93266 55.5547 2.47401 55.1901L2.72928 52.3833Z"
            fill="#005EB8"
        />
        <path
            d="M22.1657 55.4818V53.9507H22.1292C21.1811 55.1172 19.9048 55.7369 18.4461 55.7369C15.93 55.7369 14.6172 54.0601 14.6172 51.3261V44.2177H17.5345V50.6335C17.5345 52.8936 18.3732 53.4404 19.4307 53.4404C21.2176 53.4404 22.0198 51.8 22.0198 49.6493V44.2177H24.9371V55.4818H22.1657Z"
            fill="#005EB8"
        />
        <path
            d="M27.5262 52.8571C28.4379 53.331 29.4225 53.5862 30.4071 53.5862C31.2093 53.5862 32.3398 53.2946 32.3398 52.201C32.3398 51.2532 31.4281 50.9616 30.2247 50.6335C28.2556 50.1232 27.2345 49.2847 27.2345 47.4256C27.2345 45.1655 29.1307 43.9261 31.6834 43.9261C32.595 43.9261 33.4702 43.999 34.8559 44.3271L34.6736 46.5507C33.6525 46.1862 32.9962 46.0768 32.0845 46.0768C31.0634 46.0768 30.3341 46.4778 30.3341 47.3163C30.3341 47.936 30.954 48.264 32.2668 48.6286C34.3819 49.2118 35.4029 50.1596 35.4029 52.0552C35.4029 54.534 33.2879 55.7369 30.6623 55.7369C29.6048 55.7369 28.4379 55.5547 27.3439 55.2631L27.5262 52.8571Z"
            fill="#005EB8"
        />
        <path
            d="M37.2627 52.8571C38.1743 53.331 39.1589 53.5862 40.1435 53.5862C40.9458 53.5862 42.0762 53.2946 42.0762 52.201C42.0762 51.2532 41.1646 50.9616 39.9612 50.6335C37.992 50.1232 36.9709 49.2847 36.9709 47.4256C36.9709 45.1655 38.8672 43.9261 41.4198 43.9261C42.3315 43.9261 43.2067 43.999 44.5924 44.3271L44.41 46.5507C43.389 46.1862 42.7326 46.0768 41.8209 46.0768C40.7999 46.0768 40.0706 46.4778 40.0706 47.3163C40.0706 47.936 40.6905 48.264 42.0033 48.6286C44.1183 49.2118 45.1394 50.1596 45.1394 52.0552C45.1394 54.534 43.0243 55.7369 40.3988 55.7369C39.3412 55.7369 38.1743 55.5547 37.0803 55.2631L37.2627 52.8571Z"
            fill="#005EB8"
        />
        <path
            d="M56.444 54.8256C55.3864 55.4089 54.1466 55.7369 52.5785 55.7369C48.8225 55.7369 46.671 53.5862 46.671 49.9044C46.671 46.7695 48.312 43.9626 51.8857 43.9626C55.7146 43.9626 57.2462 46.332 57.2462 50.7793H49.4424C49.5883 52.5655 50.7917 53.6227 52.6879 53.6227C54.0736 53.6227 55.3135 53.1123 56.4075 52.4926V54.8256H56.444ZM54.4748 48.7379C54.3654 47.134 53.5996 46.0768 52.068 46.0768C50.6094 46.0768 49.6248 47.0246 49.4789 48.7379H54.4748Z"
            fill="#005EB8"
        />
        <path
            d="M66.545 55.4818L64.0289 51.2897L61.4762 55.4818H58.2672L62.1326 49.5764L58.6683 44.2177H62.1326L64.3571 47.8995L66.5086 44.2177H69.6082L66.2533 49.5764L70.0458 55.4818H66.545Z"
            fill="#005EB8"
        />
        <path
            d="M77.8131 55.4818V40.2079H81.7515C85.7992 40.2079 88.0601 41.7025 88.0601 44.8374C88.0601 48.0453 85.9086 49.6857 82.2255 49.6857H80.8763V55.4818H77.8131ZM81.8973 47.2798C83.9759 47.2798 84.8511 46.4414 84.8511 45.0197C84.8511 43.4158 83.7571 42.6502 81.9338 42.6502H80.8763V47.3163H81.8973V47.2798Z"
            fill="#005EB8"
        />
        <path
            d="M96.8484 55.4818C96.7755 55.0808 96.739 54.3153 96.7025 53.8049H96.6661C96.0097 55.0079 94.5875 55.7369 93.2018 55.7369C91.1597 55.7369 89.2634 54.4975 89.2634 52.3833C89.2634 50.4512 90.3939 48.5192 94.7698 48.5192H96.5931V48.4828C96.5931 46.5872 95.645 46.1133 94.0405 46.1133C92.8006 46.1133 91.5608 46.5872 90.5762 47.3527L90.5033 44.8374C91.5608 44.3635 93.0559 43.999 94.4052 43.999C97.76 43.999 99.2552 45.3478 99.2552 48.7379V51.0709C99.2552 51.6542 99.2916 54.0236 99.4375 55.5182H96.8484V55.4818ZM96.5931 50.269H95.3168C93.2382 50.269 92.0713 50.8887 92.0713 52.201C92.0713 53.0759 92.7642 53.5862 93.9311 53.5862C95.2439 53.5862 96.5931 52.6749 96.5931 50.3783V50.269Z"
            fill="#005EB8"
        />
        <path
            d="M102.209 55.4818V44.2177H104.798V46.7695H104.835C105.017 45.603 106.22 43.9626 107.934 43.9626C108.554 43.9626 108.736 44.0355 108.809 44.0355V46.9882C108.554 46.8424 108.007 46.733 107.497 46.733C106.403 46.733 105.126 47.3163 105.126 50.8158V55.4453H102.209V55.4818Z"
            fill="#005EB8"
        />
        <path
            d="M117.342 55.4818C116.722 55.664 116.066 55.7369 115.191 55.7369C112.821 55.7369 111.617 54.2424 111.617 52.0916V46.3685H109.466V44.2177H111.617V41.9576L114.534 41.0099V44.2177H117.124V46.3685H114.534V51.5813C114.534 52.7478 114.899 53.4769 115.92 53.4769C116.431 53.4769 116.941 53.3675 117.233 53.1852L117.342 55.4818Z"
            fill="#005EB8"
        />
        <path
            d="M126.605 55.4818V49.1754C126.605 47.4985 126.24 46.2591 124.709 46.2591C122.849 46.2591 122.119 48.0453 122.119 50.0502V55.5182H119.202V44.2542H121.974V45.7852H122.01C122.922 44.5094 124.162 43.999 125.693 43.999C128.282 43.999 129.522 45.7852 129.522 48.4099V55.5182H126.605V55.4818Z"
            fill="#005EB8"
        />
        <path
            d="M141.592 54.8256C140.535 55.4089 139.295 55.7369 137.727 55.7369C133.971 55.7369 131.819 53.5862 131.819 49.9044C131.819 46.7695 133.46 43.9626 137.034 43.9626C140.863 43.9626 142.395 46.332 142.395 50.7793H134.591C134.737 52.5655 135.94 53.6227 137.836 53.6227C139.222 53.6227 140.462 53.1123 141.556 52.4926V54.8256H141.592ZM139.623 48.7379C139.514 47.134 138.748 46.0768 137.216 46.0768C135.758 46.0768 134.773 47.0246 134.627 48.7379H139.623Z"
            fill="#005EB8"
        />
        <path
            d="M144.765 55.4818V44.2177H147.354V46.7695H147.391C147.573 45.603 148.776 43.9626 150.49 43.9626C151.11 43.9626 151.292 44.0355 151.365 44.0355V46.9882C151.11 46.8424 150.563 46.733 150.053 46.733C148.959 46.733 147.682 47.3163 147.682 50.8158V55.4453H144.765V55.4818Z"
            fill="#005EB8"
        />
        <path
            d="M152.788 52.8571C153.699 53.331 154.684 53.5862 155.668 53.5862C156.471 53.5862 157.601 53.2946 157.601 52.201C157.601 51.2532 156.689 50.9616 155.486 50.6335C153.517 50.1232 152.496 49.2847 152.496 47.4256C152.496 45.1655 154.392 43.9261 156.945 43.9261C157.856 43.9261 158.732 43.999 160.117 44.3271L159.935 46.5507C158.914 46.1862 158.257 46.0768 157.346 46.0768C156.325 46.0768 155.595 46.4778 155.595 47.3163C155.595 47.936 156.215 48.264 157.528 48.6286C159.643 49.2118 160.664 50.1596 160.664 52.0552C160.664 54.534 158.549 55.7369 155.924 55.7369C154.866 55.7369 153.699 55.5547 152.605 55.2631L152.788 52.8571Z"
            fill="#005EB8"
        />
        <path
            d="M170.364 55.4818V49.1025C170.364 47.0611 169.744 46.2227 168.468 46.2227C166.754 46.2227 165.879 47.7901 165.879 50.0138V55.4453H162.962V39.0414H165.879V45.7123H165.915C166.645 44.6552 167.921 43.9261 169.453 43.9261C172.042 43.9261 173.282 45.7488 173.282 48.337V55.4818H170.364Z"
            fill="#005EB8"
        />
        <path
            d="M176.381 42.1399V39.3695H179.298V42.1764H176.381V42.1399ZM176.381 55.4818V44.2177H179.298V55.4818H176.381Z"
            fill="#005EB8"
        />
        <path
            d="M182 60.0749V44.2177H184.808V45.8217H184.844C185.574 44.5823 186.777 43.9626 188.345 43.9626C191.627 43.9626 193.086 46.4778 193.086 49.8315C193.086 53.1488 191.663 55.7734 188.309 55.7734C186.813 55.7734 185.902 55.2995 184.99 54.2059H184.954V60.0749H182ZM189.986 49.7951C189.986 48.2276 189.439 46.2227 187.543 46.2227C185.646 46.2227 184.917 48.264 184.917 49.7951C184.917 51.4719 185.61 53.4404 187.506 53.4404C189.403 53.4404 189.986 51.399 189.986 49.7951Z"
            fill="#005EB8"
        />
        <path
            d="M50.9075 73.7084L47.0056 66.0897H46.9692V73.7084H45V63.5379H47.5162L51.418 71.1202V63.5015H53.3872V73.6719H50.9075V73.7084Z"
            fill="#005EB8"
        />
        <path
            d="M61.7744 73.7084V69.2611H57.6902V73.7084H55.6481V63.5379H57.6902V67.6571H61.7744V63.5379H63.8165V73.7084H61.7744Z"
            fill="#005EB8"
        />
        <path
            d="M66.1868 71.6305C66.9162 72.0315 67.7184 72.2502 68.4842 72.2502C69.2135 72.2502 70.3075 71.8857 70.3075 70.9379C70.3075 70.1724 69.6147 69.8443 68.6301 69.4434C67.2808 68.933 65.8586 68.3498 65.8586 66.4542C65.8586 64.267 67.4996 63.3557 69.4323 63.3557C70.3075 63.3557 71.1098 63.465 71.8391 63.7202L71.6568 65.397C70.9639 65.1054 70.2711 64.9596 69.5782 64.9596C68.8124 64.9596 67.9737 65.3241 67.9737 66.3813C67.9737 67.1468 68.8489 67.4384 69.6876 67.7665C71.0733 68.2768 72.4226 68.8966 72.4226 70.7921C72.4226 72.7606 71.0368 73.8542 68.5936 73.8542C67.5361 73.8542 66.9891 73.7084 66.0045 73.4532L66.1868 71.6305Z"
            fill="#005EB8"
        />
        <path
            d="M78.3301 73.7084V63.5379H83.9823V65.1419H80.3722V67.6571H83.8V69.2611H80.3722V73.7084H78.3301Z"
            fill="#005EB8"
        />
        <path
            d="M88.9053 73.8906C86.4985 73.8906 84.9669 72.2138 84.9669 70.0266C84.9669 67.6207 86.6079 66.0532 88.9053 66.0532C91.2026 66.0532 92.8436 67.6207 92.8436 70.0266C92.8436 72.2138 91.312 73.8906 88.9053 73.8906ZM90.8015 69.7714C90.8015 68.5685 90.1451 67.5478 88.9053 67.5478C87.6654 67.5478 87.009 68.6049 87.009 69.7714C87.009 71.3754 87.6289 72.3596 88.9053 72.3596C90.1816 72.3596 90.8015 71.3754 90.8015 69.7714Z"
            fill="#005EB8"
        />
        <path
            d="M99.4075 73.7084V72.6877H99.3711C98.7147 73.4532 97.876 73.8906 96.8914 73.8906C95.2139 73.8906 94.3387 72.7606 94.3387 70.9379V66.199H96.3079V70.5005C96.3079 71.9951 96.8549 72.3961 97.5842 72.3961C98.7512 72.3961 99.2981 71.3025 99.2981 69.8443V66.199H101.267V73.7084H99.4075Z"
            fill="#005EB8"
        />
        <path
            d="M108.232 73.7084V69.4798C108.232 68.3498 108.014 67.5113 106.956 67.5113C105.716 67.5113 105.242 68.7143 105.242 70.0266V73.6719H103.273V66.1626H105.133V67.1833H105.169C105.753 66.3448 106.591 65.9803 107.649 65.9803C109.363 65.9803 110.202 67.1468 110.202 68.933V73.6719H108.232V73.7084Z"
            fill="#005EB8"
        />
        <path
            d="M117.203 73.7084V72.8699H117.166C116.583 73.599 115.781 73.8906 114.869 73.8906C112.572 73.8906 111.733 72.0315 111.733 69.9537C111.733 67.6936 112.718 66.0532 114.869 66.0532C115.854 66.0532 116.547 66.3813 117.13 67.1103H117.166V62.8089H119.136V73.7448H117.203V73.7084ZM117.13 69.9172C117.13 68.8601 116.619 67.5113 115.38 67.5113C114.213 67.5113 113.775 68.7507 113.775 69.9172C113.775 70.9744 114.14 72.3596 115.416 72.3596C116.692 72.3596 117.13 71.0108 117.13 69.9172Z"
            fill="#005EB8"
        />
        <path
            d="M125.7 73.7084C125.627 73.4168 125.627 72.9064 125.627 72.5783H125.59C125.153 73.3803 124.205 73.8906 123.256 73.8906C121.907 73.8906 120.631 73.0887 120.631 71.6305C120.631 70.3182 121.36 69.0788 124.314 69.0788H125.517V69.0424C125.517 67.803 124.861 67.4384 123.84 67.4384C123.001 67.4384 122.162 67.7665 121.506 68.2404L121.433 66.5636C122.126 66.2355 123.147 65.9803 124.059 65.9803C126.32 65.9803 127.304 66.8916 127.304 69.1517V70.6828C127.304 71.0837 127.341 72.6512 127.414 73.6719H125.7V73.7084ZM125.517 70.2453H124.642C123.256 70.2453 122.491 70.6463 122.491 71.5576C122.491 72.1409 122.928 72.469 123.73 72.469C124.606 72.469 125.517 71.8857 125.517 70.3547V70.2453Z"
            fill="#005EB8"
        />
        <path
            d="M133.686 73.7084C133.285 73.8177 132.81 73.8906 132.263 73.8906C130.659 73.8906 129.857 72.9064 129.857 71.4483V67.6207H128.398V66.199H129.857V64.7044L131.826 64.0847V66.199H133.576V67.6207H131.826V71.1202C131.826 71.9222 132.081 72.3596 132.774 72.3596C133.102 72.3596 133.43 72.2867 133.649 72.1409L133.686 73.7084Z"
            fill="#005EB8"
        />
        <path
            d="M134.926 64.8138V62.9547H136.895V64.8138H134.926ZM134.926 73.7084V66.199H136.895V73.7084H134.926Z"
            fill="#005EB8"
        />
        <path
            d="M142.401 73.8906C139.994 73.8906 138.463 72.2138 138.463 70.0266C138.463 67.6207 140.104 66.0532 142.401 66.0532C144.698 66.0532 146.339 67.6207 146.339 70.0266C146.339 72.2138 144.808 73.8906 142.401 73.8906ZM144.297 69.7714C144.297 68.5685 143.641 67.5478 142.401 67.5478C141.161 67.5478 140.505 68.6049 140.505 69.7714C140.505 71.3754 141.125 72.3596 142.401 72.3596C143.677 72.3596 144.297 71.3754 144.297 69.7714Z"
            fill="#005EB8"
        />
        <path
            d="M152.794 73.7084V69.4798C152.794 68.3498 152.575 67.5113 151.518 67.5113C150.278 67.5113 149.804 68.7143 149.804 70.0266V73.6719H147.835V66.1626H149.694V67.1833H149.731C150.314 66.3448 151.153 65.9803 152.211 65.9803C153.924 65.9803 154.763 67.1468 154.763 68.933V73.6719H152.794V73.7084Z"
            fill="#005EB8"
        />
        <path d="M162.859 73.7084V65.1419H159.941V63.5379H167.818V65.1419H164.901V73.7084H162.859Z" fill="#005EB8" />
        <path
            d="M167.636 73.7084V66.199H169.386V67.9123H169.423C169.532 67.1103 170.371 66.0168 171.501 66.0168C171.902 66.0168 172.012 66.0532 172.085 66.0897V68.0581C171.902 67.9488 171.574 67.9123 171.209 67.9123C170.48 67.9123 169.605 68.3133 169.605 70.6463V73.7448H167.636V73.7084Z"
            fill="#005EB8"
        />
        <path
            d="M178.32 73.7084V72.6877H178.284C177.627 73.4532 176.789 73.8906 175.804 73.8906C174.127 73.8906 173.251 72.7606 173.251 70.9379V66.199H175.221V70.5005C175.221 71.9951 175.768 72.3961 176.497 72.3961C177.664 72.3961 178.211 71.3025 178.211 69.8443V66.199H180.18V73.7084H178.32Z"
            fill="#005EB8"
        />
        <path
            d="M181.894 71.9586C182.514 72.2503 183.134 72.469 183.827 72.469C184.337 72.469 185.103 72.2503 185.103 71.5576C185.103 70.9379 184.483 70.7557 183.717 70.537C182.405 70.1724 181.712 69.6621 181.712 68.3862C181.712 66.8552 182.988 66.0532 184.665 66.0532C185.249 66.0532 185.832 66.1261 186.78 66.3448L186.635 67.803C185.978 67.5842 185.504 67.4749 184.884 67.4749C184.191 67.4749 183.717 67.7665 183.717 68.3133C183.717 68.7507 184.118 68.933 185.03 69.1882C186.416 69.5892 187.145 70.2089 187.145 71.4483C187.145 73.0887 185.723 73.8906 183.973 73.8906C183.243 73.8906 182.477 73.7448 181.785 73.5626L181.894 71.9586Z"
            fill="#005EB8"
        />
        <path
            d="M193.126 73.7084C192.724 73.8177 192.25 73.8906 191.703 73.8906C190.099 73.8906 189.297 72.9064 189.297 71.4483V67.6207H187.838V66.199H189.297V64.7044L191.266 64.0847V66.199H193.016V67.6207H191.266V71.1202C191.266 71.9222 191.521 72.3596 192.214 72.3596C192.542 72.3596 192.87 72.2867 193.089 72.1409L193.126 73.7084Z"
            fill="#005EB8"
        />
        <path
            d="M193 31V0H117V31H193ZM147.557 2.99057L142.31 27.9445H134.075L128.898 10.6764H128.828L125.385 27.9445H119.141L124.43 2.99057H132.69L137.765 20.2943H137.835L141.31 2.99057H147.557ZM171.107 2.99057L165.962 27.9445H159.334L161.533 17.2583H153.692L151.493 27.9445H144.861L150.006 2.99057H156.635L154.686 12.537H162.523L164.475 2.99057H171.107ZM190.154 3.67246L188.554 8.64052C187.278 8.03331 185.541 7.50079 183.095 7.50079C180.473 7.50079 178.342 7.89044 178.342 9.89714C178.342 13.4332 187.955 12.1149 187.955 19.6936C187.955 26.5904 181.605 28.3796 175.858 28.3796C173.303 28.3796 170.36 27.7691 168.197 27.0873L169.758 22.012C171.065 22.8725 173.694 23.444 175.858 23.444C177.919 23.444 181.14 23.0511 181.14 20.4762C181.14 16.4693 171.527 17.9727 171.527 10.933C171.527 4.49398 177.133 2.5652 182.56 2.5652C185.615 2.5652 188.487 2.88667 190.154 3.67246Z"
            fill="#005EB8"
        />
    </svg>
)

export default OrgSussexLogo
