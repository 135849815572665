import React from 'react'
import { ReferralDetails } from 'ui/app/context/referralDetails'
import { Text, Link } from '@psyomics/components'

export const DemoUserCopy: React.FC = () => (
    <>
        <Text size="medium" css={{ mb: 5 }}>
            You have been asked to complete this by a healthcare professional. They want to make sure you get the best
            care available, and to do that please answer the questions as accurately as you can. If you get stuck at any
            point, you can contact support in the bottom left of your screen.
        </Text>
        <Text size="medium" css={{ mb: 5 }}>
            The answers to the questions you are about to be asked will be analysed and a report will be sent back to
            the health care professional who referred you to us, and their team. You will hear from them after they
            receive the report.
        </Text>
    </>
)

export const SussexCopy: React.FC<{ referralDetails: ReferralDetails }> = ({ referralDetails }) => (
    <>
        <Text size="medium" css={{ mb: 5 }}>
            To help with your appointment at{' '}
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                {referralDetails?.data?.organisationName}
                {referralDetails?.data?.organisationNameAbbr && ` (${referralDetails?.data?.organisationNameAbbr})`}
            </span>
            , please fill out the pre-assessment questionnaire.{' '}
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                This helps the team understand your needs and make the best decisions for your care.
            </span>
        </Text>
        <Text size="medium" css={{ mb: 5 }}>
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>Your progress is automatically saved.</span>{' '}
            Filling out this questionnaire is your choice, but it helps us understand your needs better.
        </Text>
        <Text size="medium" css={{ mb: 5 }}>
            See{' '}
            <Link
                rel="noreferrer"
                href="https://www.sussexpartnership.nhs.uk/our-services/using-our-services/censeo-patient-questionnaire"
            >
                here
            </Link>{' '}
            for more information on how Censeo and {referralDetails?.data?.organisationNameAbbr} are working together.
        </Text>
    </>
)

export const HertsCopy: React.FC<{ referralDetails: ReferralDetails }> = ({ referralDetails }) => (
    <>
        <Text size="medium" css={{ mb: 5 }}>
            To help with your appointment at{' '}
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                {referralDetails?.data?.organisationName}
                {referralDetails?.data?.organisationNameAbbr && ` (${referralDetails?.data?.organisationNameAbbr})`}
            </span>
            , please fill out the pre-assessment questionnaire.{' '}
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                This helps the team understand your needs and make the best decisions for your care.
            </span>
        </Text>
        <Text size="medium" css={{ mb: 5 }}>
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>Your progress is automatically saved.</span>{' '}
            Filling out this questionnaire is your choice, but it helps us understand your needs better.
        </Text>
        <Text size="medium" css={{ mb: 5 }}>
            See{' '}
            <Link rel="noreferrer" target="_blank" href="https://www.hpft.nhs.uk/service-users/online-self-assessment">
                here
            </Link>{' '}
            for more information on how Censeo and {referralDetails?.data?.organisationNameAbbr} are working together.
        </Text>
    </>
)

export const MidlandsCopy: React.FC<{ referralDetails: ReferralDetails }> = ({ referralDetails }) => (
    <>
        <Text size="medium" css={{ mb: 5 }}>
            To help with your appointment at{' '}
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                {referralDetails?.data?.organisationName}
                {referralDetails?.data?.organisationNameAbbr && ` (${referralDetails?.data?.organisationNameAbbr})`}
            </span>
            , please fill out the pre-assessment questionnaire.{' '}
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                This helps the team understand your needs and make the best decisions for your care.
            </span>
        </Text>
        <Text size="medium" css={{ mb: 5 }}>
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>Your progress is automatically saved.</span>{' '}
            Filling out this questionnaire is your choice, but it helps us understand your needs better.
        </Text>
        <Text size="medium" css={{ mb: 5 }}>
            For any further support
            {referralDetails?.data?.organisationNameAbbr ? ` from ${referralDetails.data.organisationNameAbbr}` : ''},
            please call 0808 196 3002.
        </Text>
    </>
)

export const PrioryCopy: React.FC<{ referralDetails: ReferralDetails }> = ({ referralDetails }) => (
    <>
        <Text size="medium" css={{ mb: 5 }}>
            To help with your appointment at{' '}
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                {referralDetails?.data?.organisationName}
                {referralDetails?.data?.organisationNameAbbr && ` (${referralDetails?.data?.organisationNameAbbr})`}
            </span>
            , please fill out the pre-assessment questionnaire.{' '}
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                This helps the team understand your needs and make the best decisions for your care.
            </span>
        </Text>
        <Text size="medium" css={{ mb: 5 }}>
            <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>Your progress is automatically saved.</span>{' '}
            Filling out this questionnaire is your choice, but it helps us understand your needs better.
        </Text>
        <Text size="medium" css={{ mb: 5 }}>
            If you feel that you need to talk to someone, please visit the following link:{' '}
            <Link rel="noreferrer" target="_blank" href="https://www.priorygroup.com/crisis-support">
                https://www.priorygroup.com/crisis-support
            </Link>
        </Text>
    </>
)

export const DefaultOrgCopy: React.FC<{ referralDetails: ReferralDetails }> = ({ referralDetails }) => (
    <Text size="medium" css={{ mb: 5 }}>
        In order to support your referral to{' '}
        <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
            {referralDetails?.data?.organisationName}
            {referralDetails?.data?.organisationNameAbbr && ` (${referralDetails?.data?.organisationNameAbbr})`}
        </span>
        , you have been asked to complete a Censeo assessment.
    </Text>
)

export const DefaultContent: React.FC = () => (
    <Text size="medium" css={{ mb: 5 }}>
        You have been asked to complete a Censeo assessment by your local psychological therapy service to help make
        sure you receive the best support for your needs.
    </Text>
)
