import { Button, Heading, Input, Page } from '@psyomics/components'
import * as Sentry from '@sentry/react'
import { InfoContext } from 'configure'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Field from 'registration/components/Field'
import Header from 'registration/components/Header'
import Label from 'registration/components/Label'
import Validation from 'registration/components/Validation'
import QuickSilvaError from './QuickSilvaError/QuickSilvaError'
import * as Yup from 'yup'

interface CreateQuicksilvaReferralFormValues {
    nhsNumber: string
    phoneNumber: string
    firstName: string
    lastName: string
    orgId: string
    orgName: string
    token: string
    staffName: string
}

const CreateQuicksilvaReferral: React.FC = () => {
    const [submitted, setSubmitted] = useState(false)
    const [loadError, setLoadError] = useState(false)
    const [formError, setFormError] = useState(false)
    const [validationError, setValidationError] = useState('')

    const { search } = useLocation()
    const query = useMemo(() => new URLSearchParams(search), [search])
    const info = useContext(InfoContext)

    const initialValues: CreateQuicksilvaReferralFormValues = {
        firstName: query.get('firstName') || '',
        lastName: query.get('lastName') || '',
        phoneNumber: query.get('phoneNumber') || '',
        nhsNumber: query.get('nhsNumber') || '',
        orgId: query.get('orgId') || '',
        orgName: query.get('orgName') || '',
        token: query.get('token') || '',
        staffName: query.get('staffName') || '',
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        phoneNumber: Yup.string().required('Phone number is required'),
        nhsNumber: Yup.string(),
    })

    const onSubmit = async (values: CreateQuicksilvaReferralFormValues) => {
        const queryParams = new URLSearchParams({
            firstName: values.firstName || 'Not provided',
            lastName: values.lastName || 'Not provided',
            phoneNumber: values.phoneNumber,
            nhsNumber: values.nhsNumber,
            orgId: values.orgId,
            orgName: values.orgName,
            token: values.token,
            staffName: values.staffName,
        }).toString()

        try {
            const apiResponse = await fetch(info?.inboundApiUrl + `v1/nhs/quicksilva/processReferral?` + queryParams, {
                method: 'POST',
            })

            const apiResponseBody = await apiResponse.json()
            if (apiResponse.status === 400 && apiResponseBody.error === 'phoneInvalid') {
                setValidationError(apiResponseBody.message)
            }

            if (!apiResponse.ok) {
                setFormError(true)
            } else {
                setSubmitted(true)
                setFormError(false)
                setValidationError('')
            }
        } catch (err) {
            Sentry.captureException(err)
            setFormError(true)
        }
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    })

    const handleFieldChange = () => {
        setFormError(false)
        setSubmitted(false)
    }

    useEffect(() => {
        const errorParam = query.get('error')

        if (errorParam) {
            switch (errorParam) {
                case 'phoneValidation': {
                    const errorMessage = query.get('message') || 'Invalid phone number'
                    setValidationError(errorMessage)
                    formik.setTouched({ phoneNumber: true })
                    break
                }
                case 'loadError': {
                    Sentry.captureException(`Failed to load quicksilva form`, { extra: { queryString: query } })
                    setLoadError(true)
                    break
                }
                default: {
                    Sentry.captureMessage(`Unexpected error: Error param: ${errorParam}`, {
                        extra: { queryString: query },
                    })
                    setLoadError(true)
                    break
                }
            }
        }

        if (!initialValues.orgId || !initialValues.orgName) {
            Sentry.captureException(
                `OrgId or OrgName not defined! OrgId: '${initialValues.orgId}' OrgName:${initialValues.orgName}`,
                { extra: { queryString: query } }
            )
            setLoadError(true)
        }
    }, [])

    if (loadError) {
        return <QuickSilvaError />
    }

    return (
        <Page>
            <Header template="landing" />
            <Heading el="h1" size="heading2" color="mid" css={{ mb: 4 }}>
                Create referral
            </Heading>

            <form onSubmit={formik.handleSubmit}>
                <Field>
                    <Label htmlFor="firstName">First name</Label>
                    <Input
                        type="text"
                        id="firstName"
                        appearance="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            formik.handleChange(event)
                            handleFieldChange()
                        }}
                        value={formik.values.firstName}
                        disabled={submitted}
                    />
                </Field>
                <Field>
                    <Label htmlFor="lastName">Last name</Label>
                    <Input
                        type="text"
                        id="lastName"
                        appearance="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            formik.handleChange(event)
                            handleFieldChange()
                        }}
                        value={formik.values.lastName}
                        disabled={submitted}
                    />
                </Field>
                <Field>
                    <Label htmlFor="phoneNumber">Phone number</Label>
                    <Input
                        type="text"
                        id="phoneNumber"
                        appearance="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            formik.handleChange(event)
                            handleFieldChange()
                            setValidationError('')
                        }}
                        value={formik.values.phoneNumber}
                        disabled={submitted}
                        invalid={formik.errors.phoneNumber || validationError}
                    />
                    {formik.errors.phoneNumber && <Validation>{formik.errors.phoneNumber}</Validation>}
                </Field>
                <Field>
                    <Label htmlFor="nhsNumber">NHS Number</Label>
                    <Input
                        type="text"
                        id="nhsNumber"
                        appearance="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            formik.handleChange(event)
                            handleFieldChange()
                        }}
                        value={formik.values.nhsNumber}
                        disabled={submitted}
                    />
                    {formik.errors.nhsNumber && <Validation>{formik.errors.nhsNumber}</Validation>}
                </Field>
                <Field>
                    <Input type="hidden" id="orgId" value={formik.values.orgId}></Input>
                    <Input type="hidden" id="orgName" value={formik.values.orgName}></Input>
                    <Input type="hidden" id="token" value={formik.values.token}></Input>
                    <Input type="hidden" id="token" value={formik.values.staffName}></Input>
                </Field>
                <Button
                    id="submit"
                    type="submit"
                    appearance="primary"
                    fullWidth
                    size="large"
                    label="Submit"
                    disabled={formik.isSubmitting || submitted || validationError || !formik.values.phoneNumber}
                    loading={formik.isSubmitting}
                    css={{ mt: 4 }}
                    onClick={() => {
                        // Reset the form error and submission status when the submit button is clicked
                        setFormError(false)
                        setSubmitted(false)
                    }}
                />
                {formError && !validationError && (
                    <Validation>
                        <p>
                            Failed to create referral! Please close this page and try again. If the problem persists,
                            please contact us on <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a>.
                        </p>
                    </Validation>
                )}
                {validationError && (
                    <Validation>
                        {(() => {
                            try {
                                const parsedError = JSON.parse(validationError)
                                if (Array.isArray(parsedError)) {
                                    return parsedError.map((error, index) => <p key={index}>{error}</p>)
                                }
                            } catch (e) {
                                // If parsing fails, fall back to treating validationError as a string
                            }
                            return <p>{validationError}</p>
                        })()}
                    </Validation>
                )}

                {submitted && <p>Referral submitted successfully.</p>}
            </form>
        </Page>
    )
}

export default CreateQuicksilvaReferral
