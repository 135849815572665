import React, { useState, useContext } from 'react'
import { Heading, Text, Button, Input } from '@psyomics/components'
import Validation from '../../../components/Validation'
import { useReportingApi } from '../../../../ui/app/reporting'
import { useRegistration } from '../../../context/registration/useRegistration'
import Field from '../../../components/Field'
import Label from '../../../components/Label'
import Styles from './AboutYou.module.scss'
import { useAsync } from 'registration/hooks/use-async'
import DateEntry from 'registration/components/DateEntry'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { UserType } from 'censeo-core'
import { isENGBLocale } from 'registration/utils/locale'
import { getAboutYouMessage } from 'registration/utils/messages'
import { OnboardingFormProps } from 'registration/pages/Onboarding/use-Onboarding'

type IEligibilityProps = OnboardingFormProps & React.ComponentPropsWithoutRef<'div'>

const AboutYou: React.FC<IEligibilityProps> = ({
    values,
    errors,
    touched,
    onChange,
    onBlur,
    validateForm,
    submitForm,
    setFieldValue,
    setPageState,
}) => {
    const { state } = useRegistration()
    const [nextPressed, setNextPressed] = useState(false)
    const report = useReportingApi()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { dateOfBirthEntered, postcodeEntered } = useMixpanelEvent(mixpanelApiUrl)
    const { run, isLoading } = useAsync()
    const isD2CUser = state?.userType == UserType.D2C
    const showPostCode = isENGBLocale()

    function handleSubmit() {
        dateOfBirthEntered(state?.organisationCode, state?.organisationName, values.birthdate, state?.referralPublicId)
        postcodeEntered(state?.organisationCode, state?.organisationName, state?.referralPublicId)
        report?.elegibilityCompleted(state?.userType, state?.referralCode)
        setPageState('sex')
    }
    const [postcodeNotFound, setPostcodeNotFound] = useState(false)

    // todo: refactor to use react-async-hook
    async function checkPostcodeApi(postcode: string) {
        // todo: doesn't doing this _here_ mean an invalid postcode will be allowed?
        const result = await fetch(`https://api.postcodes.io/postcodes/${postcode}`)
        if (result.status === 200) {
            setPostcodeNotFound(false)
            const formattedPostcode = (await result.json()).result.postcode
            if (formattedPostcode) setFieldValue('postcode', formattedPostcode)
            // note: this only seemed to be needed for the tests :(
            await validateForm?.()
        } else {
            setPostcodeNotFound(true)
        }
    }
    return (
        <div
            className={Styles.container}
            onMouseLeave={(e: any) => {
                // checks postcode for the before using forward browser navigation issue
                // if condition to stop LastPass extension triggering the event.
                if (e.target.tagName !== 'INPUT' && !isD2CUser && showPostCode) {
                    checkPostcodeApi(values.postcode)
                } else {
                    setPostcodeNotFound(false)
                }
            }}
        >
            <div className={Styles.intro}>
                <Heading el="h1" size="heading2">
                    About you
                </Heading>
                <Text size="medium" color="mid">
                    {getAboutYouMessage(isD2CUser)}
                </Text>
            </div>
            <div className={Styles.form}>
                {!isD2CUser && showPostCode && (
                    <Field>
                        <Label htmlFor="postcode">Your postcode</Label>
                        <Input
                            data-private="lipsum"
                            type="text"
                            id="postcode"
                            name="postcode"
                            appearance="outlined"
                            spellCheck="false"
                            placeholder=""
                            invalid={errors.postcode && touched.postcode ? true : false}
                            autoComplete="postal-code"
                            onChange={onChange}
                            onInput={(e: any) => {
                                if (e.nativeEvent.data === undefined && showPostCode) {
                                    // checks for autofill
                                    checkPostcodeApi(e.target.value)
                                }
                            }}
                            onKeyUp={onChange}
                            onBlur={(e: any) => {
                                onBlur?.(e)
                                if (showPostCode) {
                                    run(checkPostcodeApi(e.target.value))
                                }
                            }}
                            value={values.postcode}
                        />

                        {nextPressed && errors.postcode ? <Validation>{errors.postcode}</Validation> : null}
                        {postcodeNotFound && !(nextPressed && errors.postcode) && (
                            <Validation>
                                Postcode was not found. Please enter the postcode you used when you registered with your
                                GP.
                            </Validation>
                        )}
                    </Field>
                )}

                <Field>
                    <Label appearance="primary">Your date of birth</Label>
                    <DateEntry
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        onChange={onChange}
                        onBlur={onBlur}
                        setPageState={setPageState}
                        validateForm={validateForm}
                        submitForm={submitForm}
                    ></DateEntry>
                    {nextPressed && (errors.birthdate || errors.dobDate) ? (
                        <Validation>{errors.dobDate || errors.birthdate}</Validation>
                    ) : null}
                </Field>
            </div>
            <div className={Styles.actions}>
                <Button
                    type="button"
                    appearance="primary"
                    fullWidth
                    size="large"
                    label="Next"
                    disabled={(showPostCode && postcodeNotFound) || errors.birthdate || isLoading}
                    onClick={async () => {
                        const errorsNow = await validateForm()
                        setNextPressed(true)

                        if (showPostCode) {
                            await run(checkPostcodeApi(values.postcode))
                        }

                        if (!showPostCode || (!errorsNow?.postcode && !postcodeNotFound)) {
                            handleSubmit()
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default AboutYou
