import React, { useContext, useState } from 'react'
import { Text, Button } from '@psyomics/components'
import { OnboardingFormProps } from '../use-Onboarding'
import { GenderOptions } from '..'
import GenderRadioGroup from 'registration/components/GenderRadioGroup'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { OrganisationContext } from 'ui/app/context/organisation'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'

type IOnboarding = OnboardingFormProps & React.ComponentPropsWithoutRef<'div'>

const Gender: React.FC<IOnboarding> = ({ values, errors, onChange, validateForm, setPageState }) => {
    const [showErrors, setShowErrors] = useState(false)
    const mixpanelApiUrl = useContext(MixpanelContext)
    const organisation = useContext(OrganisationContext)
    const referralDetails = useContext(ReferralDetailsContext)
    const { genderEntered } = useMixpanelEvent(mixpanelApiUrl)

    return (
        <>
            <Text color="mid" css={{ mb: 4 }}>
                In order to improve your experience we&#39;d like to find out a little more about you.
            </Text>
            <GenderRadioGroup
                label="What is your gender?"
                value={values.gender}
                onChange={onChange}
                error={showErrors ? errors.gender : undefined}
            />
            <Button
                type="submit"
                appearance="primary"
                fullWidth
                size="large"
                css={{ mt: 6 }}
                label="Next"
                onClick={async () => {
                    const errors = await validateForm()
                    setShowErrors(true)
                    if (!errors.gender) {
                        values.gender = (GenderOptions as any)[values.gender]
                        genderEntered(
                            organisation?.organisationCode,
                            organisation?.organisationName,
                            values.gender,
                            referralDetails?.data?.publicId
                        )
                        setPageState('ethnicity')
                    }
                }}
            />
        </>
    )
}

export default Gender

// todo: move centrally!
export const getGenderLabelFromValue = (genderKey: keyof typeof GenderOptions): any => {
    switch (genderKey) {
        case GenderOptions.Female:
            return 'Female (including trans female)'
        case GenderOptions.Male:
            return 'Male (including trans male)'
        default:
            return (GenderOptions as any)[genderKey]
    }
}
